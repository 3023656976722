import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import {
  downloadOutputFile,
  downloadTopFile,
  getTimePeriods,
} from "Redux/outputs/actions";
// -------------------------------------------------------------
import waitbar from "Media/waitbar.gif";
import "./css.css";

const filetypes = [
  { filetype: "songxrevxhalf", headline: "Song x Revenue x Half" },
  { filetype: "incomexrevxhalf", headline: "Income x Revenue x Half" },
  { filetype: "sourcexrevxhalf", headline: "Source x Revenue x Half" },
  { filetype: "songxincomexrevxhalf", headline: "Song x Income x Rev x Half" },
  {
    filetype: "thirdpartyxsongxrevxhalf",
    headline: "3rd Party x Song x Revenue x Half",
  },
  {
    filetype: "thirdpartyxincomexrevxhalf",
    headline: "3rd Party x Income x Revenue x Half",
  },
  {
    filetype: "thirdpartyxsourcexrevxhalf",
    headline: "3rd Party x Source x Revenue x Half",
  },
  { filetype: "artistxrevxhalf", headline: "Artist x Revenue x Half" },

  { filetype: "configurationxrevxhalf", headline: "Configuration" },
  { filetype: "nulleventdatelines", headline: "Activity / Lines" },
  { filetype: "nulleventdaterev", headline: "Activity / Revenue" },
  { filetype: "temporegionxrevxhalf", headline: "Territory / Region" },
  {
    filetype: "tempocountryxrevxhalf",
    headline: "Territory / Region / Country",
  },
  { filetype: "songspotifyID", headline: "Spotify" },
  { filetype: "songappleID", headline: "Apple" },
  { filetype: "songgeniusID", headline: "Genius" },

  { filetype: "songxPRObonuscredits", headline: "Song x Bonus Credits" },
  { filetype: "songxPRObonusdollars", headline: "Song x Bonus Dollars" },
  { filetype: "incomexPRObonuscredits", headline: "Income x Bonus Credits" },

  { filetype: "incomexPRObonusdollars", headline: "Income x Bonus Dollars" },
  { filetype: "sourcexPRObonuscredits", headline: "Source x Bonus Credits" },

  { filetype: "sourcexPRObonusdollars", headline: "Source x Bonus Dollars " },
  { filetype: "creditsVdollars", headline: "Bonus Credits V Dollars" },
  { filetype: "songdatexrevxhalf", headline: "Song / Release x Rev " },

  { filetype: "summaryoutputs", headline: "Summary Outputs" },
  { filetype: "masteroutput", headline: "Master Output" },

  { filetype: "hipsongs", headline: "Hip Songs" },
  { filetype: "hipincome", headline: "Hip Income" },
  { filetype: "hipsources", headline: "Hip Sources" },
  { filetype: "hipsourcechains", headline: "Hip Source Chains" },
  { filetype: "hipterritories", headline: "Hip Territories" },
  { filetype: "hipnetgross", headline: "Hip Net Gross" },
  { filetype: "hiprates", headline: "Hip Rates" },
  { filetype: "hipratesterritories", headline: "Hip Rates Territories" },
  { filetype: "syncxsourcexrevxhalf", headline: "Hip Sync" },
  { filetype: "hipsyncdetail", headline: "Hip Sync Detail" },
  { filetype: "releaseyearxrevxhalf", headline: "Release Year x Rev x Half" }
];

const filetypesHipgnosis = [
  { filetype: "countryxrevxhalf", headline: "Country x Rev x Half" },
  {
    filetype: "regionalblockxrevxhalf",
    headline: "Regional Block x Rev x Half",
  },
  {
    filetype: "fullterritoryxrevxhalf",
    headline: "Full Territory x Rev x Half",
  },
  {
    filetype: "incomesourcexrevxhalf",
    headline: "Income Source x Revenue x Half",
  },
  {
    filetype: "syncincomesourcexrevxhalf",
    headline: "Sync Income Source x Revenue x Half",
  },
  { filetype: "songIDxrevxhalf", headline: "SongID x Revenue x Half" },
  {
    filetype: "songdateIDISWCISRCxrevxhalf",
    headline: "Song Date IDISWCSR x Rev x Half",
  },
  {
    filetype: "songdateISWCxrevxhalf",
    headline: "Song Date ISWC x Rev x Half",
  },
  {
    filetype: "songdateISWCISRCxrevxhalf",
    headline: "Song Date ISWCISRC x Rev x Half",
  },
  {
    filetype: "songdateartistIDISWCISRCxrevxhalf",
    headline: "Song Date Artist IDISWCSR x Rev x Half",
  },
  { filetype: "incomeIIxrevxhalf", headline: "IncomeII x Rev x Half" },
  { filetype: "fullincomexrevxhalf", headline: "Full Income x Rev x Half" },
  { filetype: "songxnetgross", headline: "Song x Net Gross" },
  { filetype: "songIDincomesongshare", headline: "Song ID Income Song Share" },
  { filetype: "songIDincomepayeepercent", headline: "Song ID Income Payee %" },
  {
    filetype: "songIDISWCISRCincomesongshare",
    headline: "Song IDISWCISRC Income Song Share",
  },
  {
    filetype: "songIDISWCincomesongshare",
    headline: "Song IDISWC Income Song Share",
  },
  {
    filetype: "songIDISWCISRCincomepayeepercent",
    headline: "Song IDISWCISRC Income Payee %",
  },
  {
    filetype: "songIDISWCincomepayeepercent",
    headline: "Song ID ISWC Income Payee %",
  },
  { filetype: "summarynetgross", headline: "Summary Net Gross" },
  { filetype: "songxplaysxrevenue", headline: "Song x Plays x Rev" },
  { filetype: "playsxrevxhalf", headline: "Plays x Rev x Half" },
  { filetype: "mojomasteroutput", headline: "Master Output - Mojo" },

];
// -------------------------------------------------------------
// -------------------------------------------------------------

const Index = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const outputs = useSelector((state) => state.outputs);

  const [making, setMaking] = useState(false);
  const [loading, setLoading] = useState(null);
  const [loadingHip, setLoadingHip] = useState(null);
  const [common, setCommon] = useState(false);
  const [loadingPeriod, setLoadingPeriod] = useState(null);

  const { project } = projects;

  useEffect(() => {
    dispatch(getTimePeriods(project));
  }, []);

  return (
    <>
      <div className="box">
        <div className="buttons">
          {filetypes.map((entry, index) => {
            const isLoading = loading === index;
            return (
              <button
                key={`filetype-${index}`}
                className={`button ${isLoading ? "is-loading" : ""}`}
                disabled={making && !isLoading}
                onClick={() => {
                  setMaking(true);
                  setLoading(index);
                  dispatch(
                    downloadOutputFile(project, entry.filetype, () => {
                      setMaking(false);
                      setLoading(null);
                    })
                  );
                }}
              >
                <div className="tag is-success xlsx">XLSX</div>
                <span>{entry.headline}</span>
              </button>
            );
          })}
        </div>
      </div>
      <div className="box">
        <div className="buttons">
          {filetypesHipgnosis.map((entry, index) => {
            const isLoading = loadingHip === index;
            return (
              <button
                key={`filetype-${index}`}
                className={`button ${isLoading ? "is-loading" : ""}`}
                disabled={making && !isLoading}
                onClick={() => {
                  setMaking(true);
                  setLoadingHip(index);
                  dispatch(
                    downloadOutputFile(project, entry.filetype, () => {
                      setMaking(false);
                      setLoadingHip(null);
                    })
                  );
                }}
              >
                <div className="tag is-success xlsx">XLSX</div>
                <span>{entry.headline}</span>
              </button>
            );
          })}
        </div>
      </div>
      <div className="box">
        <div className="buttons">
          <button
            className={`${common ? "is-loading" : ""} button`}
            disabled={making && !common}
            onClick={() => {
              setMaking(true);
              setCommon(true);
              dispatch(
                downloadOutputFile(project, "songvest2", () => {
                  setMaking(false);
                  setCommon(false);
                })
              );
            }}
          >
            <div className="tag is-success xlsx">XLSX</div>
            <span>Top Sources & Songs (Common Denominator)</span>
          </button>
        </div>
      </div>

      {outputs.timePeriods ? (
        <div className="box">
          <div className="buttons">
            {outputs.timePeriods.map((period, periodIndex) => {
              const isLoading = loadingPeriod === periodIndex;
              return (
                <button
                  key={`output-time-periods-${periodIndex}`}
                  className={`${isLoading ? "is-loading" : ""} button`}
                  disabled={making && !isLoading}
                  onClick={() => {
                    setMaking(true);
                    setLoadingPeriod(periodIndex);
                    dispatch(
                      downloadTopFile(project, period, () => {
                        setMaking(false);
                        setLoadingPeriod(null);
                      })
                    );
                  }}
                >
                  <div className="tag is-success xlsx">XLSX</div>
                  <span>Top Sources & Songs ({period})</span>
                </button>
              );
            })}
          </div>
        </div>
      ) : (
        <img src={waitbar} alt="loading..." />
      )}
    </>
  );
};

export default Index;
