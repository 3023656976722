import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../NormingButtons";
// -------------------------------------------------------------
import MainTable from "./MainTable";
import StatusStats from "./StatusStats";
import ProgressBar from "Components/ProgressBar";
import PoolMaster from "./PoolMaster";
import SongNorming from "./SongNorming";
import SongDetails from "./SongDetails";
import CurrentSong from "./CurrentSong";
import APINormingButtons from "./APINormingButtons";
// -------------------------------------------------------------
import { getNorming } from "System/normingDataStore";
// -------------------------------------------------------------
import {
  storeNorming,
  populateFromMaster,
  setRaw,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {
  const dispatch = useDispatch();
  const hot = useRef(null);

  const projects = useSelector((state) => state.projects);
  const {
    project: { pivot, isPerformer },
  } = projects;
  const [populating, setPopulating] = useState(false);
  const [displayStats, setDisplayStats] = useState(false);
  const [displayPool, setDisplayPool] = useState(false);

  const [items, setItems] = useState(null);

  useEffect(() => {
    const theseItems = getNorming("song");
    console.log("theseItems", theseItems);
    setItems(theseItems);
  }, []);

  const populate = async () => {
    setPopulating(true);
    await dispatch(
      populateFromMaster("song", (result) => {
        setPopulating(false);
        const newItems = JSON.parse(JSON.stringify(result));
        setItems(JSON.parse(JSON.stringify(result)));
        dispatch(storeNorming(result, "song"));
        if (isPerformer) {
          console.log(newItems.data[0]);
          for (var ii = 0; ii < newItems.data.length; ii++) {
            const item = newItems.data[ii];
            console.log(ii, item["Release_Artist_9LC"]);
          }
        }
      })
    );
  };

  // ------------------------------------------------------
  const updatePool = (poolIndices) => {
    const ref = hot.current.hotInstance;
    const changes = poolIndices.map((idx) => [idx, "Song_Name_9LC", true]);
    ref.setDataAtRowProp(changes);
  };

  const acceptSongNorming = (song, performer, songIndex, rawSong) => {
    const ref = hot.current.hotInstance;
    ref.setDataAtRowProp([
      [songIndex, "Song_Name_9LC", song],
      [songIndex, "Release_Artist_9LC", performer],
    ]);
    dispatch(storeNorming(items, "song"));
    dispatch(setRaw(songIndex, rawSong, song, performer));
  };

  const acceptSongDetails = (data, songIndex, rawSong, infoSource) => {
    const ref = hot.current.hotInstance;
    ref.setDataAtRowProp([
      [songIndex, "Song_Name_9LC", data["matchedSong"]],
      [songIndex, "Release_Artist_9LC", data["matchedPerformer"]],
      [songIndex, "Album_Name_9LC", data["albumTitle"]],
      [songIndex, "Project_Type_9LC", data["albumType"]],
      [songIndex, "Featured_Artist_9LC", data["featuring"]],
      [songIndex, "Release_Date_9LC", data["releaseDate"]],
      [songIndex, "Information_Source_9LC", infoSource],
    ]);
    dispatch(storeNorming(items, "song"));
    dispatch(
      setRaw(songIndex, rawSong, data["matchedSong"], data["matchedPerformer"])
    );
  };

  // ------------------------------------------------------
  return (
    <div className="norming-wrapper">
      {displayPool ? (
        <PoolMaster
          items={items}
          close={() => {
            setDisplayPool(false);
          }}
          update={updatePool}
        />
      ) : null}
      <ProgressBar />
      <StatusStats
        items={items}
        displayStats={displayStats}
        close={() => {
          setDisplayStats(false);
        }}
      />
      <SongNorming accept={acceptSongNorming} />
      <SongDetails accept={acceptSongDetails} />
      <CurrentSong />
      <NormingButtons
        which="song"
        populate={populate}
        populating={populating}
        items={items}
      >
        <button
          className="button is-small"
          onClick={() => {
            setDisplayStats(true);
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-chart-pie" />
          </span>
          <span>Status</span>
        </button>
        <button
          className="button is-small"
          onClick={() => {
            setDisplayPool(true);
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-swimming-pool" />
          </span>
          <span>Pool</span>
        </button>
        <APINormingButtons />
      </NormingButtons>

      {items ? (
        <div className="hands-on-wrapper">
          <MainTable songs={items} hot={hot} />
        </div>
      ) : null}
    </div>
  );
};
export default Index;
