export const swatches = [
  "default",
  "slate",
  "nuclear",
  "cerulean",
  "cosmo",
  "cyborg",
  "darkly",
  "flatly",
  "journal",
  "litera",
  "lumen",
  "lux",
  "materia",
  "minty",
  "pulse",
  "sandstone",
  "simplex",
  "solar",
  "spacelab",
  "superhero",
  "united",
  "yeti",
];
