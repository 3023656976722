import { useSelector, useDispatch } from 'react-redux';
// -------------------------------------------------------------
import running from "Media/running.gif";

// -------------------------------------------------------------
// -------------------------------------------------------------
const RunBuild = () => {

  const database = useSelector( state => state.database );

  const { dbProgress } = database;
  if(!dbProgress) return null;
  
  const { status, pointer, total, headline, main } = dbProgress;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head db">
          <p className="modal-card-title">{main}</p>
        </header>
        <section className="modal-card-body">
          <div className="box">
            <div className="status-headline">
              <img src={running} />
              &nbsp;{headline}
            </div>
            <i>{status}</i>
          </div>
        </section>
        <footer className="modal-card-foot" />
      </div>
    </div>
  );
}

export default RunBuild;