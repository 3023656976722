import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
// -------------------------------------------------------------
import Frame from "Components/FrameMain/";
import NewProject from "./Components/NewProject";
// -------------------------------------------------------------
import { listProjects, listMoreProjects } from "Redux/projects/actions";
// -------------------------------------------------------------
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = ({ history }) => {

  const batchSize = 500;

  const projects = useSelector( state => state.projects );
  const user = useSelector( state => state.user );
  const [thisUser, setThisUser] = useState(null);
  const [ howMany, setHowMany ] = useState(batchSize);

  const dispatch = useDispatch();
  useEffect( () => { dispatch(listProjects(thisUser, batchSize)) }, [] )

  const changeTab = user => {
    setHowMany(batchSize);
    setThisUser(user);
    dispatch(listProjects(user, batchSize));
  }

  const projectsList = projects.list || [];
  const isLoading =  projects.list === null;
  return (
    <Frame isLoading={isLoading}>
      {projects.newProject ? <NewProject history={history} /> : null}

      <div className="tabs is-boxed">
        <ul>
          <li className={thisUser !== null ? "" : "is-active"}>
            <a
              onClick={() => {
                changeTab(null);
              }}
            >
              All Projects
            </a>
          </li>
          <li className={thisUser === null ? "" : "is-active"}>
            <a
              onClick={() => {
                changeTab(user.user);
              }}
            >
              My Projects
            </a>
          </li>
        </ul>
      </div>

      <div className="table-container">
        <table className="table is-fullwidth is-striped is-hoverable">
          <thead>
            {isLoading ? null : (
              <tr>
                <th>Project</th>
                <th>Since</th>
                <th>Modified</th>
                <th>Author</th>
              </tr>
            )}
          </thead>
          <tbody>
            {projectsList.map((project, prindex) => {
              const { _id, title, user, modified, setup } = project;
              const setupDate = new Date(setup).toLocaleDateString("de-de");
              const setupTime = new Date(setup).toLocaleTimeString("de-de");
              const modifiedDate = new Date(modified).toLocaleDateString(
                "de-de"
              );
              const modifiedTime = new Date(modified).toLocaleTimeString(
                "de-de"
              );
              return (
                <tr
                  key={`project-key-${prindex}`}
                  className="project-item"
                  onClick={() => {
                    dispatch({ type: "RESET_PROJECT" });
                    history.push(`/projects/${_id}`);
                  }}
                >
                  <td>{title}</td>
                  <td>
                    {setupDate}
                    <br />
                    {setupTime}
                  </td>
                  <td>
                    {modifiedDate}
                    <br />
                    {modifiedTime}
                  </td>
                  <td>{user}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/*  projects.showMore ? (
          <button 
            className = "button is-fullwidth is-info is-outlined"
            onClick = { () => { 
              dispatch(listMoreProjects(thisUser, howMany + batchSize));
              setHowMany(howMany+batchSize);
            } }
          >
            More...
          </button>  
        ) : null
        */}
      </div>
    </Frame>
  );

}
export default (Index);