import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import ProgressBar from "Components/ProgressBar/";
// -------------------------------------------------------------
import {
  assignToFlyway,
  unAssignToFlyway,
  buildFrame,
} from "Redux/flyway/actions";
// -------------------------------------------------------------
import "./css.css";

const confirm = () => {
  return window.confirm("Are you sure? This action cannot be undone.");
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [allInProgress, setAllInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const projects = useSelector((state) => state.projects);

  const {
    project,
    project: { collectionsRepo, hasFrame },
  } = projects;

  const tables = project.flywayTables;
  const collectionids = Object.keys(collectionsRepo);
  const includedTables = tables
    .filter((x) => collectionsRepo.hasOwnProperty(x))
    .map((tableid) => ({
      id: tableid,
      headline: collectionsRepo[tableid].headline,
    }));
  const excludedTables = [];
  const includedSet = new Set(tables);
  for (var ii = 0; ii < collectionids.length; ii++) {
    if (!includedSet.has(collectionids[ii])) {
      const thisColl = collectionsRepo[collectionids[ii]];
      if (thisColl.hasTable) {
        excludedTables.push({
          id: thisColl.id,
          headline: thisColl.headline,
        });
      }
    }
  }

  const doBuildFrame = () => {
    dispatch(buildFrame(includedTables));
  };

  return (
    <div className="master-wrapper">
      <ProgressBar />
      {hasFrame ? (
        <div className="box">
          <button
            className={`button is-primary`}
            onClick={() => {
              const uri = `https://flyway.bitsonic.ai/projects/${project._id}`;
              //const uri = `https://bitsonic-flyway.web.app/projects/${project._id}`;
              window.open(uri, "_blank");
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-external-link-alt" />
            </span>
            <span>Switch to Flyway</span>
          </button>
        </div>
      ) : null}
      {includedTables.length ? (
        <div className="box">
          <div className="headline">Assigned Tables:</div>
          <p className="buttons">
            {includedTables.map((table) => (
              <MinusTableButton
                key={table.id}
                project={project}
                table={table}
                allInProgress={allInProgress}
              />
            ))}
          </p>
        </div>
      ) : null}

      {excludedTables.length ? (
        <div className="box">
          <div className="headline">Unassigned Tables:</div>
          <p className="buttons">
            {excludedTables.map((table) => (
              <PlusTableButton
                key={table.id}
                project={project}
                table={table}
                allInProgress={allInProgress}
              />
            ))}
          </p>
        </div>
      ) : null}

      {includedTables.length && !hasFrame ? (
        <div className="box">
          <div className="buttons">
            <button
              className={`button is-primary ${isLoading}`}
              onClick={doBuildFrame}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-microchip"></i>
              </span>
              <span>Build Frame</span>
            </button>
          </div>
        </div>
      ) : null}
      <div className="database-clear">{project.database}</div>
    </div>
  );
};
export default Index;

// -----------------------------------------------------------
// -----------------------------------------------------------
const MinusTableButton = ({ project, table, allInProgress }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");

  return (
    <button
      disabled={allInProgress}
      className={`button is-warning is-light ${isLoading}`}
      onClick={() => {
        dispatch(unAssignToFlyway(table.id));
      }}
    >
      <span>{table.headline}</span>
      <span className="icon is-small">
        <i className="fa-duotone fa-times"></i>
      </span>
    </button>
  );
};
// -----------------------------------------------------------
// -----------------------------------------------------------
const PlusTableButton = ({ project, table, allInProgress }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");
  return (
    <button
      disabled={allInProgress}
      className={`button is-info is-light ${isLoading}`}
      onClick={() => {
        dispatch(assignToFlyway(table.id));
      }}
    >
      <span className="icon is-small">
        <i className="fa-duotone fa-plus-circle"></i>
      </span>
      <span>{table.headline}</span>
    </button>
  );
};
