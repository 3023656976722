import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import {
  querySongIDs,
  queryAndUpdateSongDetailsSpotify,
} from "Redux/songbridge/actions";
// -------------------------------------------------------------
import "./css.css";
import running from "Media/running.gif";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(null);
  const projects = useSelector((state) => state.projects);
  const {
    project: { database },
  } = projects;

  useEffect(async () => {
    console.log("Database is", database);
    await dispatch(
      querySongIDs(database, (thisdata) => {
        setData(thisdata);
      })
    );
  }, []);

  const getSpotifyIDs = async () => {
    const thisData = JSON.parse(JSON.stringify(data));
    setLoading(true);
    for (var ii = 0; ii < data.length; ii++) {
      setCurrentIdx(ii);
      const { Song, Artist, Spotify, Genius} = data[ii];
      if (Song && Artist && (!Spotify || !Genius)) {
        await dispatch(
          queryAndUpdateSongDetailsSpotify(Song, Artist, database, ({spotifyID, geniusID}) => {
            if(!spotifyID) spotifyID = "-";
            if(!geniusID) geniusID = "-";
            thisData[ii] = { ...thisData[ii], Spotify: spotifyID, Genius: geniusID };
            setData([...thisData]);
          })
        );
      }
    }
    setCurrentIdx(null);
    setLoading(false);
  };

  if(!data) return null;

  return (
    <div>
      <div className="buttons">
        <button
          className={`button is-primary ${loading ? "is-loading" : ""}`}
          onClick={getSpotifyIDs}
        >
          <span className="icon is-small">
            <i className="fab fa-spotify" />
          </span>
          <span>Get Spotify ID</span>
        </button>
      </div>
      <div className="box">
        <div className="table-container">
          <table className="table is-fullwidth is-striped is-hoverable small-table">
            <thead>
              <tr>
                <th>Number</th>
                <th>Song</th>
                <th>Performer</th>
                <th>Spotify ID</th>
                <th>Apple ID</th>
                <th>Genius ID</th>
              </tr>
            </thead>
            <tbody>
              {data.map((entry, index) => (
                <tr key={`data-${index}`}>
                  <td>{index===currentIdx ? <img src = {running} /> : <div>{index+1}</div>}</td>
                  <td>{entry.Song}</td>
                  <td>{entry.Artist}</td>
                  <td>{entry.Spotify}</td>
                  <td>{entry.Apple}</td>
                  <td>{entry.Genius}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Index;
