const initialState = {
  list: []
};
  
  // -------------------------------------------------------
  // -------------------------------------------------------
  const reducer = ( state = initialState, action ) => {
  
    // -------------------------------------------------------
    switch(action.type) {

      case "SET_MAPPINGS_LIST":
        return {
          ...state,
          list: action.list
        };
      // -------------------------------------------------------
      default:
        return state;
  
    }
  }
  
  export default reducer;