import { engine, download, apPy, backend } from "System/system";
import md5 from "md5";

// -------------------------------------------------------
// -------------------------------------------------------
export const getSignature = (collection, allfiles, cb) => async dispatch => {

  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = JSON.stringify(allfiles.map((x) => x.name));
  const paths = JSON.stringify(allfiles.map((x) => x.path.replace(/\./g, "")));
  const cuts = JSON.stringify(allfiles.map((x) => x.cut));

  const result = await apPy("files", "getHeadersMultiple", {
    files,
    paths,
    cuts,
  });

  if(result.data) {
    const hhash = md5(result.data.headers[0].join().trim());
    console.log("Hhash is", hhash);
    const response = await engine("signature", "getSignature", { hhash });
    console.log("Response is", response);
    if(response&&response.data) {
      const { collection: { sql, payor, parsers }} = response.data;
      const collectionid = collection.id;
      cb(response.data.collection);
      return;
    }
  }
  cb(null);
}

// -------------------------------------------------------
// -------------------------------------------------------
export const setSignature = (collection, allfiles) => async dispatch => {
  console.log("Setting Signature");
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = JSON.stringify(allfiles.map((x) => x.name));
  const paths = JSON.stringify(allfiles.map((x) => x.path.replace(/\./g, "")));
  const cuts = JSON.stringify(allfiles.map((x) => x.cut));

  const result = await apPy("files", "getHeadersMultiple", {
    files,
    paths,
    cuts,
  });

  if(result.data) {
    const hhash = md5(result.data.headers[0].join().trim());
    console.log("Hhash is", hhash);
    console.log(collection);
    engine("signature", "setSignature", { hhash, collectionString: JSON.stringify(collection) });
  }
}


export const applySignature = (collectionid, data) => async dispatch => {
  console.log("Applying Signature", collectionid, data);
  const { sql, payor, parsers } = data;
  dispatch({type: "SIGNATURE_SET", collectionid, sql, payor, parsers});
}