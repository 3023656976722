import React from "react";
import { useDispatch } from "react-redux";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
// -------------------------------------------------------------
import { setSynced, setRaw, storeNorming } from "Redux/norming/actions";
// -------------------------------------------------------------
import "Styles/handsontable.full.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ songs, hot }) => {

  const dispatch = useDispatch();

  const poolRenderer = (...myArgs) => {
    const [instance, td, row, col, prop, value, cellProperties] = myArgs;
    Handsontable.renderers.TextRenderer.apply(this, myArgs);
    td.style.color = "1d72aa";
    td.style.background = "#eef6fc";
  };

  const semiPopulatedRenderer = (...myArgs) => {
    const [instance, td, row, col, prop, value, cellProperties] = myArgs;
    Handsontable.renderers.TextRenderer.apply(this, myArgs);
    td.style.color = "#947600";
    td.style.background = "#fffbeb";
  };

  const fullyPopulatedRenderer = (...myArgs) => {
    const [instance, td, row, col, prop, value, cellProperties] = myArgs;
    Handsontable.renderers.TextRenderer.apply(this, myArgs);
    td.style.color = "#004400";
    td.style.background = "#ebfffc";
  };

  const standardRenderer = (...myArgs) => {
    const [instance, td, row, col, prop, value, cellProperties] = myArgs;
    Handsontable.renderers.TextRenderer.apply(this, myArgs);
  };

  const afterChange = (changes, source) => {
    if (source !== "loadData") {
      dispatch(setSynced("song", true));
      dispatch(storeNorming(songs, "song"));

      const ref = hot.current.hotInstance;
      for (var ii = 0; ii < changes.length; ii++) {
        const [row, key, fromValue, toValue] = changes[ii];
        const song = ref.getDataAtRowProp(row, "Song");

        if (key === "Pool") {
          //setSkip(2);
          if (toValue) {
            ref.setDataAtRowProp([
              [row, "Song_Name_9LC", "Pool Revenue"],
              [row, "Pool_Revenue_9LC", "X"],
            ]);
          } else {
            ref.setDataAtRowProp([
              [row, "Song_Name_9LC", ""],
              [row, "Pool_Revenue_9LC", ""],
            ]);
          }
          //dispatch(setPoolRevenue(song, toValue, masterTable, database));
        }

        if (key === "Pool" || key === "Song_Name_9LC") {
          //dispatch(updateCoverage(songs));
        }
        
        if (key !== "Pool") {
          //dispatch(updateSong(song, key, toValue, masterTable, database));
        }
      }
    }
  };

  const afterSelection = (row, col) => {
    if (col === 0) {
      const ref = hot.current.hotInstance;
      const rowData = ref.getDataAtRow(row);
      dispatch(setRaw(row, rowData[0], rowData[1], rowData[9]));
    }
  };

  return (
    <HotTable
      ref={hot}
      data={songs.data}
      stretchH="all"
      height={500}
      licenseKey="non-commercial-and-evaluation"
      colHeaders={songs.colHeaders}
      afterChange={afterChange}
      afterSelection={afterSelection}
      columns={songs.columns}
      columnSorting={true}
      manualColumnResize={true}
      colWidths={[35, 15]}
      fixedRowsTop={0}
      //fixedColumnsLeft={1}
      rowHeaders={true}
      cells={(row, col) => {
        if (col !== 4) {
          const pool = songs.data[row]["Pool"];
          if (pool) return { renderer: poolRenderer };
          const content = songs.data[row]["Song_Name_9LC"];
          if (!content) return { renderer: standardRenderer };
          if (content !== null && content !== "") {
            const date = songs.data[row]["Release_Date_9LC"];
            if (date) return { renderer: fullyPopulatedRenderer };
            return { renderer: semiPopulatedRenderer };
          }
        }
      }}
    />
  );
};

export default MainTable;
