import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// --------------------------------------------------------------------
import { signOut } from "Redux/user/actions";
// --------------------------------------------------------------------
import bird from "Media/logo_newer.png";
import spin from "Media/spin.gif";
// --------------------------------------------------------------------
import "./frame.css";

// --------------------------------------------------------------------
// --------------------------------------------------------------------
const Frame = ({ isLoading, children }) => {
  const [active, setActive] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // --------------------------------------------------------------------
  const onHamburger = () => {
    setActive(active === "is-active" ? "" : "is-active");
  };

  // --------------------------------------------------------------------
  return (
    <div className="frame-wrapper">
      {user.note ? (
        <div className="notifier-wrapper">
          <div className="notification is-primary is-light">
            <span>{user.note.headline}</span>
            <br />
            <b>{user.note.content}</b>
          </div>
        </div>
      ) : null}
      <nav className="navbar project-nav" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="./">
              <img alt="" src={bird} />
            </Link>
          </div>
          <div
            role="button"
            className={`navbar-burger burger ${active}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={onHamburger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        <div className="navbar-menu">
          <div className="navbar-start">
            <div className="navbar-item">
              <button
                className="button is-primary"
                onClick={() => {
                  dispatch({ type: "NEW_PROJECT_SHOW" });
                }}
              >
                <span className="icon is-small">
                  <i className="fa-duotone fa-plus"></i>
                </span>
                <span>New Project</span>
              </button>
            </div>
            <div className="navbar-item">
              {isLoading ? <img src={spin} alt="" /> : null}
            </div>
          </div>
        </div>
        <div className="navbar-end">
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link">{user.user}</div>
            <div className="navbar-dropdown">
              <div
                className="navbar-item"
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                Sign Out
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div style={{ margin: "18px 8px" }}></div>
      <div className="box"> {children} </div>
      <footer className="footer" style={{ padding: 0, flex: "0 1 8px" }}>
        <div className="content has-text-centered">
          Version: <b>V{user.version}</b> | Last Update:{" "}
          <b>{user.lastUpdate}</b>
        </div>
      </footer>
    </div>
  );
};

export default Frame;
