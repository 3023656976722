import { useSelector } from "react-redux";
// -----------------------------------------------------
import "./css.css";

// -----------------------------------------------------
// -----------------------------------------------------
const Index = () => {
  const songbridge = useSelector((state) => state.songbridge);

  const { rawSong, performer } = songbridge;
  if (!rawSong) return null;
  return (
    <div className="song-info-wrapper">
      {rawSong ? (
        <div className="tag is-info is-light">{rawSong}</div> 
      ): null}
      {performer ? (
        <div className="tag is-info is-light">{performer}</div>
      ) : null}
    </div>
  );
};

export default Index;
