import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import "./css.css";
import { makeNewCollection } from "Redux/collections/actions";

// ------------------------------------------------------
const FixedAdd = () => {
  const dispatch = useDispatch();

  const onDrop = (e) => {
    const dir = JSON.parse(e.dataTransfer.getData("dir"));
    dispatch(makeNewCollection(dir));
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="floating-new-wrapper"
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <div className="box">
        <button className="button is-fullwidth is-outlined is-primary is-light">
          <span className="icon is-small">
            <i className="fa-duotone fa-plus-circle add-icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default FixedAdd;
