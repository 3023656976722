import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { newCollection, displayNewCollectionPopup } from "Redux/collections/actions";
// -------------------------------------------------------------
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {
  const [collection, setCollection] = useState("");
  const collections = useSelector((state) => state.collections);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  if (!collections.newCollectionPopup) return null;

  const submit = () => {
    if (collection) {
      dispatch(newCollection(user.user, collection));
      setCollection("");
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head convert">
          <div className="header">
            <p className="modal-card-title">New Collection</p>
            <button
              className="delete"
              onClick = {() => { dispatch(displayNewCollectionPopup(false)) } }
              aria-label="close"
            />
          </div>
        </header>
        <section className="modal-card-body">
          <div className="box">
            <input
              className="input"
              type="text"
              placeholder="Enter Collection Title"
              value={collection}
              onChange={(e) => {
                setCollection(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") submit();
              }}
              autoFocus={true}
            />
          </div>
        </section>
        <footer className="modal-card-foot"></footer>
      </div>
    </div>
  );
};

export default Index;
