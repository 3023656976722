import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// ------------------------------------------------------
import File from "Components/File";
// ------------------------------------------------------
import {
  addFilesToCollection,
  removeCollection,
  partitionCollection,
  setBulkCut,
  downloadCollection,
  removeEmpty,
  deEuropize,
  removeZeros,
  deFrac
} from "Redux/collections/actions";
import { getHeaders, getHeadersSet } from "Redux/files/actions";
import { setCollection, setActiveTab } from "Redux/nav/actions";

// ------------------------------------------------------
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Collection = ({ collectionid, collectionsRepo }) => {
  const [state, setState] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [deeuropize, setDeeuropize] = useState(false);
  const [defrac, setDefrac] = useState(false);
  const [removingZeros, setRemovingZeros] = useState(false);

  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const { project } = projects;
  const collection = collectionsRepo[collectionid];
  const files = collection.fileids.map(
    (fileid) => project.flatFilesReverse[fileid]
  );

  const onDrop = (e) => {
    const dir = JSON.parse(e.dataTransfer.getData("dir"));
    dispatch(addFilesToCollection(collection.id, dir));
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <ul className="collection-tree" onDrop={onDrop} onDragOver={onDragOver}>
      <li
        className={`collection-entry ${state ? "open" : "closed"}`}
        onClick={() => {
          if (files.length) {
            dispatch(
              setCollection({
                id: collection.id,
                headline: collection.headline,
              })
            );
          }
        }}
      >
        <div
          onClick={(evt) => {
            evt.stopPropagation();
            setState(!state);
          }}
        >
          {state ? (
            <i className="fa-duotone fa-box-open colpic" />
          ) : (
            <i className="fa-duotone fa-box colpic" />
          )}
        </div>
        {collection.headline}
        <div className="tags has-addons">
          {files.length ? (
            <div className="tag is-small is-light is-info">{files.length}</div>
          ) : null}
          {collection.collectionids.length ? (
            <div className="tag is-small is-light is-warning">
              {collection.collectionids.length}
            </div>
          ) : null}
          {collection.nFiles ? (
            <div className="tag is-small is-light is-info">
              {collection.nFiles}
            </div>
          ) : null}
          {collection.hasFrame ? (
            <div className="tag is-small is-light is-primary">F</div>
          ) : null}

          {collection.hasTable ? (
            <div className="tag is-small is-light is-danger">T</div>
          ) : null}
        </div>
        {files.length ? (
          <div className="button-group">
            <button
              className={`button box-button is-small ${
                removingZeros ? "is-loading" : ""
              }`}
              onClick={(e) => {
                setRemovingZeros(true);
                e.stopPropagation();
                dispatch(
                  removeZeros(files, () => {
                    setRemovingZeros(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-00" />
              </span>
            </button>

            <button
              className={`button box-button is-small ${
                removing ? "is-loading" : ""
              }`}
              onClick={(e) => {
                setRemoving(true);
                e.stopPropagation();
                dispatch(
                  removeEmpty(files, () => {
                    setRemoving(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-align-left" />
              </span>
            </button>

            <button
              className={`button box-button is-small ${
                defrac ? "is-loading" : ""
              }`}
              onClick={(e) => {
                setDefrac(true);
                e.stopPropagation();
                dispatch(
                  deFrac(files, () => {
                    setDefrac(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-percent" />
              </span>
            </button>

            <button
              className={`button box-button is-small ${
                deeuropize ? "is-loading" : ""
              }`}
              onClick={(e) => {
                setDeeuropize(true);
                e.stopPropagation();
                dispatch(
                  deEuropize(files, () => {
                    setDeeuropize(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-flag-usa" />
              </span>
            </button>

            <button
              className="button box-button is-small"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setBulkCut({ collection }));
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-cut" />
              </span>
            </button>

            <button
              className="button box-button is-small"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(getHeadersSet(collection, files));
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-columns" />
              </span>
            </button>

            <button
              className="button box-button is-small"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(getHeaders(files));
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-heading" />
              </span>
            </button>

            <button
              className="button box-button is-small"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(partitionCollection(collection, files));
              }}
            >
              <i className="fa-duotone fa-divide" />
            </button>

            <button
              className="button box-button is-small"
              onClick={(evt) => {
                evt.stopPropagation();
                dispatch(removeCollection(collection.id));
              }}
            >
              <i className="fa-duotone fa-trash-alt" />
            </button>
            <button
              className="button box-button is-small"
              onClick={(evt) => {
                evt.stopPropagation();
                dispatch(downloadCollection(files, () => {
                  console.log("Downloaded");
                }));
              }}
            >
              <i className="fa-duotone fa-download" />
            </button>
          </div>
        ) : (
          <div className="button-group">
            <button
              className="button box-button is-small"
              onClick={(evt) => {
                evt.stopPropagation();
                dispatch(removeCollection(collection.id));
              }}
            >
              <i className="fa fa-trash-alt" />
            </button>
          </div>
        )}
      </li>
      {state ? (
        <>
          {files.map((file, fileindex) => (
            <File
              file={file}
              key={`file-${fileindex}`}
              collectionid={collection.id}
            />
          ))}
          {collection.collectionids.map((collectionid, colindex) => (
            <Collection
              key={`col-${colindex}-${collection.headline}`}
              collectionid={collectionid}
              collectionsRepo={collectionsRepo}
            />
          ))}
        </>
      ) : null}
    </ul>
  );
};

export default Collection;
