import { engine, download, apPy, backend } from "System/system";
import { notify } from "Redux/user/actions";
import { saveProjectAfterTimeout } from "Redux/projects/actions";
import {
  canonicalHeaders,
  updateHeaders,
  collectionCoreHeaders,
  masterHeaders,
} from "Data/updateHeaders";

// -------------------------------------------------------
// -------------------------------------------------------
export const initCanonicals = (allfiles, collectionid) => async (dispatch) => {
  dispatch({ type: "CLEAR_DB" });
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  dispatch({ type: "SET_DB_FILES", files: allfiles });

  const files = JSON.stringify(allfiles.map((x) => x.name));
  const paths = JSON.stringify(allfiles.map((x) => x.path.replace(/\./g, "")));
  const cuts = JSON.stringify(allfiles.map((x) => x.cut));

  const result = await apPy("files", "getHeadersMultiple", {
    files,
    paths,
    cuts,
  });
  const { headers } = result.data;
  const headerSet = new Set();
  for (var ii = 0; ii < headers.length; ii++) {
    const thisHeader = headers[ii];
    for (var cc = 0; cc < thisHeader.length; cc++) {
      headerSet.add(thisHeader[cc]);
    }
  }

  const sampleResult = await apPy("files", "getSamples", {
    files,
    paths,
    cuts,
  });
  const sampleData =
    typeof sampleResult.data === "string"
      ? JSON.parse(sampleResult.data.replace(/NaN/gi, "null"))
      : sampleResult.data;

  const sample = {};
  sampleData.headers.forEach((header) => {
    sample[header] = [];
  });

  sampleData.head.forEach((row) => {
    sampleData.headers.forEach((header) => {
      sample[header].push(row[header]);
    });
  });

  const dbHeaders = Array.from(headerSet).map((header) => {
    //const datatype = isFloat(sample[header]) ? "float" : "varchar";
    return { header, datatype: null, canonical: null, sample: sample[header] };
  });

  const sql = { dbHeaders, mapper: {} };

  dispatch({
    type: "ATTACH_SQL_TO_COLLECTION",
    collectionid,
    sql,
  });
};

const isFloat = (entries) => {
  if (!entries) return false;
  let isFloat = true,
    notNull = false;
  entries.forEach((entry, entryindex) => {
    if (entry !== null) {
      notNull = true;
      if (isNaN(parseFloat(entry))) {
        isFloat = false;
      }
    }
  });
  return isFloat && notNull;
};

// -------------------------------------------------------
// -------------------------------------------------------
export const queryDB = (dbtable, database) => async (dispatch) => {
  const result = await backend("database", "queryTable", {
    dbtable,
    limit: 50,
    database,
  });
  const data = result.data;
  if (data) {
    console.log("Setting data");
    dispatch({ type: "SET_DB_QUERY_RESULTS", data: data.results });
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setTable = (table) => (dispatch) => {
  dispatch({ type: "SET_DB_TABLE", table });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setDatatype = (headerindex, datatype) => (dispatch) => {
  dispatch({ type: "SET_DB_DATATYPE", headerindex, datatype });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setMapping = (headerindex, mapping) => (dispatch) => {
  dispatch({ type: "SET_DB_MAPPING", headerindex, mapping });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setSecondary = (headerindex) => (dispatch) => {
  dispatch({ type: "SET_SECONDARY", headerindex });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setSecondaryTitle = (headerindex) => (dispatch) => {
  dispatch({ type: "SET_SECONDARY_TITLE", headerindex });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const buildDB =
  (
    dbtable,
    database,
    files,
    mainPath,
    canonicalMapper,
    secondary,
    secondaryTitle
  ) =>
  async (dispatch) => {
    dispatch({
      type: "SET_DB_PROGRESS",
      headline: "Database",
      status: "Setting up table...",
    });

    await backend("database", "makeTable", {
      dbtable,
      database,
      headersString: JSON.stringify(collectionCoreHeaders),
    });

    dispatch({ type: "SET_DB_PROGRESS_MAIN", main: "Build SQL Table" });
    dispatch({
      type: "SET_DB_PROGRESS",
      headline: "Files",
      status: "Pre-processing files for import",
    });

    const result = await backend("database", "prepFolder", { mainPath });
    const { table } = result.data;
    const nFiles = files.length;

    for (var ii = 0; ii < nFiles; ii++) {
      const file = files[ii];
      console.log("PreProcessing files", ii, nFiles);
      dispatch({
        type: "SET_DB_PROGRESS",
        headline: "Pre-processing Files",
        status: file.name,
      });
      const input = {
        headers: JSON.stringify(collectionCoreHeaders.map((x) => x.header)),
        canonicalMapperString: JSON.stringify(canonicalMapper),
        canonicalHeadersString: JSON.stringify(
          canonicalHeaders.map((x) => x.header)
        ),
        filePath: file.path.replace(/\./g, ""),
        mainPath: mainPath.replace(/\./g, ""),
        ini_file: file.name,
        ini_index: ii,
        cut: file.cut,
        ref: file.ref,
        updateTableString: JSON.stringify(file.updateTable),
        updateHeadersString: JSON.stringify(updateHeaders.map((x) => x.header)),
        table,
        secondary,
        secondaryTitle,
      };
      await apPy("files", "preProcess", input);
    }
    dispatch({
      type: "SET_DB_PROGRESS",
      headline: "Upload",
      status: "Uploading files to GC",
    });
    console.log("Upload Folder");
    for (ii = 0; ii < nFiles; ii++) {
      dispatch({
        type: "SET_DB_PROGRESS",
        headline: "Uploading to GC",
        status: `Uploading file (${ii} / ${nFiles})`,
      });
      await backend("database", "uploadFolderFile", {
        mainPath,
        table,
        fileIndex: ii,
      });
    }

    for (ii = 0; ii < nFiles; ii++) {
      console.log("Import Folder files", ii, nFiles);
      dispatch({
        type: "SET_DB_PROGRESS",
        headline: "Importing to DB",
        status: `Importing file (${ii} / ${nFiles})`,
      });
      await backend("database", "file2DB", {
        dbtable,
        table,
        fileIndex: ii,
        database,
      });
    }

    dispatch({
      type: "SET_DB_PROGRESS",
      headline: "Preparing DB Date Support",
      status: "Correcting NULL values",
    });
    await backend("database", "correctNullDates", { dbtable, database });

    console.log("Cleaning Up");
    dispatch({
      type: "SET_DB_PROGRESS",
      headline: "Clean up",
      status: "Cleaning up files",
    });
    await backend("database", "clearFolder", { mainPath, table });

    dispatch({ type: "SET_DB_FILES", files: null });
    dispatch({
      type: "ATTACH_TABLE_TO_COLLECTION",
      collectionid: dbtable,
      hasTable: true,
    });
    dispatch(queryDB(dbtable, database));
    dispatch({ type: "SET_MASTER_TABLE", masterTable: null });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const buildFrame =
  (
    collectionid,
    database,
    files,
    mainPath,
    canonicalMapper,
    secondary,
    secondaryTitle
  ) =>
  async (dispatch) => {
    const nFiles = files.length;

    dispatch({
      type: "SET_DB_PROGRESS_MAIN",
      main: "Building Collection Frame",
    });

    for (var ii = 0; ii < nFiles; ii++) {
      const file = files[ii];
      dispatch({
        type: "SET_DB_PROGRESS",
        headline: "Processing Files & Merging into Frame",
        status: `${file.name} (${ii} / ${nFiles})`,
        main: "Build SQL Table",
      });
      const input = {
        headers: JSON.stringify(collectionCoreHeaders.map((x) => x.header)),
        canonicalMapperString: JSON.stringify(canonicalMapper),
        canonicalHeadersString: JSON.stringify(
          canonicalHeaders.map((x) => x.header)
        ),
        filePath: file.path.replace(/\./g, ""),
        mainPath: mainPath.replace(/\./g, ""),
        ini_file: file.name,
        ini_index: ii,
        cut: file.cut,
        ref: file.ref,
        updateTableString: JSON.stringify(file.updateTable),
        updateHeadersString: JSON.stringify(updateHeaders.map((x) => x.header)),
        secondary,
        secondaryTitle,
        collectionid,
      };
      console.log(ii, input, collectionid, database);
      await apPy("files", "col2frame", input);
    }

    await apPy("files", "merge_collection", {
      mainPath: mainPath.replace(/\./g, ""),
      collectionid,
      nFiles,
    });

    dispatch({
      type: "SET_DB_PROGRESS",
      pointer: null,
    });

    let nLines = 0;
    
    const res = await apPy("files", "get_frame_size", {
      collectionid,
      mainPath: mainPath.replace(/\./g, ""),
    });
    if (res.data) {
      nLines = res.data.nLines;
    }
    dispatch({
      type: "ATTACH_FRAME_TO_COLLECTION",
      collectionid,
      hasFrame: true,
      nLines,
    });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const dropTable = (dbtable, database) => (dispatch) => {
  backend("database", "dropTable", { dbtable, database });
  dispatch({ type: "CLEAR_DB" });
  dispatch({
    type: "ATTACH_TABLE_TO_COLLECTION",
    collectionid: dbtable,
    hasTable: false,
  });
  dispatch({ type: "SET_MASTER_TABLE", masterTable: null });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const initMasterTable = (project, cb) => async (dispatch) => {
  const { database, masterTable } = project;
  await backend("database", "initMaster", {
    masterTable,
    columnsString: JSON.stringify(masterHeaders),
    database,
  });
  dispatch({ type: "INIT_MASTER_TABLE" });
  dispatch(notify("Master Table", " Table initialized", 3));

  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
const dbOn = () => (dispatch) => {
  dispatch({ type: "SET_DB_ACCESS", dbaccess: true });
};
const dbOff = () => (dispatch) => {
  dispatch({ type: "SET_DB_ACCESS", dbaccess: false });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const mergeCollectionTable =
  (project, collectionid, autosave, cb) => async (dispatch) => {
    dispatch(dbOn());
    dispatch({ type: "IS_CURRENCY_ADJUSTED", currencyAdjusted: false });

    const { database, masterTable, currency } = project;

    await backend("database", "mergeCollectionTable", {
      masterTable,
      columnsString: JSON.stringify(collectionCoreHeaders),
      collectionid,
      database,
    });

    dispatch({ type: "MERGE_TABLE_WITH_MASTER", collectionid });
    dispatch(
      notify("Master Table", "Collection Table merged successfully", 1.5)
    );
    if (autosave) dispatch(saveProjectAfterTimeout(project));
    dispatch(dbOff());
    cb();
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const setOutputCurrency =
  (project, outputCurrency, cb) => async (dispatch) => {
    dispatch({ type: "RESET_SUMMARY" });
    dispatch({ type: "SET_OUTPUT_CURRENCY", outputCurrency });

    const { database, currency } = project;
    const exchangeRate =
      outputCurrency === "USD" ? 1 : 1 / currency[outputCurrency.toLowerCase()];
    console.log(outputCurrency, exchangeRate);
    dispatch(dbOn());
    await backend("database", "setOutputCurrency", {
      database,
      currency: outputCurrency,
      exchangeRate,
    });
    dispatch(dbOff());
    cb();
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const calculateCurrencyAdjustments =
  (project, cb) => async (dispatch) => {
    dispatch({ type: "SET_OUTPUT_CURRENCY", outputCurrency: "USD" });
    const { database, currency } = project;
    dispatch(dbOn());
    const results = await backend("database", "getMasterCurrencies", {
      database,
    });
    if (results.data) {
      const masterCurrencies = results.data.map((x) => x.Currency);
      console.log("Results are", masterCurrencies);
      for (var ii = 0; ii < masterCurrencies.length; ii++) {
        const thisCurrency = masterCurrencies[ii];
        const thisExchangeRate =
          thisCurrency === "USD" ? 1 : currency[thisCurrency.toLowerCase()];
        console.log(thisCurrency, thisExchangeRate);
        await backend("database", "adjustMasterCurrency", {
          database,
          currency: thisCurrency,
          exchangeRate: thisExchangeRate,
        });
      }
      dispatch({ type: "IS_CURRENCY_ADJUSTED", currencyAdjusted: true });
    }
    dispatch(dbOff());
    cb();
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const upgradeMaster = (project, cb) => async (dispatch) => {
  const { database } = project;
  dispatch(dbOn());
  await backend("database", "upgradeMaster", { database });
  dispatch(dbOff());
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const unMergeCollectionTable =
  (project, collectionid, cb) => async (dispatch) => {
    dispatch(dbOn());
    dispatch({ type: "IS_CURRENCY_ADJUSTED", currencyAdjusted: false });
    const { database, masterTable } = project;
    await backend("database", "unMergeCollectionTable", {
      masterTable,
      collectionid,
      database,
    });
    dispatch({ type: "UNMERGE_TABLE_WITH_MASTER", collectionid });
    dispatch(
      notify("Master Table", "Collection Table removed successfully", 1.5)
    );
    dispatch(saveProjectAfterTimeout(project));
    dispatch(dbOff());
    cb();
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const truncateMaster = (project) => async (dispatch) => {
  dispatch(dbOn());
  const { database, masterTable } = project;
  await backend("database", "truncateMaster", {
    masterTable,
    database,
  });
  dispatch({ type: "TRUNCATE_MASTER" });
  dispatch(notify("Master Table", "Master Truncated", 1.5));
  dispatch(
    initMasterTable(project, () => {
      dispatch(saveProjectAfterTimeout(project));
      dispatch(dbOff());
    })
  );
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setProjectCurrencies = (currency) => async (dispatch) => {
  dispatch({ type: "RESET_SUMMARY" });
  dispatch({ type: "IS_CURRENCY_ADJUSTED", currencyAdjusted: false });

  dispatch({
    type: "UPDATE_CURRENCY",
    currency,
  });
  dispatch(
    notify("Currency Changed", "Make sure to rebuild Master and outputs", 8)
  );
};
