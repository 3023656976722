import React, { useState } from "react";
// ------------------------------------------------------
import File from "Components/File";
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Dir = ({ dir }) => {
  
  const [ state, setState ] = useState(false);

  const openCloseFolder = () => {
    setState(!state);
  }

  const onDragStart = (e) => {
    e.dataTransfer.setData("dir", JSON.stringify(dir));
  }

  const parsePdf = dir.files.filter( x => x.ext === "PDF").length > 0;

  const parsePDF = () => {
    const files = dir.files.filter((x) => x.ext === "PDF");
    console.log("Parsing files:", files);
  }



  return (
    <ul className="file-tree">
      <li
        className={`physical-entry`}
        onClick={openCloseFolder}
        draggable
        onDragStart={onDragStart}
      >
        {state ? (
          <i className="fa-duotone fa-folder-open dirpic" />
        ) : (
          <i className="fa-duotone fa-folder dirpic" />
        )}
        {dir.title}
        {parsePdf ? (
          <div className="button-group folder-buttons">
            <button
              className="button is-rounded is-primary is-outlined is-small"
              onClick={(e) => {
                e.stopPropagation();
                parsePDF();
              }}
            >
              pdf &rarr; csv
            </button>
          </div>
        ) : null}
      </li>
      {state ? (
        <>
          {dir.dirs.map((dir, dirind) => (
            <Dir key={`dir-${dirind}-${dir.title}`} dir={dir} />
          ))}
          {dir.files.map((file, fileindex) => (
            <File file={file} fileindex={fileindex} key={`file-${fileindex}`} />
          ))}
        </>
      ) : null}
    </ul>
  );
}

export default Dir;