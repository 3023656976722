import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import waitbar from "Media/waitbar.gif";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({ suggesting, close, hot, suggestions, update }) => {
  if (!suggesting) return null;
  const nUpdates = suggestions
    ? suggestions.filter((x) => !(x.isEqual && x.isEqual2)).length
    : null;

  const accept = () => {
    suggestions
      .filter((x) => !x.isEqual)
      .forEach((x) => {
        console.log(x);
        const ref = hot.current.hotInstance;
        ref.setSourceDataAtCell(
          x.dataindex,
          "Normalized_Income_Type_9LC",
          x.suggested
        );
      });
    suggestions
      .filter((x) => !x.isEqual2)
      .forEach((x) => {
        console.log(x);
        const ref = hot.current.hotInstance;
        ref.setSourceDataAtCell(
          x.dataindex,
          "Normalized_Income_Type_II_9LC",
          x.suggested2
        );
      });
    update();
    close();
  };

  return (
    <div className="modal is-active" style={{ zIndex: 9999 }}>
      <div className="modal-background"></div>
      <div className="modal-card wide-screen">
        <header className="modal-card-head">
          <p className="modal-card-title">Income Type Suggestions</p>
          <button onClick={close} className="delete" aria-label="close" />
        </header>

        <section className="modal-card-body">
          {suggestions === null ? (
            <img src={waitbar} />
          ) : (
            <div className="table-container">
              <table className="table is-fullwidth is-striped">
                <thead>
                  <tr>
                    <th>Income Type</th>
                    <th>Third Party</th>
                    <th>Current</th>
                    <th>Suggested</th>
                    <th>Match</th>
                    <th>Current&nbsp;II</th>
                    <th>Suggested&nbsp;II</th>
                    <th>Match&nbsp;II</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {suggestions.map((suggestion, index) => (
                    <tr key={`suggestions-${index}`}>
                      <td>{suggestion.income}</td>
                      <td>{suggestion.thirdparty}</td>
                      <td>{suggestion.current}</td>
                      <td>{suggestion.suggested}</td>
                      <td>
                        {suggestion.isEqual ? (
                          <i className="fa fa-check" />
                        ) : (
                          <div className="tag is-danger">
                            <i className="fa fa-times" />
                          </div>
                        )}
                      </td>
                      <td>{suggestion.current2}</td>
                      <td>{suggestion.suggested2}</td>
                      <td>
                        {suggestion.isEqual2 ? (
                          <i className="fa fa-check" />
                        ) : (
                          <div className="tag is-danger">
                            <i className="fa fa-times" />
                          </div>
                        )}
                      </td>

                      <td>
                        {suggestion.isEqual || suggestion.isEqual2 ? null : (
                          <button className="delete" />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </section>

        <footer className="modal-card-foot">
          {nUpdates ? (
            <button className="button is-primary" onClick={accept}>
              Accept suggestions ({nUpdates})
            </button>
          ) : null}
          <button className="button">Discard</button>
        </footer>
      </div>
    </div>
  );
};
export default Index;
