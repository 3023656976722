import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
// -------------------------------------------------------------
import {
  mergeCollectionFrame,
  unmergeCollectionFrame,
  clearAllFrames,
  mergeAllFrames,
} from "Redux/flyway/actions";

import ProgressBar from "Components/ProgressBar";

// -------------------------------------------------------------
// -------------------------------------------------------------
const MergeFrames = () => {
  const { project } = useSelector((state) => state.projects);
  const dispatch = useDispatch();

  if (!project) return null;

  const repo = project.collectionsRepo;
  const allCollections = Object.keys(repo)
    .map((x) => repo[x])
    .filter((x) => x.hasFrame); //.find( collection => collection.hasFrame);
  const inside = allCollections.filter((x) => project.frames.includes(x.id));
  const outside = allCollections.filter((x) => !project.frames.includes(x.id));
  const ncolls = inside.length;
  return (
    <div>
      <ProgressBar />
      {project.hasFrame ? (
        <div className = "notification">All frames are merged</div>
      ) : (
        <div className="buttons">
          <button
            className="button is-primary"
            onClick={() => {
              dispatch(mergeAllFrames(allCollections));
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-plus-circle" />
            </span>
            <span>Add All Frames</span>
          </button>
        </div>
      )}
      <br />
      <div className="columns">
        <div className="column">
          <div className="title is-5">Collection Frames</div>
          <div className="box">
            <div className="tags">
              {allCollections.map((collection) => (
                <AnyTag key={collection.id} collection={collection} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MergeFrames;

const AnyTag = ({ collection, ncolls }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return <div className="tag is-info is-light">{collection.headline}</div>;
};

const InsideTag = ({ collection, ncolls }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <button
      className={`button is-info is-light is-clickable ${
        loading ? "is-loading" : ""
      }`}
      onClick={() => {
        setLoading(true);
        dispatch(unmergeCollectionFrame(collection.id, () => {}));
      }}
    >
      <span className="icon is-small">
        <i className="fa-duotone fa-minus-circle" />
      </span>
      <span>{collection.headline}</span>
    </button>
  );
};

const OutsideTag = ({ collection, ncolls }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <button
      className={`button is-warning is-light is-clickable ${
        loading ? "is-loading" : ""
      }`}
      onClick={() => {
        setLoading(true);
        dispatch(mergeCollectionFrame(collection.id, ncolls, () => {}));
      }}
    >
      <span className="icon is-small">
        <i className="fa-duotone fa-plus-circle" />
      </span>
      <span>{collection.headline}</span>
    </button>
  );
};
