import { useSelector, useDispatch } from 'react-redux';
import { useState } from "react";
// -------------------------------------------------------------
import { 
  updateCSVInfo, runSumFile, hideFileViewer, 
  updateCut, updateRef
} from "Redux/files/actions";
import { downloadFile } from "System/system";
// -------------------------------------------------------------
import "./css.css";
import spin from "Media/spin.gif";

const clean = str => {
  if(typeof(str)!=='string') return str;
  if(str===null) return null;
  return str.replace(/ /g, "\u00A0");
}

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {

  const dispatch = useDispatch();
  const files = useSelector( state => state.files );
  const [sums, setSums] = useState({});
  const [top, setTop] = useState(6);
  const [bottom, setBottom] = useState(6);

  if(!files.viewer) return null;
  const { data } = files.viewer;
  if(!data) return null;
  const { headers, head, tail, file } = data;

  const filestring = data ? (
    file.name.length <= 700 ?
    file.name : 
    file.name.substr(0, 700) + '...'
  ) : '';

  const incTopCut = () => {
    dispatch(updateCut(data.file, file.cut+1))
  }
  const decTopCut = () => {
    if(file.cut > 0) {
      dispatch(updateCut(data.file, file.cut-1))
    }
  }

  const incTopView = () => {
    dispatch(updateCSVInfo(data.file, top+1, bottom))
    setTop(top+1);
  }

  const decTopView = () => {
    if(top>0) {
      dispatch(updateCSVInfo(data.file, top-1, bottom))
      setTop(top-1);
    }
  }
  const incBottomView = () => {
    dispatch(updateCSVInfo(data.file, top, bottom+1))
    setBottom(bottom+1);
  }

  const decBottomView = () => {
    console.log("Dec Bottom", bottom);
    if(bottom>0) {
      dispatch(updateCSVInfo(data.file, top, bottom-1))
      setBottom(bottom-1);
    }
  }

  return(
    <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card wide-screen viewer" >
      <header className="modal-card-head">
        <div className = "tag is-warning">{ filestring }</div>
        <p className="modal-card-title" />
        <button 
          className="delete"
          onClick = { () => { dispatch(hideFileViewer()) } } 
          aria-label="close"
        />
      </header>
      <section className="modal-card-body">
        {
          data ? (
            <div className = "box table-container">
              <div className = "table-container">
                <table className = "table is-small is-fullwidth is-striped is-hoverable is-bordered">
                  <thead>
                    <tr>
                      {
                        headers.map(
                          (header, headerindex) => {
                            return(
                              <th key = { `${header}-${headerindex}` }>
                                {
                                  sums.hasOwnProperty(header) ? 
                                    <span>
                                      { header.replace(/ /g, "\u00A0") } (&Sigma;&nbsp;=&nbsp;{sums[header]})
                                    </span> :
                                    <a 
                                      onClick = { () => {  dispatch(updateRef(file, header)) } }
                                    >
                                      { header.replace(/ /g, "\u00A0") }
                                    </a>                          
                                }
                              </th>                              
                            )
                          }
                        )
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      head.map(
                        (headRow, headIndex) => {
                          return(
                            <tr key = {`head-row-${headIndex}`}>
                              {
                                headers.map(
                                  header => {
                                    return(
                                      <td key = {`${header}-${headIndex}`}>
                                        { clean(headRow[header]) }
                                      </td>
                                    )
                                  }
                                )
                              }
                            </tr>
                          )
                        }
                      )
                    }
                    <tr></tr>
                    <tr>
                      <td colSpan = { headers.length } style = {{ height: 38, backgroundColor: '#ddd', opacity: 0.5 }}>
                        <i className="fa-duotone fa-ellipsis-v" style = {{ marginLeft: 150, marginTop: 8,  fontSize: 17 }} />
                      </td>
                    </tr>
                    {
                      tail.map(
                        (tailRow, tailIndex) => {
                          return(
                            <tr key = {`tail-row-${tailIndex}`}>
                              {
                                headers.map(
                                  header => {
                                    return(
                                      <td key = {`${header}-tail-${tailIndex}`}>
                                        {clean(tailRow[header])}
                                      </td>
                                    )
                                  }
                                )
                              }
                            </tr>
                          )
                        }
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className = "please-wait">
              <img src = { spin } />
            </div>  
          )
        }
      </section>
      {
        data === null ? (
          <footer className="modal-card-foot" />
        ) : (
          <footer className="modal-card-foot">

            <div className = "field has-addons unit">
              <p className = "control">
                <button className = "button is-small" onClick = { () => { decTopCut() }}>
                  <span className = "icon">
                    <i className = "fa fa-caret-left" />                
                  </span>
                </button>
              </p>
              <p className = "control">
                <button className = "button is-small">
                  <span className = "icon is-small">
                    <i className="fa-duotone fa-cut" />
                  </span>
                  <span>{ file.cut }</span>
                </button>
              </p>
              <p className = "control">
                <button className = "button is-small" onClick = { () => { incTopCut() }}>
                  <span className = "icon">
                    <i className = "fa fa-caret-right" />                  
                  </span>
                </button>
              </p>
            </div>

            <div className = "field has-addons unit">
              <p className = "control">
                <button className = "button is-small" onClick = { () => { decTopView() }}>
                  <span className = "icon">
                    <i className = "fa fa-caret-left" />                  
                  </span>
                </button>
              </p>
              <p className = "control">
                <button className = "button is-small">
                  <span className = "icon is-small">
                    <i className="fa-duotone fa-eye" />
                  </span>
                  <span>Top: { top }</span>
                </button>
              </p>
              <p className = "control">
                <button className = "button is-small" onClick = { () => { incTopView() }}>
                  <span className = "icon">
                    <i className = "fa fa-caret-right" />                  
                  </span>
                </button>
              </p>
            </div>

            <div className = "field has-addons">
              <p className = "control">
                <button className = "button is-small" onClick = { () => { decBottomView() }}>
                  <span className = "icon">
                    <i className = "fa fa-caret-left" />                  
                  </span>
                </button>
              </p>
              <p className = "control">
                <button className = "button is-small">
                  <span className = "icon is-small">
                    <i className="fa-duotone fa-eye" />
                  </span>
                  <span>Bottom: { bottom }</span>
                </button>
              </p>
              <p className = "control">
                <button className = "button is-small" onClick = { () => { incBottomView() }}>
                  <span className = "icon">
                    <i className = "fa fa-caret-right" />                  
                  </span>
                </button>
              </p>
            </div>

            <div className = "field has-addons">
              <p className = "control">
                <button 
                  className = "button is-small"
                  onClick = {
                    () => { downloadFile(`${data.file.path}/${data.file.name}`, data.file.name) }
                  }
                >
                  <span className = "icon is-small">
                    <i className="fa-duotone fa-cloud-download-alt" />
                  </span>
                  <span>Download</span>
                </button>
              </p>
            </div>
            <div className = "field has-addons">
              {
                file.ref ? <>
                  <div 
                    className = "tag is-light is-primary" 
                    onClick = { () => {dispatch(updateRef(file, null)) }}
                    style = {{ cursor: 'pointer' }}
                  >
                    Ref&nbsp;<b>{ file.ref }</b>
                  </div>
                </> : null
              }
            </div>

          </footer>
        )
      }
    </div>
  </div>
  )
}
export default Index;