import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone'
// ------------------------------------------------------
import running from "Media/running.gif";
// ------------------------------------------------------
import { upload } from "System/system";
import { showHideConverters, startUnzipping } from "Redux/conversions/actions";
// ------------------------------------------------------
import "./css.css";


// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {

  const [ state, setState ] = useState('upload');

  const dispatch = useDispatch();

  const conversions = useSelector((state) => state.conversions);
  const { converters } = conversions;
  const projects = useSelector( state => state.projects );
  const { project } = projects;
  const extensions = Object.keys(converters);

  useEffect( 
    () => { dispatch({ type: "INIT_CONVERTERS" }) }, 
    [] 
  );

  const onDrop = async acceptedFiles => {
    setState('processing');
    const formData = new FormData();    
    formData.append( "filetoupload", acceptedFiles[0] );
    formData.append( "projectid", project._id );
    const result = await upload(
      formData, pointer => { 
        dispatch({
          type: "SET_CHECKIN_PROGRESS",
          pointer, ext: 'upload'
        })
      }
    );
    const { data: { originalFolder, tag } } = result;
    dispatch(startUnzipping(originalFolder, tag));
  }

  const { getRootProps, getInputProps } = useDropzone({onDrop})

  return(
    <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card wide-screen" >
      <header className="modal-card-head convert">
        <div className = "header">
        <p className="modal-card-title">
          New Check-In
        </p>
        <button className = "delete" onClick={() => {
          dispatch(showHideConverters(false));
        }}/>
        </div>
      </header>
      <section className="modal-card-body">
      { 
        state === 'upload' ? (
          <div className="drop-zone-wrapper box">
            <div { ...getRootProps() }  className = "drop-zone" >
              <input { ...getInputProps() }/>
              <i className="fa-duotone fa-file-archive zipper" />
            </div>  
          </div>
        ) : (
          <table className="table is-fullwidth converter-table">
            <tbody>
              {extensions.map((ext) => (
                <ProgressRow converter={converters[ext]} key={ext} />
              ))}
            </tbody>
          </table>
        ) 
      }
      </section>
      <footer className="modal-card-foot">
      </footer>
    </div>
  </div>
  )
}
export default Index;

// ---------------------------------------------------------------
// ---------------------------------------------------------------
const ProgressRow = ({ converter }) => {
  const { nFiles, pointer, status } = converter;
  return (
    <tr>
      <td>{ converter.headline }</td>
      <td>
        {
          status === null ? null : (
            status === 'progress' ? (
              <img src={running} alt="" />
            ) : (
              <span className="icon is-small">
                <i className="fa-duotone fa-check converter" />
              </span> 
            )
          )
        }
      </td>
      <td className = "pt">{ status === 'progress' ? pointer : ''  }</td>
      <td>{ status === 'progress' ? nFiles : ''  }</td>
      <td width = "40%">
        {
          status === 'progress' ? (
            (nFiles===null || pointer===null) ? 
              <progress className="progress is-warning" value={null} /> :
              <progress className="progress is-warning" value={pointer} max={nFiles} />  
            ) : (
              status === 'done' ? 
                <progress className="progress is-primary" value={10} max={10} /> : 
                <progress className="progress is-primary" value={0} max={10} /> 
            )
        }
      </td>
    </tr>
  );
};
