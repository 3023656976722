export const normKeys = {
  song: "songNormingString",
  income: "incomeNormingString",
  source: "sourceNormingString",
};

export const syncKeys = {
  song: "songUnsynced",
  income: "incomeUnsynced",
  source: "sourceUnsynced",
};
