import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
// --------------------------------------------------------------------
import { signOut } from "Redux/user/actions";
import {
  saveProject,
  showPivotPopup,
  eraseProject,
} from "Redux/projects/actions";
// --------------------------------------------------------------------
import { swatches } from "Data/swatches";
// --------------------------------------------------------------------
import SetPivot from "./SetPivot/";
// --------------------------------------------------------------------
import bird from "Media/logo_newer.png";
import shortbar from "Media/short-bar.gif";

// --------------------------------------------------------------------
import "./frame.css";

// --------------------------------------------------------------------
// --------------------------------------------------------------------
const Frame = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [active, setActive] = useState("");
  const user = useSelector((state) => state.user);
  const projects = useSelector((state) => state.projects);

  const { project, unsaved } = projects;
  const isHidden = false; //project === null || project.mainDir === null ? "hidden" : "";

  const setSwatch = (swatch) => {
    localStorage.setItem("swatch", swatch);
    window.location.reload(true);
  };

  const erase = () => {
    if (window.confirm("Are you sure? This action cannot be undone.")) {
      dispatch(eraseProject(projects.project));
      history.push("/");
    }
  };

  // --------------------------------------------------------------------
  const onHamburger = () => {
    setActive(active === "is-active" ? "" : "is-active");
  };

  // --------------------------------------------------------------------
  return (
    <div className="frame-wrapper">
      {user.note ? (
        <div className="notifier-wrapper">
          <div className="notification is-primary is-light">
            <span>{user.note.headline}</span>
            <br />
            <b>{user.note.content}</b>
          </div>
        </div>
      ) : null}
      <nav
        className="navbar project-nav"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">
              <img src={bird} alt="Songbird Logo" />
            </Link>
          </div>
          <a
            role="button"
            className={`navbar-burger burger ${active}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={onHamburger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isHidden}`}>
          <div className="navbar-start">
            {
              false && (
                <a className="navbar-item">
                <div className="field has-addons">
                  <p className="control">
                    <button
                      className={`button ${unsaved ? "is-warning" : ""}`}
                      onClick={() => {
                        dispatch(saveProject(project));
                      }}
                      disabled={!unsaved}
                    >
                      <span className="icon is-small">
                        <i className="fa-duotone fa-save"></i>
                      </span>
                      <span>Save</span>
                    </button>
                  </p>
                </div>
              </a>
              )
            }
            <div className={`navbar-item ${user.dbaccess ? "" : "nav-hidden"}`}>
              <span className="icon is-small">
                <i className="fa-duotone fa-database db-access" />
              </span>
              <img src={shortbar} />
            </div>
            <a
              className="navbar-item"
              onClick={() => {
                dispatch(showPivotPopup(true));
              }}
            >
              <div className="field has-addons">
                {project ? (
                  <p className="control">
                    {project.isPerformer ? (
                      <div className="tag is-info is-light">
                        {project.pivot}&nbsp;|&nbsp; &nbsp;
                        <span className="icon is-small">
                          &nbsp;
                          <i className="fa-duotone fa-guitar" />
                          &nbsp; &nbsp;
                        </span>
                      </div>
                    ) : (
                      <div className="tag is-primary is-light">
                        {project.pivot}
                      </div>
                    )}
                  </p>
                ) : null}
              </div>
            </a>
          </div>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="tag is-info is-light">V{user.version}</div>
          </div>

          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">{user.user}</a>
            <div className="navbar-dropdown">
              <a className="navbar-item" onClick={erase}>
                Erase Project
              </a>
              <a
                className="navbar-item"
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                Sign Out
              </a>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable swatches">
            <a className="navbar-link">
              <i className="fa-duotone fa-paint-brush" />
            </a>
            <div className="navbar-dropdown swatches-list">
              {swatches.map((swatch) => (
                <a
                  key={swatch}
                  className="navbar-item swatches-list-item"
                  onClick={() => {
                    setSwatch(swatch);
                  }}
                >
                  {swatch.at(0).toUpperCase() + swatch.slice(1)}
                </a>
              ))}
            </div>
          </div>
        </div>
      </nav>
      {project ? projects.pivotPopup ? <SetPivot /> : null : null}
      {children}
    </div>
  );
};

export default Frame;
