export const countries = [
  {
    country: "Afghanistan",
    "alpha-2": "AF",
    "alpha-3": "AFG",
    "country-code": 4,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Åland Islands",
    "alpha-2": "AX",
    "alpha-3": "ALA",
    "country-code": 248,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Albania",
    "alpha-2": "AL",
    "alpha-3": "ALB",
    "country-code": 8,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Algeria",
    "alpha-2": "DZ",
    "alpha-3": "DZA",
    "country-code": 12,
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "American Samoa",
    "alpha-2": "AS",
    "alpha-3": "ASM",
    "country-code": 16,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Andorra",
    "alpha-2": "AD",
    "alpha-3": "AND",
    "country-code": 20,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Angola",
    "alpha-2": "AO",
    "alpha-3": "AGO",
    "country-code": 24,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Anguilla",
    "alpha-2": "AI",
    "alpha-3": "AIA",
    "country-code": 660,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Antarctica",
    "alpha-2": "AQ",
    "alpha-3": "ATA",
    "country-code": 10,
    region: "",
    "sub-region": "",
    "intermediate-region": "",
    "hipgnosis-region-code": "",
    domestic: 0,
  },
  {
    country: "Antigua and Barbuda",
    "alpha-2": "AG",
    "alpha-3": "ATG",
    "country-code": 28,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Argentina",
    "alpha-2": "AR",
    "alpha-3": "ARG",
    "country-code": 32,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Armenia",
    "alpha-2": "AM",
    "alpha-3": "ARM",
    "country-code": 51,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Aruba",
    "alpha-2": "AW",
    "alpha-3": "ABW",
    "country-code": 533,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Australia",
    "alpha-2": "AU",
    "alpha-3": "AUS",
    "country-code": 36,
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Austria",
    "alpha-2": "AT",
    "alpha-3": "AUT",
    "country-code": 40,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Azerbaijan",
    "alpha-2": "AZ",
    "alpha-3": "AZE",
    "country-code": 31,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Bahamas",
    "alpha-2": "BS",
    "alpha-3": "BHS",
    "country-code": 44,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Bahrain",
    "alpha-2": "BH",
    "alpha-3": "BHR",
    "country-code": 48,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Bangladesh",
    "alpha-2": "BD",
    "alpha-3": "BGD",
    "country-code": 50,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Barbados",
    "alpha-2": "BB",
    "alpha-3": "BRB",
    "country-code": 52,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Belarus",
    "alpha-2": "BY",
    "alpha-3": "BLR",
    "country-code": 112,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Belgium",
    "alpha-2": "BE",
    "alpha-3": "BEL",
    "country-code": 56,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Belize",
    "alpha-2": "BZ",
    "alpha-3": "BLZ",
    "country-code": 84,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Benin",
    "alpha-2": "BJ",
    "alpha-3": "BEN",
    "country-code": 204,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Bermuda",
    "alpha-2": "BM",
    "alpha-3": "BMU",
    "country-code": 60,
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "hipgnosis-region-code": "",
    domestic: 0,
  },
  {
    country: "Bhutan",
    "alpha-2": "BT",
    "alpha-3": "BTN",
    "country-code": 64,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Bolivia",
    "alpha-2": "BO",
    "alpha-3": "BOL",
    "country-code": 68,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Bonaire, Sint Eustatius and Saba",
    "alpha-2": "BQ",
    "alpha-3": "BES",
    "country-code": 535,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Bosnia And Herzegovina",
    "alpha-2": "BA",
    "alpha-3": "BIH",
    "country-code": 70,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Botswana",
    "alpha-2": "BW",
    "alpha-3": "BWA",
    "country-code": 72,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Bouvet Island",
    "alpha-2": "BV",
    "alpha-3": "BVT",
    "country-code": 74,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Brazil",
    "alpha-2": "BR",
    "alpha-3": "BRA",
    "country-code": 76,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "British Indian Ocean Territory",
    "alpha-2": "IO",
    "alpha-3": "IOT",
    "country-code": 86,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Brunei Darussalam",
    "alpha-2": "BN",
    "alpha-3": "BRN",
    "country-code": 96,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Bulgaria",
    "alpha-2": "BG",
    "alpha-3": "BGR",
    "country-code": 100,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Burkina Faso",
    "alpha-2": "BF",
    "alpha-3": "BFA",
    "country-code": 854,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Burundi",
    "alpha-2": "BI",
    "alpha-3": "BDI",
    "country-code": 108,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Cabo Verde",
    "alpha-2": "CV",
    "alpha-3": "CPV",
    "country-code": 132,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Cambodia",
    "alpha-2": "KH",
    "alpha-3": "KHM",
    "country-code": 116,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Cameroon",
    "alpha-2": "CM",
    "alpha-3": "CMR",
    "country-code": 120,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Canada",
    "alpha-2": "CA",
    "alpha-3": "CAN",
    "country-code": 124,
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Cayman Islands",
    "alpha-2": "KY",
    "alpha-3": "CYM",
    "country-code": 136,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Central African Republic",
    "alpha-2": "CF",
    "alpha-3": "CAF",
    "country-code": 140,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Chad",
    "alpha-2": "TD",
    "alpha-3": "TCD",
    "country-code": 148,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Chile",
    "alpha-2": "CL",
    "alpha-3": "CHL",
    "country-code": 152,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "China",
    "alpha-2": "CN",
    "alpha-3": "CHN",
    "country-code": 156,
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Christmas Island",
    "alpha-2": "CX",
    "alpha-3": "CXR",
    "country-code": 162,
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Cocos (Keeling) Islands",
    "alpha-2": "CC",
    "alpha-3": "CCK",
    "country-code": 166,
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Colombia",
    "alpha-2": "CO",
    "alpha-3": "COL",
    "country-code": 170,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Comoros",
    "alpha-2": "KM",
    "alpha-3": "COM",
    "country-code": 174,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Congo",
    "alpha-2": "CG",
    "alpha-3": "COG",
    "country-code": 178,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Congo (Democratic Republic Of The)",
    "alpha-2": "CD",
    "alpha-3": "COD",
    "country-code": 180,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Cook Islands",
    "alpha-2": "CK",
    "alpha-3": "COK",
    "country-code": 184,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Costa Rica",
    "alpha-2": "CR",
    "alpha-3": "CRI",
    "country-code": 188,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Côte D'Ivoire",
    "alpha-2": "CI",
    "alpha-3": "CIV",
    "country-code": 384,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Croatia",
    "alpha-2": "HR",
    "alpha-3": "HRV",
    "country-code": 191,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Cuba",
    "alpha-2": "CU",
    "alpha-3": "CUB",
    "country-code": 192,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Curaçao",
    "alpha-2": "CW",
    "alpha-3": "CUW",
    "country-code": 531,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Cyprus",
    "alpha-2": "CY",
    "alpha-3": "CYP",
    "country-code": 196,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Czech Republic",
    "alpha-2": "CZ",
    "alpha-3": "CZE",
    "country-code": 203,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Denmark",
    "alpha-2": "DK",
    "alpha-3": "DNK",
    "country-code": 208,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Djibouti",
    "alpha-2": "DJ",
    "alpha-3": "DJI",
    "country-code": 262,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Dominica",
    "alpha-2": "DM",
    "alpha-3": "DMA",
    "country-code": 212,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Dominican Republic",
    "alpha-2": "DO",
    "alpha-3": "DOM",
    "country-code": 214,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Ecuador",
    "alpha-2": "EC",
    "alpha-3": "ECU",
    "country-code": 218,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Egypt",
    "alpha-2": "EG",
    "alpha-3": "EGY",
    "country-code": 818,
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "El Salvador",
    "alpha-2": "SV",
    "alpha-3": "SLV",
    "country-code": 222,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Equatorial Guinea",
    "alpha-2": "GQ",
    "alpha-3": "GNQ",
    "country-code": 226,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Eritrea",
    "alpha-2": "ER",
    "alpha-3": "ERI",
    "country-code": 232,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Estonia",
    "alpha-2": "EE",
    "alpha-3": "EST",
    "country-code": 233,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Eswatini",
    "alpha-2": "SZ",
    "alpha-3": "SWZ",
    "country-code": 748,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Ethiopia",
    "alpha-2": "ET",
    "alpha-3": "ETH",
    "country-code": 231,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Falkland Islands (Malvinas)",
    "alpha-2": "FK",
    "alpha-3": "FLK",
    "country-code": 238,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Faroe Islands",
    "alpha-2": "FO",
    "alpha-3": "FRO",
    "country-code": 234,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Fiji",
    "alpha-2": "FJ",
    "alpha-3": "FJI",
    "country-code": 242,
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Finland",
    "alpha-2": "FI",
    "alpha-3": "FIN",
    "country-code": 246,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "France",
    "alpha-2": "FR",
    "alpha-3": "FRA",
    "country-code": 250,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "French Guiana",
    "alpha-2": "GF",
    "alpha-3": "GUF",
    "country-code": 254,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "French Polynesia",
    "alpha-2": "PF",
    "alpha-3": "PYF",
    "country-code": 258,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "French Southern Territories",
    "alpha-2": "TF",
    "alpha-3": "ATF",
    "country-code": 260,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Gabon",
    "alpha-2": "GA",
    "alpha-3": "GAB",
    "country-code": 266,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Gambia",
    "alpha-2": "GM",
    "alpha-3": "GMB",
    "country-code": 270,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Georgia",
    "alpha-2": "GE",
    "alpha-3": "GEO",
    "country-code": 268,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Germany",
    "alpha-2": "DE",
    "alpha-3": "DEU",
    "country-code": 276,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Ghana",
    "alpha-2": "GH",
    "alpha-3": "GHA",
    "country-code": 288,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Gibraltar",
    "alpha-2": "GI",
    "alpha-3": "GIB",
    "country-code": 292,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Greece",
    "alpha-2": "GR",
    "alpha-3": "GRC",
    "country-code": 300,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Greenland",
    "alpha-2": "GL",
    "alpha-3": "GRL",
    "country-code": 304,
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "hipgnosis-region-code": "",
    domestic: 0,
  },
  {
    country: "Grenada",
    "alpha-2": "GD",
    "alpha-3": "GRD",
    "country-code": 308,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Guadeloupe",
    "alpha-2": "GP",
    "alpha-3": "GLP",
    "country-code": 312,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Guam",
    "alpha-2": "GU",
    "alpha-3": "GUM",
    "country-code": 316,
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Guatemala",
    "alpha-2": "GT",
    "alpha-3": "GTM",
    "country-code": 320,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Guernsey",
    "alpha-2": "GG",
    "alpha-3": "GGY",
    "country-code": 831,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "Channel Islands",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Guinea",
    "alpha-2": "GN",
    "alpha-3": "GIN",
    "country-code": 324,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Guinea Bissau",
    "alpha-2": "GW",
    "alpha-3": "GNB",
    "country-code": 624,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Guyana",
    "alpha-2": "GY",
    "alpha-3": "GUY",
    "country-code": 328,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Haiti",
    "alpha-2": "HT",
    "alpha-3": "HTI",
    "country-code": 332,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Heard Island and McDonald Islands",
    "alpha-2": "HM",
    "alpha-3": "HMD",
    "country-code": 334,
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Holy See",
    "alpha-2": "VA",
    "alpha-3": "VAT",
    "country-code": 336,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Honduras",
    "alpha-2": "HN",
    "alpha-3": "HND",
    "country-code": 340,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Hong Kong",
    "alpha-2": "HK",
    "alpha-3": "HKG",
    "country-code": 344,
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Hungary",
    "alpha-2": "HU",
    "alpha-3": "HUN",
    "country-code": 348,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Iceland",
    "alpha-2": "IS",
    "alpha-3": "ISL",
    "country-code": 352,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "India",
    "alpha-2": "IN",
    "alpha-3": "IND",
    "country-code": 356,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Indonesia",
    "alpha-2": "ID",
    "alpha-3": "IDN",
    "country-code": 360,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Iran",
    "alpha-2": "IR",
    "alpha-3": "IRN",
    "country-code": 364,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Iraq",
    "alpha-2": "IQ",
    "alpha-3": "IRQ",
    "country-code": 368,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Ireland",
    "alpha-2": "IE",
    "alpha-3": "IRL",
    "country-code": 372,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Isle of Man",
    "alpha-2": "IM",
    "alpha-3": "IMN",
    "country-code": 833,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Israel",
    "alpha-2": "IL",
    "alpha-3": "ISR",
    "country-code": 376,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Italy",
    "alpha-2": "IT",
    "alpha-3": "ITA",
    "country-code": 380,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Jamaica",
    "alpha-2": "JM",
    "alpha-3": "JAM",
    "country-code": 388,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Japan",
    "alpha-2": "JP",
    "alpha-3": "JPN",
    "country-code": 392,
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Jersey",
    "alpha-2": "JE",
    "alpha-3": "JEY",
    "country-code": 832,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "Channel Islands",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Jordan",
    "alpha-2": "JO",
    "alpha-3": "JOR",
    "country-code": 400,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Kazakhstan",
    "alpha-2": "KZ",
    "alpha-3": "KAZ",
    "country-code": 398,
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Kenya",
    "alpha-2": "KE",
    "alpha-3": "KEN",
    "country-code": 404,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Kiribati",
    "alpha-2": "KI",
    "alpha-3": "KIR",
    "country-code": 296,
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Korea, Republic of",
    "alpha-2": "KR",
    "alpha-3": "KOR",
    "country-code": 410,
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Kuwait",
    "alpha-2": "KW",
    "alpha-3": "KWT",
    "country-code": 414,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Kyrgyzstan",
    "alpha-2": "KG",
    "alpha-3": "KGZ",
    "country-code": 417,
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Laos",
    "alpha-2": "LA",
    "alpha-3": "LAO",
    "country-code": 418,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Latvia",
    "alpha-2": "LV",
    "alpha-3": "LVA",
    "country-code": 428,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Lebanon",
    "alpha-2": "LB",
    "alpha-3": "LBN",
    "country-code": 422,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Lesotho",
    "alpha-2": "LS",
    "alpha-3": "LSO",
    "country-code": 426,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Liberia",
    "alpha-2": "LR",
    "alpha-3": "LBR",
    "country-code": 430,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Libya",
    "alpha-2": "LY",
    "alpha-3": "LBY",
    "country-code": 434,
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Liechtenstein",
    "alpha-2": "LI",
    "alpha-3": "LIE",
    "country-code": 438,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Lithuania",
    "alpha-2": "LT",
    "alpha-3": "LTU",
    "country-code": 440,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Luxembourg",
    "alpha-2": "LU",
    "alpha-3": "LUX",
    "country-code": 442,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Macao",
    "alpha-2": "MO",
    "alpha-3": "MAC",
    "country-code": 446,
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Macedonia",
    "alpha-2": "MK",
    "alpha-3": "MKD",
    "country-code": 807,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Madagascar",
    "alpha-2": "MG",
    "alpha-3": "MDG",
    "country-code": 450,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Malawi",
    "alpha-2": "MW",
    "alpha-3": "MWI",
    "country-code": 454,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Malaysia",
    "alpha-2": "MY",
    "alpha-3": "MYS",
    "country-code": 458,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Maldives",
    "alpha-2": "MV",
    "alpha-3": "MDV",
    "country-code": 462,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Mali",
    "alpha-2": "ML",
    "alpha-3": "MLI",
    "country-code": 466,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Malta",
    "alpha-2": "MT",
    "alpha-3": "MLT",
    "country-code": 470,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Marshall Islands",
    "alpha-2": "MH",
    "alpha-3": "MHL",
    "country-code": 584,
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Martinique",
    "alpha-2": "MQ",
    "alpha-3": "MTQ",
    "country-code": 474,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Mauritania",
    "alpha-2": "MR",
    "alpha-3": "MRT",
    "country-code": 478,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Mauritius",
    "alpha-2": "MU",
    "alpha-3": "MUS",
    "country-code": 480,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Mayotte",
    "alpha-2": "YT",
    "alpha-3": "MYT",
    "country-code": 175,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Mexico",
    "alpha-2": "MX",
    "alpha-3": "MEX",
    "country-code": 484,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Micronesia (Federated States of)",
    "alpha-2": "FM",
    "alpha-3": "FSM",
    "country-code": 583,
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Moldova",
    "alpha-2": "MD",
    "alpha-3": "MDA",
    "country-code": 498,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Monaco",
    "alpha-2": "MC",
    "alpha-3": "MCO",
    "country-code": 492,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Mongolia",
    "alpha-2": "MN",
    "alpha-3": "MNG",
    "country-code": 496,
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Montenegro",
    "alpha-2": "ME",
    "alpha-3": "MNE",
    "country-code": 499,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Montserrat",
    "alpha-2": "MS",
    "alpha-3": "MSR",
    "country-code": 500,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Morocco",
    "alpha-2": "MA",
    "alpha-3": "MAR",
    "country-code": 504,
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Mozambique",
    "alpha-2": "MZ",
    "alpha-3": "MOZ",
    "country-code": 508,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Myanmar",
    "alpha-2": "MM",
    "alpha-3": "MMR",
    "country-code": 104,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Namibia",
    "alpha-2": "NA",
    "alpha-3": "NAM",
    "country-code": 516,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Nauru",
    "alpha-2": "NR",
    "alpha-3": "NRU",
    "country-code": 520,
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Nepal",
    "alpha-2": "NP",
    "alpha-3": "NPL",
    "country-code": 524,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Netherlands",
    "alpha-2": "NL",
    "alpha-3": "NLD",
    "country-code": 528,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "New Caledonia",
    "alpha-2": "NC",
    "alpha-3": "NCL",
    "country-code": 540,
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "New Zealand",
    "alpha-2": "NZ",
    "alpha-3": "NZL",
    "country-code": 554,
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Nicaragua",
    "alpha-2": "NI",
    "alpha-3": "NIC",
    "country-code": 558,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Niger",
    "alpha-2": "NE",
    "alpha-3": "NER",
    "country-code": 562,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Nigeria",
    "alpha-2": "NG",
    "alpha-3": "NGA",
    "country-code": 566,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Niue",
    "alpha-2": "NU",
    "alpha-3": "NIU",
    "country-code": 570,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Norfolk Island",
    "alpha-2": "NF",
    "alpha-3": "NFK",
    "country-code": 574,
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "North Korea",
    "alpha-2": "KP",
    "alpha-3": "PRK",
    "country-code": 408,
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Northern Mariana Islands",
    "alpha-2": "MP",
    "alpha-3": "MNP",
    "country-code": 580,
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Norway",
    "alpha-2": "NO",
    "alpha-3": "NOR",
    "country-code": 578,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Oman",
    "alpha-2": "OM",
    "alpha-3": "OMN",
    "country-code": 512,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Pakistan",
    "alpha-2": "PK",
    "alpha-3": "PAK",
    "country-code": 586,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Palau",
    "alpha-2": "PW",
    "alpha-3": "PLW",
    "country-code": 585,
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Palestine, State of",
    "alpha-2": "PS",
    "alpha-3": "PSE",
    "country-code": 275,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Panama",
    "alpha-2": "PA",
    "alpha-3": "PAN",
    "country-code": 591,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Papua New Guinea",
    "alpha-2": "PG",
    "alpha-3": "PNG",
    "country-code": 598,
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Paraguay",
    "alpha-2": "PY",
    "alpha-3": "PRY",
    "country-code": 600,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Peru",
    "alpha-2": "PE",
    "alpha-3": "PER",
    "country-code": 604,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Philippines",
    "alpha-2": "PH",
    "alpha-3": "PHL",
    "country-code": 608,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Pitcairn",
    "alpha-2": "PN",
    "alpha-3": "PCN",
    "country-code": 612,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Poland",
    "alpha-2": "PL",
    "alpha-3": "POL",
    "country-code": 616,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Portugal",
    "alpha-2": "PT",
    "alpha-3": "PRT",
    "country-code": 620,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Puerto Rico",
    "alpha-2": "PR",
    "alpha-3": "PRI",
    "country-code": 630,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Qatar",
    "alpha-2": "QA",
    "alpha-3": "QAT",
    "country-code": 634,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Réunion",
    "alpha-2": "RE",
    "alpha-3": "REU",
    "country-code": 638,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Romania",
    "alpha-2": "RO",
    "alpha-3": "ROU",
    "country-code": 642,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Russia",
    "alpha-2": "RU",
    "alpha-3": "RUS",
    "country-code": 643,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Rwanda",
    "alpha-2": "RW",
    "alpha-3": "RWA",
    "country-code": 646,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Saint Barthélemy",
    "alpha-2": "BL",
    "alpha-3": "BLM",
    "country-code": 652,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    "alpha-2": "SH",
    "alpha-3": "SHN",
    "country-code": 654,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Saint Kitts and Nevis",
    "alpha-2": "KN",
    "alpha-3": "KNA",
    "country-code": 659,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Saint Lucia",
    "alpha-2": "LC",
    "alpha-3": "LCA",
    "country-code": 662,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Saint Martin (French part)",
    "alpha-2": "MF",
    "alpha-3": "MAF",
    "country-code": 663,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Saint Pierre and Miquelon",
    "alpha-2": "PM",
    "alpha-3": "SPM",
    "country-code": 666,
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "hipgnosis-region-code": "",
    domestic: 0,
  },
  {
    country: "Saint Vincent and the Grenadines",
    "alpha-2": "VC",
    "alpha-3": "VCT",
    "country-code": 670,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Samoa",
    "alpha-2": "WS",
    "alpha-3": "WSM",
    "country-code": 882,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "San Marino",
    "alpha-2": "SM",
    "alpha-3": "SMR",
    "country-code": 674,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Sao Tome and Principe",
    "alpha-2": "ST",
    "alpha-3": "STP",
    "country-code": 678,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Saudi Arabia",
    "alpha-2": "SA",
    "alpha-3": "SAU",
    "country-code": 682,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Senegal",
    "alpha-2": "SN",
    "alpha-3": "SEN",
    "country-code": 686,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Serbia",
    "alpha-2": "RS",
    "alpha-3": "SRB",
    "country-code": 688,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Seychelles",
    "alpha-2": "SC",
    "alpha-3": "SYC",
    "country-code": 690,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Sierra Leone",
    "alpha-2": "SL",
    "alpha-3": "SLE",
    "country-code": 694,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Singapore",
    "alpha-2": "SG",
    "alpha-3": "SGP",
    "country-code": 702,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Sint Maarten (Dutch part)",
    "alpha-2": "SX",
    "alpha-3": "SXM",
    "country-code": 534,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Slovakia",
    "alpha-2": "SK",
    "alpha-3": "SVK",
    "country-code": 703,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Slovenia",
    "alpha-2": "SI",
    "alpha-3": "SVN",
    "country-code": 705,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Solomon Islands",
    "alpha-2": "SB",
    "alpha-3": "SLB",
    "country-code": 90,
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Somalia",
    "alpha-2": "SO",
    "alpha-3": "SOM",
    "country-code": 706,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "South Africa",
    "alpha-2": "ZA",
    "alpha-3": "ZAF",
    "country-code": 710,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    "alpha-2": "GS",
    "alpha-3": "SGS",
    "country-code": 239,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "South Sudan",
    "alpha-2": "SS",
    "alpha-3": "SSD",
    "country-code": 728,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Spain",
    "alpha-2": "ES",
    "alpha-3": "ESP",
    "country-code": 724,
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Sri Lanka",
    "alpha-2": "LK",
    "alpha-3": "LKA",
    "country-code": 144,
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Sudan",
    "alpha-2": "SD",
    "alpha-3": "SDN",
    "country-code": 729,
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Suriname",
    "alpha-2": "SR",
    "alpha-3": "SUR",
    "country-code": 740,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Svalbard and Jan Mayen",
    "alpha-2": "SJ",
    "alpha-3": "SJM",
    "country-code": 744,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Sweden",
    "alpha-2": "SE",
    "alpha-3": "SWE",
    "country-code": 752,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Switzerland",
    "alpha-2": "CH",
    "alpha-3": "CHE",
    "country-code": 756,
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Syria",
    "alpha-2": "SY",
    "alpha-3": "SYR",
    "country-code": 760,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Taiwan",
    "alpha-2": "TW",
    "alpha-3": "TWN",
    "country-code": 158,
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Tajikistan",
    "alpha-2": "TJ",
    "alpha-3": "TJK",
    "country-code": 762,
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Tanzania",
    "alpha-2": "TZ",
    "alpha-3": "TZA",
    "country-code": 834,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Thailand",
    "alpha-2": "TH",
    "alpha-3": "THA",
    "country-code": 764,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Timor-Leste",
    "alpha-2": "TL",
    "alpha-3": "TLS",
    "country-code": 626,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Togo",
    "alpha-2": "TG",
    "alpha-3": "TGO",
    "country-code": 768,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Tokelau",
    "alpha-2": "TK",
    "alpha-3": "TKL",
    "country-code": 772,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Tonga",
    "alpha-2": "TO",
    "alpha-3": "TON",
    "country-code": 776,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Trinidad and Tobago",
    "alpha-2": "TT",
    "alpha-3": "TTO",
    "country-code": 780,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Tunisia",
    "alpha-2": "TN",
    "alpha-3": "TUN",
    "country-code": 788,
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Turkey",
    "alpha-2": "TR",
    "alpha-3": "TUR",
    "country-code": 792,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Turkmenistan",
    "alpha-2": "TM",
    "alpha-3": "TKM",
    "country-code": 795,
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Turks and Caicos Islands",
    "alpha-2": "TC",
    "alpha-3": "TCA",
    "country-code": 796,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Tuvalu",
    "alpha-2": "TV",
    "alpha-3": "TUV",
    "country-code": 798,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Uganda",
    "alpha-2": "UG",
    "alpha-3": "UGA",
    "country-code": 800,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Ukraine",
    "alpha-2": "UA",
    "alpha-3": "UKR",
    "country-code": 804,
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "United Arab Emirates",
    "alpha-2": "AE",
    "alpha-3": "ARE",
    "country-code": 784,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "United Kingdom",
    "alpha-2": "GB",
    "alpha-3": "GBR",
    "country-code": 826,
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "United States",
    "alpha-2": "US",
    "alpha-3": "USA",
    "country-code": 840,
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "hipgnosis-region-code": "USA",
    domestic: 1,
  },
  {
    country: "United States Minor Outlying Islands",
    "alpha-2": "UM",
    "alpha-3": "UMI",
    "country-code": 581,
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Uruguay",
    "alpha-2": "UY",
    "alpha-3": "URY",
    "country-code": 858,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Uzbekistan",
    "alpha-2": "UZ",
    "alpha-3": "UZB",
    "country-code": 860,
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Vanuatu",
    "alpha-2": "VU",
    "alpha-3": "VUT",
    "country-code": 548,
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Venezuela",
    "alpha-2": "VE",
    "alpha-3": "VEN",
    "country-code": 862,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Vietnam",
    "alpha-2": "VN",
    "alpha-3": "VNM",
    "country-code": 704,
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Virgin Islands (British)",
    "alpha-2": "VG",
    "alpha-3": "VGB",
    "country-code": 92,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Virgin Islands (U.S.)",
    "alpha-2": "VI",
    "alpha-3": "VIR",
    "country-code": 850,
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "hipgnosis-region-code": "PEER",
    domestic: 0,
  },
  {
    country: "Wallis and Futuna",
    "alpha-2": "WF",
    "alpha-3": "WLF",
    "country-code": 876,
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "hipgnosis-region-code": "OCEANIA",
    domestic: 0,
  },
  {
    country: "Western Sahara",
    "alpha-2": "EH",
    "alpha-3": "ESH",
    "country-code": 732,
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Yemen",
    "alpha-2": "YE",
    "alpha-3": "YEM",
    "country-code": 887,
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Zambia",
    "alpha-2": "ZM",
    "alpha-3": "ZMB",
    "country-code": 894,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
  {
    country: "Zimbabwe",
    "alpha-2": "ZW",
    "alpha-3": "ZWE",
    "country-code": 716,
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "hipgnosis-region-code": "SACEM",
    domestic: 0,
  },
];
