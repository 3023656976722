import { useSelector, useDispatch } from "react-redux"
// -----------------------------------------------------
import "./css.css";


// -----------------------------------------------------
// -----------------------------------------------------
const Index = () => {
  
  const dispatch = useDispatch();
  const songbridge = useSelector((state) => state.songbridge);
  const { rawSong, performer } = songbridge;

  if(!rawSong) return null;

  return (
    <>
      <button
        className="button is-small"
        onClick={() => {
          dispatch({ type: "SHOW_HIDE_SONG_NORM", show: true });
        }}
      >
        <span className="icon is-small">
          <i className="fa-duotone fa-spell-check" />
        </span>
        <span>Song Norm</span>
      </button>
      {performer ? (
        <button
          className="button is-small"
          onClick={() => {
            dispatch({ type: "SHOW_HIDE_SONG_DETAILS", show: true });
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-list-ol" />
          </span>
          <span>Song Details</span>
        </button>
      ) : null}
    </>
  );
}

export default Index;