import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
// -------------------------------------------------------------
import running from "Media/running.gif";
import "./css.css";


// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {

  const files = useSelector( state => state.files );
  if(!files.xlsx2csvProgress) return null;

  const { headline, pointer, total, sheets, status } = files.xlsx2csvProgress;

  return(
    <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card wide-screen" >
      <header className="modal-card-head convert">
        <div className = "header">
        <p className="modal-card-title">
          XLSX &rarr; CSV
        </p>
        <progress 
          className = "convert progress is-primary" 
          value = {pointer} max = { total } 
        />
        </div>
      </header>
      <section className="modal-card-body">
        <div className = "box converter-box">
          <div className = "status-headline">
            <img src = {running} />
            &nbsp;{ headline } 
            &nbsp;|&nbsp; {pointer} (<b>{total}</b>)
          </div>
          <i>{ status }</i>
          <div className = "display-sheets">
            {
              sheets.map(
                (sheet, sheetindex) => {
                  return(
                    <div className = "tag sheet" key = {`sheet-${sheetindex}`}>
                      { sheet }
                    </div>
                  )
                }
              )
            }
          </div>
        </div>
      </section>
      <footer className="modal-card-foot">
      </footer>
    </div>
  </div>
  )
}

export default Index;