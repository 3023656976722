const initialState = {
  table: '',
  dbHeaders: [],
  collection: null,
  dbProgress: null,
  data: null
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = ( state = initialState, action ) => {

  // -------------------------------------------------------
  switch(action.type) {

    // -------------------------------------------------------
    case 'SET_DB_DATATYPE': 
      return {
        ...state,
        dbHeaders: state.dbHeaders.map(
          (x, index) => {
            return( 
              index===action.headerindex ? {
                ...x, datatype: action.datatype
              } : x
            )
          }
        )
      }
    // -------------------------------------------------------
    case 'SET_DB_MAPPING': 
      return {
        ...state,
        dbHeaders: state.dbHeaders.map(
          (x, index) => {
            return( 
              index===action.headerindex ? {
                ...x, mapping: action.mapping
              } : (
                x.mapping === action.mapping ? {
                  ...x, mapping: null
                } : x
              )
            )
          }
        )
      }
    // -------------------------------------------------------
    case 'SET_SECONDARY':
      if(action.headerindex===null) return { ...state, secondary: null }
      return {
        ...state,
        secondary: state.dbHeaders[action.headerindex].header
      }
    // -------------------------------------------------------
    case 'SET_SECONDARY_TITLE':
      if(action.headerindex===null) return { ...state, secondaryTitle: null }
      return {
        ...state,
        secondaryTitle: state.dbHeaders[action.headerindex].header
      }
    // -------------------------------------------------------
    case 'SET_DB_QUERY_RESULTS': 
      return { ...state, data: action.data }

    // -------------------------------------------------------
    case 'CLEAR_DB': 
    return { ...state, table: '', data: null, secondary: null }
    
    // -------------------------------------------------------
    case 'SET_DB_FILES': 
      return {
        ...state,
        files: action.files,
        dbProgress: null,
        table: ''
      }

    // -------------------------------------------------------
    case 'INIT_DB': 
      return {
        ...state,
        dbHeaders: action.dbHeaders, 
        table: action.table, data: null, 
        sample: action.sample
      }
    // -------------------------------------------------------
    case 'SET_DB_PROGRESS':
      if(action.pointer === null) {
        return { 
          ...state, 
          dbProgress: null 
        }
      }
      return { 
        ...state,
        dbProgress: {
          ...state.dbProgress,
          headline: action.headline,
          pointer: action.pointer,
          status: action.status,
          total: action.total,
        }
      }
    // -------------------------------------------------------
    case "SET_DB_PROGRESS_MAIN":
      return {
        ...state,
        dbProgress: {
          ...state.dbProgress,
          main: action.main
        }
      }
    // -------------------------------------------------------
    default:
      return state;
  }
}

export default reducer;