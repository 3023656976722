import { backend } from "System/system";

export const bridge = (catalog, song, index) => async (dispatch) => {
  const result = await backend("songbridge", "bridge", {
    catalog,
    song,
  });

  dispatch({
    type: "ADD_SONGBRIDGE_ENTRY",
    entry: { song, ...result.data },
    index,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const queryDiscogs = (rawSong, entity, cb) => async (dispatch) => {
  let result;
  result = await backend("songbridge", "findEntry", {
    entity,
    rawSong,
  });
  const rawSongs = [rawSong.split("-")[0], ...rawSong.split(" ")];

  let cnt = 0;

  do {
    const query = `${rawSongs[cnt++]} ${entity}`.toUpperCase();
    console.log("Query is", query);
    result = await backend("songbridge", "findEntry", {
      entity,
      query,
      rawSong: rawSong.split("-")[0],
    });
  } while (!result.data && cnt < rawSongs.length);
  if (result.data) {
    if (result.data.performer) {
      result.data.performer = capitalize(result.data.performer
        .toUpperCase()
        .split("FEAT")[0]);
    }
  }
  cb(result.data);
};


// -------------------------------------------------------
// -------------------------------------------------------
export const querySongDetailsGenius =
  (song, performer, cb) => async (dispatch) => {
    const result = await backend("songbridge", "lookUpGenius", {
      song,
      performer,
    });
    cb(result);
  };

// ----------------------------------------------
// ----------------------------------------------
const capitalize = (string) => {
  const capitalized = string
    .toLowerCase()
    .split(" ")
    .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1))
    .join(" ");
  console.log("Capitalized: " + capitalized);
  return capitalized;
}

// -------------------------------------------------------
// -------------------------------------------------------
export const querySongIDs = (database, cb) => async (dispatch) => {
  const result = await backend("songbridge", "querySongIDs", {
    database,
  });
  const data = result.data;
  if(!data) { cb({}); return; }  
  cb(data.data)
};

// -------------------------------------------------------
// -------------------------------------------------------
export const querySongDetailsSpotify =
  (song, performer, cb) => async (dispatch) => {
    const result = await backend("songbridge", "lookUpSpotify", {
      song,
      performer,
    });
    if (!result.data) {
      cb();
      return;
    }
    cb(result.data);
  };

  // -------------------------------------------------------
// -------------------------------------------------------
export const queryAndUpdateSongDetailsSpotify =
  (song, performer, database, cb) => async (dispatch) => {
    const result = await backend("songbridge", "lookUpSpotify", {
      song,
      performer,
    });
    if(result.data) {
      console.log(result.data.item)
    }
    const spotifyID = result.data ? result.data.spotifyID : null;

    const resultGenius = await backend("songbridge", "lookUpGenius", {
      song,
      performer,
    });
    const geniusID = resultGenius.data ? resultGenius.data.geniusID : null;

    await backend("songbridge", "updateSongIDs", {
      database,
      song,
      performer,
      spotifyID,
      geniusID
    });
    cb({ spotifyID, geniusID });
  };