import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// -------------------------------------------------------------

// -------------------------------------------------------------
// -------------------------------------------------------------
import { shutHeaders } from 'Redux/files/actions';
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ shutHeaders }, dispatch);
};
const mapStateToProps = (state) => ({ files: state.files });


const clean = str => {
  if(typeof(str)!=='string') return str;
  if(str===null) return null;
  return str.replace(/ /g, "\u00A0");
}

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({ files, shutHeaders  }) => {
  
  const { headers } = files;
  if(!headers) return null;

  return(
    <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card" style = {{ width: '100%', padding: 35 }}>
      <header className = "modal-card-head">
        <p className = "modal-card-title"></p>
        <button 
          className="delete" 
          aria-label="close"
          onClick = { shutHeaders }
        />
      </header>
      <section 
        className="modal-card-body"
      >
        <Headers headers = { headers } />
      </section>
      <footer className="modal-card-foot">
        <button 
          className = "button is-info"
          onClick = { shutHeaders }
        >
          Done
        </button>
      </footer>
    </div>
  </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);


const Headers = ({ headers }) => {
  
  if(!headers) return null;

  return(
    <div className = "table-container small-table">
    <table className = "table is-fullwidth is-striped is-hoverable is-bordered is-small">
      <thead>
      </thead>
      <tbody>
        {
          headers.map(
            (line, lineindex) => {
              return(
                <tr key = {`headers-${lineindex}`}>
                  {
                    line.map(
                      (entry, entrindex) => {
                        return(
                          <td>
                            { 
                              //entry.replace(/"/g, "") 
                              clean(entry)
                            }
                          </td>
                        )
                      }
                    )
                  }
                </tr>
              )
            }
          )
        }
      </tbody>
    </table>
    </div>
  )
}
