import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from 'react-number-format';
// -------------------------------------------------------------
import {
  getSummary,
  getSubCatalogDetails,
  getSummaryTopView,
} from "Redux/summary/actions";
// -------------------------------------------------------------
import running from "Media/running.gif";
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [making, setMaking] = useState(false);
  const [gettingDetails, setGettingDetails] = useState(false);

  const summary = useSelector((state) => state.summary);
  const projects = useSelector((state) => state.projects);
  const {
    project,
    project: { outputCurrency },
  } = projects;

  const generateSummary = (nominal) => {
    setMaking(true);
    dispatch(
      getSummary(project, async (catalogsData) => {
        setMaking(false);
        for (var ii = 0; ii < catalogsData.length; ii++) {
          const entry = catalogsData[ii];
          await dispatch(
            getSubCatalogDetails(
              project,
              ii,
              entry.Third_Party_9LC,
              entry.Contract_ID_9LC
            )
          );
        }
      })
    );
  };

  const getDetailedSummary = () => {
    setGettingDetails(true);
    dispatch(getSummaryTopView(project))
  }


  if (!summary.summary) {
    return (
      <div className="buttons">
        <button
          className={`button is-primary ${making ? "is-loading" : ""}`}
          onClick={() => {
            generateSummary();
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-cogs" />
          </span>
          <span>Project Summary</span>
        </button>
      </div>
    );
  }

  const { currencies, catalogs } = summary.summary;
  console.log(currencies);
  return (
    <div>
      <div className="title">Project Summary</div>
      <div className="box currency-summary">
        <div>
          Currencies:
          {currencies.map((item) => (
            <div
              key={item.Payout_Currency_9LC}
              className="tag is-primary is-large is-light currency"
            >
              {item.Payout_Currency_9LC}
            </div>
          ))}
        </div>
        <div className="output-currency-summary">
          Output Currency:{" "}
          <div className="tag is-primary is-large is-light currency">
            {outputCurrency}
          </div>
        </div>
      </div>
      <div className="box">
        Sub-Catalogs:
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Party/Contract</th>
                <th>Line Count</th>
                <th>Currency</th>
                <th>Nominal</th>
                <th>Adjusted($)</th>
                <th>Adjusted({outputCurrency})</th>
              </tr>
            </thead>
            <tbody>
              {catalogs.map((item) => {
                const subCat = item.Contract_ID_9LC
                  ? `${item.Third_Party_9LC}/${item.Contract_ID_9LC}`
                  : item.Third_Party_9LC;
                return (
                  <tr key={subCat}>
                    <td>
                      <div className="tag is-info is-light sub-catalog">
                        {subCat}
                      </div>
                    </td>
                    <td>
                      {isFinite(item.lines) ? (
                        item.lines
                      ) : (
                        <img src={running} />
                      )}
                    </td>
                    <td>
                      {item.currency ? (
                        <div className="tag is-primary is-light">
                          {item.currency}
                        </div>
                      ) : (
                        <img src={running} />
                      )}
                    </td>
                    <td className="table-number">
                      <CheckEntry entry={item.nominal} />
                    </td>
                    <td className="table-number">
                      <CheckEntry entry={item.usd} />
                    </td>
                    <td className="table-number">
                      <CheckEntry entry={item.adjusted} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
                {
            gettingDetails ? (
          <div className="box">
            Detailed Summary
            {summary.topView ? (
              <div className="table-container">
                <table className="table is-striped is-hoverable is-small">
                  <thead>
                    <tr>
                      <th>Third Party</th>
                      <th>Contract</th>
                      <th>Period</th>
                      <th>Currency</th>
                      <th>Line Count</th>
                      <th>Nominal</th>
                      <th>Adjusted($)</th>
                      <th>Adjusted({outputCurrency})</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summary.topView.map((item, itemindex) => (
                      <tr key={`sum-${itemindex}`}>
                        <td>
                          <div className="tag is-info is-light">
                            {item.Third_Party_9LC}
                          </div>
                        </td>
                        <td>
                          <div className="tag is-info is-light">
                            {item.Contract_ID_9LC}
                          </div>
                        </td>
                        <td>{item.Statement_Period_9LC}</td>
                        <td>
                          <div className="tag is-primary is-light">
                            {item.Payout_Currency_9LC}
                          </div>
                        </td>
                        <td>{item["Line Count"]}</td>
                        <td className = "table-number"><Amount amount = {item.Nominal} /></td>
                        <td className = "table-number"><Amount amount = {item.USD} /></td>
                        <td className = "table-number"><Amount amount = {item.Adjusted} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <img src={running} />
              </div>
            )}
                      </div>  

            ) : (
              <div className = "box">
              <button className = "button"
                onClick = { getDetailedSummary }
              >Calculate Detailed Summary</button>
              </div>
            )
          }
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const CheckEntry = ({ entry }) => {
  return isFinite(entry) ? (
    <Amount amount = { entry } />
  ) : (
    <img src={running} />
  );
};

const Amount = ({amount}) => {
  return(
    <NumberFormat value={amount} displayType = "text" decimalScale = {2} thousandSeparator={true} />
  )
}