import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import "Styles/handsontable.full.css";
// ------------------------------------------------------
import ProgressBar from "Components/ProgressBar";
// ------------------------------------------------------
import { initCanonicals } from "Redux/database/actions";
import { runParsers } from "Redux/parser/actions";
// ------------------------------------------------------
import "./css.css";
import waitbar from "Media/waitbar.gif";
// ------------------------------------------------------

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const nav = useSelector((state) => state.nav);

  const [newParser, setNewParser] = useState(false);
  const [pick, setPick] = useState(null);

  const { project } = projects;
  const { collectionsRepo, flatFilesReverse } = project;
  const { collectionid } = nav;

  useEffect(() => {
    if (collectionid && !sql) {
      const files = collection.fileids.map(
        (fileid) => flatFilesReverse[fileid]
      );
      dispatch(initCanonicals(files, collectionid));
    }
  }, []);

  if (!collectionid) {
    return null;
  }

  const collection = collectionsRepo[collectionid];
  const { sql } = collection;
  if (!sql) {
    return (
      <div className="box">
        <img src={waitbar} />
        <div>Gathering Headers</div>
      </div>
    );
  }

  const parsers = collection.hasOwnProperty("parsers")
    ? collection.parsers
    : [];

  const files = collection.fileids.map((fileid) => flatFilesReverse[fileid]);

  console.log(files);

  const onDropCustom = (evt, parserindex, stepindex) => {
    const column = evt.dataTransfer.getData("canonical");
    const parser = parsers[parserindex];
    console.log(column, parser);

    if (
      parser.datatype === "key-value" &&
      parser.steps[0].fields.length !== 0
    ) {
      console.log("Returning Null");
      return null;
    }

    dispatch({
      type: "ADD_FIELD_TO_PARSER_STEP",
      collectionid,
      parserindex,
      stepindex,
      column,
    });
  };

  const onRemoveCustomItem = (parserindex, stepindex, itemindex) => {
    dispatch({
      type: "REMOVE_FIELD_FROM_PARSER_STEP",
      collectionid,
      parserindex,
      stepindex,
      itemindex,
    });
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const addStep = (parserindex, steptype) => {
    dispatch({
      type: "ADD_PARSER_STEP",
      collectionid,
      parserindex,
      steptype,
    });
  };

  return (
    <div className="box">
      <ProgressBar />
      {newParser ? (
        <NewParser
          onClose={() => {
            setNewParser(false);
          }}
          collectionid={collectionid}
        />
      ) : null}
      <div className="columns">
        <div className="column">
          <div className="buttons">
            <button
              className="button"
              onClick={() => {
                dispatch(runParsers(files, parsers));
              }}
            >
              <span className="icon is-small">
                <i className="fa fa-cogs" />
              </span>
              <span>Apply Parsers</span>
            </button>
            <button className="button" onClick={() => setNewParser(true)}>
              <span className="icon is-small">
                <i className="fa fa-plus" />
              </span>
              <span>New Parser</span>
            </button>
          </div>
          <div className="table-container">
            <div className="table is-striped is-narrow is-bordered">
              {sql.dbHeaders.map((header, headerindex) => (
                <Header
                  key={`header-${header.header}`}
                  header={header}
                  collectionid={collectionid}
                />
              ))}
            </div>
          </div>
        </div>
        {parsers.map((parser, parserindex) => {
          return (
            <div className="column" key={`parsers-${parserindex}`}>
              <div style={{ position: "fixed" }}>
                <div className="tag is-warning is-light">{parser.datatype}</div>
                {
                  parser.mappingHeadline ? (
                    <div className="tag is-info is-light">{parser.mappingHeadline}</div>
                  ) : null
                }

                <div className="headline-wrapper">
                  <div className="tag is-large">{parser.headline}</div>
                  <button
                    className="delete"
                    onClick={() => {
                      dispatch({
                        type: "REMOVE_PARSER",
                        collectionid,
                        parserindex,
                      });
                    }}
                  />
                </div>
                {parser.datatype === "str" ? (
                  <div className="buttons has-addons">
                    <button
                      className="button is-small"
                      onClick={() => addStep(parserindex, "concat")}
                    >
                      <span>concat</span>
                    </button>
                    <button
                      className="button is-small"
                      onClick={() => addStep(parserindex, "cascade")}
                    >
                      <span>cascade</span>
                    </button>
                  </div>
                ) : parser.datatype === "float" ? (
                  <div className="buttons has-addons">
                    <button
                      className="button is-small"
                      onClick={() => addStep(parserindex, "def")}
                    >
                      <span>def</span>
                    </button>
                    <button
                      className="button is-small"
                      onClick={() => addStep(parserindex, "sum")}
                    >
                      <span>sum</span>
                    </button>
                    <button
                      className="button is-small"
                      onClick={() => addStep(parserindex, "mul")}
                    >
                      <span>mul</span>
                    </button>
                  </div>
                ) : null}

                {parser.steps.map((step, stepindex) => (
                  <div
                    key={`parse-${stepindex}`}
                    className="parser-item"
                    onDrop={(evt) => {
                      onDropCustom(evt, parserindex, stepindex);
                    }}
                    onDragOver={onDragOver}
                  >
                    <div className="parser-wrapper">
                      <div>
                        <div className="tags has-addons">
                          <div className="tag is-small is-warning is-light">
                            {parser.datatype}
                          </div>
                          <div className="tag is-danger is-light">
                            {step.type}
                          </div>
                          <a
                            class="tag is-delete"
                            onClick={() => {
                              dispatch({
                                type: "REMOVE_PARSER_STEP",
                                collectionid,
                                parserindex,
                                stepindex,
                              });
                            }}
                          ></a>
                        </div>
                      </div>
                    </div>
                    <table className="table is-fullwidth is-striped">
                      <tbody>
                        {step.fields.map((item, itemindex) => (
                          <tr key={`parser-item-${stepindex}-${itemindex}`}>
                            <td>{itemindex + 1}</td>
                            <td>{item}</td>
                            <td>
                              <div
                                onClick={() => {
                                  onRemoveCustomItem(
                                    parserindex,
                                    stepindex,
                                    itemindex
                                  );
                                }}
                                className="delete"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Index;

// -----------------------------------------------------
// -----------------------------------------------------
const Header = ({ header, collectionid }) => {
  const dispatch = useDispatch();
  const onDragStart = (e) => {
    e.dataTransfer.setData("canonical", header.header);
    console.log("Dragging it");
  };
  return (
    <tr className="date-header">
      <td>
        <div
          className="tag is-info is-light"
          draggable
          onDragStart={onDragStart}
        >
          {header.header.replace(/ /, "\u00A0")}
        </div>
      </td>
      <td>
        <div className="header-sample">
          <ul>
            {header.sample
              ? header.sample.map((sample, sampleid) => (
                  <li key={`sample-${sampleid}`}>
                    {sample && typeof sample === "string"
                      ? sample.replace(/ /, "\u00A0")
                      : sample}
                  </li>
                ))
              : null}
          </ul>
        </div>
      </td>
    </tr>
  );
};

// -----------------------------------------------------
// -----------------------------------------------------
const NewParser = ({ onClose, collectionid }) => {
  const [headline, setHeadline] = useState("");
  const [datatype, setDatatype] = useState("str");
  const [mapping, setMapping] = useState(null);
  const [mappingHeadline, setMappingHeadline] = useState(null);
  const [nRows, setNRows] = useState("1");
  const [data, setData] = useState([{ key: "", value: "" }]);
  const hot = useRef(null);
  const { list } = useSelector((state) => state.mapping);

  const dispatch = useDispatch();

  const newParser = () => {
    if (datatype === "key-value" && mapping!==null) {
      const parsedData = {};
      const mappingItem = list.find((item) => item._id === mapping);
      if(mappingItem) {
        dispatch({
          type: "ADD_PARSER",
          collectionid,
          headline,
          datatype,
          mapping, mappingHeadline: mappingItem.mapping,
          steps: [{ type: "map", fields: [] }],
        });
        onClose();
        return;  
      }
    }
    dispatch({ type: "ADD_PARSER", collectionid, headline, datatype });
    onClose();
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head convert">
          <div className="header">
            <p className="modal-card-title">New Parser</p>
            <button className="delete" onClick={onClose} />
          </div>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Data Type</label>
            <div className="control">
              <div className="select">
                <select
                  onChange={(evt) => {
                    setDatatype(evt.target.value);
                  }}
                  value={datatype}
                >
                  <option value="str">String</option>
                  <option value="float">Float</option>
                  <option value="key-value">Key-Value</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">New Field</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Enter Field"
                value={headline}
                onChange={(evt) => {
                  setHeadline(evt.target.value);
                }}
              />
            </div>
          </div>
          {datatype === "key-value" ? (
            <div className="field">
              <label className="label">Key / Value Mapping</label>
              <div className="select">
                <select className="select" onChange={(evt) => {
                  setMapping(evt.target.value)
                }}>
                  <option value={null}>
                    -- Pick Mapping --
                  </option>
                  {list.map((item, itemindex) => {
                    return (
                      <option value={item._id} key={item._id}>
                        {item.mapping}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          ) : null}
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-primary"
            onClick={newParser}
            disabled={!headline}
          >
            Add Parser
          </button>
          <button className="button" onClick={onClose}>
            Discard
          </button>
        </footer>
      </div>
    </div>
  );
};
