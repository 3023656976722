import React, { useState } from "react";
// ------------------------------------------------------
import File from "Components/File";


// ------------------------------------------------------
// ------------------------------------------------------
const Dir = ({ dir }) => {
  
  const [ state, setState ] = useState(false);

  const openCloseFolder = () => {
    setState(!state);
  }

  const onDragStart = (e) => {
    e.dataTransfer.setData("dir", JSON.stringify(dir));
  }


  return(
    <ul className = "file-tree">
      <li 
        className = { `entry` }
        onClick = { openCloseFolder }
        draggable
        onDragStart = { onDragStart }
      >
        {
          state ? 
            <i className = "fa-duotone fa-folder-open dirpic" />:
            <i className = "fa-duotone fa-folder dirpic" />
        }
        {dir.title}
      </li>
      {
        state ? <>
          { 
            dir.dirs.map(
              (dir, dirind) => 
                <Dir
                  key = { `dir-${dirind}-${dir.title}`} 
                  dir = { dir } 
                />
            )
          }
          {
            dir.files.map(
              (file, fileindex) => 
                <File
                  file = { file } 
                  fileindex = {fileindex} 
                  key = { `file-${fileindex}` }
                />
            )
          }
        </> : null
      }
    </ul>
  )
}

export default Dir;