import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
// -------------------------------------------------------------
import running from "Media/running.gif";
import "./css.css";


// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {

  const files = useSelector( state => state.files );
  if(!files.progressPopup) return null;

  const { headline, mainline, pointer, total, status } = files.progressPopup;
  
  return(
    <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card wide-screen" >
      <header className="modal-card-head convert">
        <div className = "header">
        <p className="modal-card-title">
          { headline }
        </p>
        <progress 
          className = "convert progress is-primary" 
          value = {pointer} max = { total } 
        />          
        </div>
      </header>
      <section 
        className="modal-card-body"
      >
        <div className = "box">
          <div className = "status-headline">
            <img src = {running} />
            &nbsp;{ mainline } 
            &nbsp;|&nbsp; {pointer} (<b>{ total }</b>)
          </div>
          { status === null ? null : <i>{ status }</i> }
        </div>
      </section>
      <footer className="modal-card-foot">
      </footer>
    </div>
  </div>
  )
}

export default Index;