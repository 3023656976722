import CSVViewer from "./CSVViewer";
import PDFViewer from "./PDFViewer";
import { useSelector } from 'react-redux';

const FileViewers = () => {

  const files = useSelector( state => state.files );

  return(
    files.viewer ? 
      <>
        { files.viewer.filetype === 'csv' ? <CSVViewer /> : null }
        { files.viewer.filetype === 'pdf' ? <PDFViewer /> : null }
      </> : null
  )
}


export default FileViewers;