import { backend } from "System/system";
import { notify } from "Redux/user/actions";
import { saveProjectAfterTimeout } from "Redux/projects/actions";
import {
  canonicalHeaders,
  updateHeaders,
  collectionCoreHeaders,
  masterHeaders,
} from "Data/updateHeaders";

// -------------------------------------------------------
// -------------------------------------------------------
const dbOn = () => (dispatch) => {
  dispatch({ type: "SET_DB_ACCESS", dbaccess: true });
};
const dbOff = () => (dispatch) => {
  dispatch({ type: "SET_DB_ACCESS", dbaccess: false });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getSummaryTopView = (project) => async (dispatch) => {
  dispatch(dbOn());
  const { database, masterTable } = project;
  const result = await backend("results", "getSummaryTopView", {
    masterTable,
    database,
  });
  dispatch({
    type: "SET_SUMMARY_TOP_VIEW",
    topView: result.data.data
  })

  dispatch(dbOff());
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getSubCatalogDetails = (project, catalogIndex, thirdParty, contractID) => async (dispatch) => {
  dispatch(dbOn());
  const { database, masterTable } = project;
  const result = await backend("results", "getSubCatalogDetails", {
    masterTable,
    database,
    thirdParty, contractID
  });
  const { USD, Nominal, Adjusted, Lines, Currency }  = result.data.data[0];
  console.log("Details are", thirdParty, contractID, Nominal, USD, Lines, Currency);
  dispatch({
    type: "UPDATE_SUMMARY_DETAILS",
    catalogIndex, Nominal, USD, Adjusted, Lines, Currency
  })
  dispatch(dbOff());
};


// -------------------------------------------------------
// -------------------------------------------------------
export const getSummary = (project, cb) => async (dispatch) => {
  dispatch(dbOn());
  const { database, masterTable } = project;
  const resultCatalogs = await backend("results", "getSubCatalogs", {
    masterTable,
    database,
  });
  const resultCurrencies = await backend("results", "getMasterCurrencies", {
    masterTable,
    database,
  });

  const summary = {
    catalogs: resultCatalogs.data.data,
    currencies: resultCurrencies.data.data,
  };

  dispatch(dbOff());
  dispatch({
    type: "SET_SUMMARY",
    summary
  });

  cb(resultCatalogs.data.data)
};
