import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import MultipleHeaders from "Components/MultipleHeaders/";
import ProgressBar from "Components/ProgressBar/";
import SumAttribute from "../SumAttribute";
// -------------------------------------------------------------
import { pickSumAttribute } from "Redux/collections/actions";
import {
  getHeaders,
  countAllCSVLines,
  cleanFile,
  dispCSV,
  dispPDF,
} from "Redux/files/actions";
import { hipgnosify } from "Redux/conversions/actions";
import {
  getSignature,
  setSignature,
  applySignature,
} from "Redux/signature/actions";
// -------------------------------------------------------------
import { explicitSize } from "Aux/";
import "./css.css";
import { getSize } from "react-spreadsheet/dist/matrix";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const [parser, setParser] = useState(null);
  const [hasSignature, setHasSignature] = useState(null);

  const projects = useSelector((state) => state.projects);
  const {
    project: { collectionsRepo, flatFilesReverse },
  } = projects;
  const nav = useSelector((state) => state.nav);
  const { collectionid } = nav;

  useEffect(() => {
    if (collectionid) {
      const collection = collectionsRepo[collectionid];
      const files = collection.fileids.map(
        (fileid) => flatFilesReverse[fileid]
      );
      dispatch(
        getSignature(collection, files, (data) => {
          setHasSignature(data);
        })
      );
    }
  }, [collectionid]);

  if (!collectionid) {
    return null;
  }
  const collection = collectionsRepo[collectionid];

  const attributes = [
    { attribute: "nlines", title: "Line Count" },
    { attribute: "sum", title: "Sum" },
  ];

  const iClasses = {
    PDF: "fa-duotone fa-file-pdf filepic pdf",
    CSV: "fa-duotone fa-file-alt filepic csv",
    XLS: "fa-duotone fa-file-excel filepic xls",
    XLSX: "fa-duotone fa-file-excel filepic xls",
    ZIP: "fa-duotone fa-file-archive filepic zip",
  };

  const files = collection.fileids.map((fileid) => flatFilesReverse[fileid]);

  const clickedFile = (file) => {
    switch (file.ext) {
      case "CSV":
        dispatch(dispCSV(file));
        break;
      case "PDF":
        dispatch(dispPDF(file));
        break;
      default:
    }
  };

  const doHipgnosis = async (e) => {
    dispatch({
      type: "INIT_MAIN_PROGRESS",
      title: "Parsing file data",
      total: files.length,
    });
    e.stopPropagation();
    for (var ii = 0; ii < files.length; ii++) {
      dispatch({
        type: "UPDATE_MAIN_PROGRESS",
        current: ii,
        status: files[ii].name,
      });
      await dispatch(hipgnosify(files[ii], parser));
    }
    dispatch({
      type: "CLOSE_MAIN_PROGRESS",
    });
  };

  const cleanFiles = async () => {
    dispatch({
      type: "INIT_MAIN_PROGRESS",
      title: "Cleaning special characters",
      total: files.length,
    });
    for (var ii = 0; ii < files.length; ii++) {
      dispatch({
        type: "UPDATE_MAIN_PROGRESS",
        current: ii,
        status: files[ii].name,
      });
      await dispatch(cleanFile(files[ii]));
    }
    dispatch({
      type: "CLOSE_MAIN_PROGRESS",
    });
  };

  console.log("Collection is", collection);
  return (
    <div className="components-files">
      <ProgressBar />
      <MultipleHeaders />
      <SumAttribute files={files} />
      {hasSignature ? (
        <div style={{ margin: "5px 0" }}>
          <button
            className="button is-small is-primary is-light"
            onClick={(e) => {
              dispatch(applySignature(collection.id, hasSignature));
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-signature" />
            </span>
            <span>Apply Signature</span>
          </button>
        </div>
      ) : null}
      <div className="field has-addons">
        <p className="control">
          <button
            className="button is-small box-button"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(pickSumAttribute(files));
            }}
          >
            <span className="icon is-small">&Sigma;</span>
            <span>Sum</span>
          </button>
        </p>

        <p className="control">
          <button
            className="button is-small box-button"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(countAllCSVLines(files));
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-sort-amount-down" />
            </span>
            <span>Line Count</span>
          </button>
        </p>

        <p className="control">
          <button
            className="button is-small box-button"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(getHeaders(files));
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-heading" />
            </span>
            <span>View Headers</span>
          </button>
        </p>
        <p className="control">
          <button
            className="button is-small box-button"
            onClick={(evt) => {
              evt.stopPropagation();
              cleanFiles();
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-broom" />
            </span>
            <span>Clean Special Chars</span>
          </button>
        </p>        
      </div>
      <div className="field has-addons">
        <p className="control">
          <select
            value={parser}
            className="select pick in-group"
            onChange={(evt) => {
              setParser(evt.target.value);
            }}
          >
            <option value="">- Pick Parser -</option>
            <option value="satv">SONY ATV</option>
            <option value="umpg">UMPG</option>
            <option value="bmg">BMG</option>
            <option value="wch">Warner-Chappell</option>
            <option value="socan">SOCAN</option>
            <option value="ascap-domcon">ASCAP Domestic Concord</option>
            <option value="warner-music-group-gfr">
              Warner Music Group GFR
            </option>

            <option value="double-header">Double Header</option>
            <option value="semicolon">CSV Semicolon</option>
            <option value="universalrs">Universal R.S.</option>
            <option value="sony">SONY</option>
            <option value="prsdrtest">PRS D.R. Test</option>
            <option value="bmi-bonus">BMI Bonus</option>
            <option value="wm-gr">Warner Music - Gross Royalty</option>

            <option value="universal-con-song">
              Universal Concatenated Song
            </option>
            <option value="universal-con-source">
              Universal Concatenated Source
            </option>
            <option value="dirty-con-source">
              Dirty Hit Concatenated Source
            </option>
            <option value="domino-con-income">
              Domino Concatenated Income
            </option>
          </select>
        </p>
        <p className="control">
          {parser ? (
            <button
              className="button is-small box-button"
              onClick={doHipgnosis}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-hippo" />
              </span>
              <span>Custom Parse</span>
            </button>
          ) : null}
        </p>
      </div>
      <table className="table is-striped is-hoverable small-table is-bordered collection">
        <thead>
          <tr>
            <th>Type</th>
            <th>File</th>
            <th>Size</th>
            <th>Ref</th>
            <th>Cut</th>

            {attributes.map((attribute) => (
              <th key={attribute.attribute}>{attribute.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {files.map((file, fileindex) => {
            const eS = explicitSize(file.size, 10);
            return (
              <tr
                key={`${fileindex}-${file.name}`}
                onClick={() => {
                  clickedFile(file);
                }}
                className="file-row"
              >
                <td>
                  <i className={iClasses[file.ext]} />
                </td>
                <td>{file.name}</td>
                <td>
                  <div className="file-size">
                    {eS.size}&nbsp;{eS.unit}
                  </div>
                </td>
                <td>
                  {file.ref ? (
                    <div className="tag is-light is-primary">{file.ref}</div>
                  ) : null}
                </td>
                <td>{file.cut}</td>

                {attributes.map((attribute) => {
                  return (
                    <td
                      className="attribute-col"
                      key={`${fileindex}-${attribute.attribute}`}
                    >
                      {file.attributes[attribute.attribute]
                        ? file.attributes[
                            attribute.attribute
                          ].value.toLocaleString()
                        : null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default Index;
