import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { hipgnosify } from "Redux/conversions/actions";
// -------------------------------------------------------------
import ProgressBar from "Components/ProgressBar/";
// -------------------------------------------------------------


// -------------------------------------------------------------
// -------------------------------------------------------------
const ParserPreset = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const {
    project: { collectionsRepo, flatFilesReverse },
  } = projects;
  const nav = useSelector((state) => state.nav);
  const { collectionid } = nav;
  if (!collectionid) {
    return null;
  }
  const collection = collectionsRepo[collectionid];
  const {preset} = collection;
  const files = collection.fileids.map((fileid) => flatFilesReverse[fileid]);

  const doHipgnosis = async (e) => {
    dispatch({
      type: "INIT_MAIN_PROGRESS",
      title: "Parsing file data",
      total: files.length,
    });
    e.stopPropagation();
    for (var ii = 0; ii < files.length; ii++) {
      dispatch({
        type: "UPDATE_MAIN_PROGRESS",
        current: ii,
        status: files[ii].name,
      });
      await dispatch(hipgnosify(files[ii], preset));
    }
    dispatch({
      type: "CLOSE_MAIN_PROGRESS",
    });
  };

  return (
    <div>
     <ProgressBar />
      <div className="field has-addons">
        <p className="control">
          <select
            value={preset}
            className="select pick in-group"
            onChange={(evt) => {
              dispatch({
                type: "SET_PRESET",
                preset: evt.target.value,
                collectionid,
              });
            }}
          >
            <option value="">- Pick Preset -</option>
            <option value="satv">SONY ATV</option>
            <option value="umpg">UMPG</option>
            <option value="bmg">BMG</option>
            <option value="wch">Warner-Chappell</option>
            <option value="socan">SOCAN</option>
            <option value="ascap-domcon">ASCAP Domestic Concord</option>
            <option value="warner-music-group-gfr">
              Warner Music Group GFR
            </option>

            <option value="double-header">Double Header</option>
            <option value="semicolon">CSV Semicolon</option>
            <option value="universalrs">Universal R.S.</option>
            <option value="sony">SONY</option>
            <option value="prsdrtest">PRS D.R. Test</option>
            <option value="bmi-bonus">BMI Bonus</option>
            <option value="wm-gr">Warner Music - Gross Royalty</option>

            <option value="universal-con-song">
              Universal Concatenated Song
            </option>
            <option value="universal-con-source">
              Universal Concatenated Source
            </option>
            <option value="dirty-con-source">
              Dirty Hit Concatenated Source
            </option>
            <option value="domino-con-income">
              Domino Concatenated Income
            </option>
          </select>
        </p>
        <p className="control">
          {preset ? (
            <button className="button is-primary" onClick={doHipgnosis}>
              <span className="icon is-small">
                <i className="fa-duotone fa-cogs" />
              </span>
              <span>Apply</span>
            </button>
          ) : null}
        </p>
      </div>
    </div>
  );
};

export default ParserPreset;
