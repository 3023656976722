import React, { useState } from "react";
import bird from "Media/flying_bird.png";
// -------------------------------------------------------
import "./signin.css";
const logins = {
  test: "98989",
  admin: "98989",
  will: "98989",
  renate: "98989",
  jan: "98989",
  andrew: "98989",
  mauritz: "98989",
  thomas: "98989",
  colin: "98989",
  owen: "98989",
};

// -------------------------------------------------------
// -------------------------------------------------------
const SignIn = ({}) => {
  const [user, setUser] = useState("");
  const [passcode, setPasscode] = useState("");

  // -------------------------------------------------------
  const setter = {
    user: setUser,
    passcode: setPasscode,
  };

  // -------------------------------------------------------
  const signIn = () => {
    if (user && passcode) {
      if (logins[user] !== passcode) {
        alert("Wrong login info...");
        return;
      }
      localStorage.setItem("user", user);
      window.location.href = "./";
    }
  };

  // -------------------------------------------------------
  const handleChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    setter[key](value);
  };

  // -------------------------------------------------------
  return (
    <div className="signin-container">
      <div className="box wrapper">
        <div className="box signin-wrapper">
          <div className="bird-wrapper">
            <img src={bird} />
          </div>
          <div className="context-block">
            User
            <input
              className="input"
              type="text"
              placeholder="Enter User"
              value={user}
              onChange={handleChange}
              id="user"
            />
          </div>
          <div className="context-block">
            Passcode
            <input
              className="input"
              type="password"
              placeholder="Enter Passcode"
              value={passcode}
              onChange={handleChange}
              id="passcode"
            />
          </div>
          <div className="button-row">
            <button className={`button is-primary`} onClick={signIn}>
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// -------------------------------------------------------
// -------------------------------------------------------
export default SignIn;
