import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { HotTable } from "@handsontable/react";
// -------------------------------------------------------------
import { setSynced, storeNorming } from "Redux/norming/actions";
// -------------------------------------------------------------
import "./handsontable.full.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ items, hot }) => {
  console.log("Rendering Main Table");

  const dispatch = useDispatch();
  //const hot = useRef(null);

  const afterChange = (changes, source) => {
    if (source !== "loadData") {
      dispatch(setSynced("income", true));
      dispatch(storeNorming(items, "income"));
      /*const ref = hot.current.hotInstance;
      for (var ii = 0; ii < changes.length; ii++) {
        const [row, key, fromValue, toValue] = changes[ii];
        if (key === "Normalized_Income_Type_9LC") {
          const incomeType = ref.getDataAtRowProp(row, "Income Type");
          const thirdParty = ref.getDataAtRowProp(row, "Third Party");
          dispatch(updateIncomeType(incomeType, thirdParty, key, toValue, database));  
        }
      }*/
    }
  };

  const afterSelection = (row, col) => {};

  return (
    <HotTable
      ref={hot}
      data={items.data}
      stretchH="all"
      height={500}
      licenseKey="non-commercial-and-evaluation"
      colHeaders={items.colHeaders}
      afterChange={afterChange}
      afterSelection={afterSelection}
      columns={items.columns}
      columnSorting={true}
      fixedRowsTop={0}
      fixedColumnsLeft={1}
      rowHeaders={true}
      manualColumnResize={true}
      colWidths={[35, 15]}
    />
  );
};

export default MainTable;
