import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({ items, close, update }) => {
  const dispatch = useDispatch();
  const [poolCandidates, setPoolCandidates] = useState([]);
  const [nPoolCount, setNPoolCount] = useState(0);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const allSongs = items.data.map((x, index) => ({ song: x.Song, index }));
    const thesePoolCandidates = allSongs
      .filter((x) =>
        x.song.match(
          /(netflix|hbo|vh1|ADD. DISTRIBUTION|ADD DISTRIBUTION|ADDITIONAL DISTRIBUTION|ADD. DIST.|ADDITIONAL DIST.|Settlement|REEMISSION|Proxy|ADD. DISTR.|facebook|e\!|youtube|dmx)/i
        )
      )
      .map((x) => ({ candidate: x.song, isPool: true, index: x.index }));
    setPoolCandidates(thesePoolCandidates);
    setNPoolCount(thesePoolCandidates.length);
    console.log(thesePoolCandidates);
  }, []);

  const setPool = (index, pool) => {
    const thesePoolCandidates = [...poolCandidates];
    thesePoolCandidates[index].isPool = pool;
    setPoolCandidates(thesePoolCandidates);
    setNPoolCount(thesePoolCandidates.filter((x) => x.isPool).length);
  };

  const doUpdate = () => {
    setUpdating(true);
    setTimeout(() => {
    const poolIndices = poolCandidates
      .filter((x) => x.isPool)
      .map((x) => x.index);
    update(poolIndices);
    close();
    }, 800)
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card wide-screen">
        <header className="modal-card-head">
          <p className="modal-card-title">Pool Master</p>
          <button className="delete" aria-label="close" onClick={close} />
        </header>

        <section className="modal-card-body">
          <table className="table is-fullwidth is-striped is-bordered">
            <tbody>
              {poolCandidates.map((candidate, poolIndex) => (
                <tr key={candidate.candidate}>
                  <td>{candidate.candidate}</td>
                  <td>
                    <CandidateButton
                      isPool={candidate.isPool}
                      poolIndex={poolIndex}
                      setPool={setPool}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <footer className="modal-card-foot">
          <button
            className={`button is-primary ${updating ? "is-loading" : ""}`}
            onClick={doUpdate}
          >
            Update Pool Revenue&nbsp;<b>({nPoolCount})</b>
          </button>
        </footer>
      </div>
    </div>
  );
};
export default Index;

// -------------------------------------------------------------------
// -------------------------------------------------------------------
const CandidateButton = ({ isPool, setPool, poolIndex }) => {
  if (isPool)
    return (
      <div className="buttons">
        <button
          className="button is-small is-success"
          onClick={() => {
            setPool(poolIndex, true);
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-check" />
          </span>
          <span>Pool</span>
        </button>
        <button
          className="button is-small is-outlined"
          onClick={() => {
            setPool(poolIndex, false);
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-times" />
          </span>
          <span>Not Pool</span>
        </button>
      </div>
    );

  return (
    <div className="buttons">
      <button
        className="button is-small is-outlined"
        onClick={() => {
          setPool(poolIndex, true);
        }}
      >
        <span className="icon is-small">
          <i className="fa-duotone fa-check" />
        </span>
        <span>Pool</span>
      </button>
      <button
        className="button is-small is-danger"
        onClick={() => {
          setPool(poolIndex, false);
        }}
      >
        <span className="icon is-small">
          <i className="fa-duotone fa-times" />
        </span>
        <span>Not Pool</span>
      </button>
    </div>
  );
};
