import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// --------------------------------------------
import {
  querySongDetailsSpotify,
  querySongDetailsGenius,
} from "Redux/songbridge/actions";
// --------------------------------------------
import shortbar from "Media/short-bar.gif";

import "./css.css";

// --------------------------------------------
// --------------------------------------------
const Index = ({ accept }) => {
  const projects = useSelector((state) => state.projects);
  const songbridge = useSelector((state) => state.songbridge);
  const dispatch = useDispatch();
  let { song, performer, showSongDetails, rawSong, songIndex } = songbridge;

  useEffect(async () => {
    if (song && performer && showSongDetails) {
      await dispatch(
        querySongDetailsSpotify(song, performer, (result) => {
          console.log(result);
          setSpotify(result.data);
        })
      );
      await dispatch(
        querySongDetailsGenius(song, performer, (result) => {
          console.log(result);
          setGenius(result.data);
        })
      );
    }
  }, [showSongDetails]);

  const [spotify, setSpotify] = useState(null);
  const [genius, setGenius] = useState(null);

  const [empty, setEmpty] = useState(null);

  if (!showSongDetails) return null;

  const onAccept = (data, infoSource) => {
    accept(data, songIndex, rawSong, infoSource);
    close();
  };

  const close = () => {
    setSpotify(null);
    setGenius(null);
    setEmpty(false);
    dispatch({ type: "SHOW_HIDE_SONG_DETAILS", show: false });
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card wide-screen">
        <header className="modal-card-head">
          <div className="modal-card-title">
            <div className="song-tags-wrapper">
              <div className="tag is-info is-large">{song}</div>
              <div className="tag is-info is-large">{performer}</div>
            </div>
          </div>
          <button className="delete" aria-label="close" onClick={close} />
        </header>

        <section className="modal-card-body">
          <div className="table-container">
            <table className="table is-fullwidth is-striped is-bordered">
              <thead>
                <tr>
                  <th>Song</th>
                  <th>Performer</th>
                  <th>Album</th>
                  <th>Album Type</th>
                  <th>Featuring</th>
                  <th>Release Date</th>
                  <th>Source</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {spotify ? (
                  <tr>
                    <td>{spotify.matchedSong}</td>
                    <td>{spotify.matchedPerformer}</td>
                    <td>{spotify.albumTitle}</td>
                    <td>{spotify.albumType}</td>
                    <td>{spotify.featuring}</td>
                    <td>{spotify.releaseDate}</td>
                    <td>Spotify</td>
                    <td>
                      <button
                        className="button is-small is-primary is-fullwidth"
                        onClick={() => {
                          onAccept(spotify, "spotify_api");
                        }}
                      >
                        Accept
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <img src={shortbar} alt="loading..." />
                    </td>
                  </tr>
                )}
                {genius ? (
                  <tr>
                    <td>{genius.matchedSong}</td>
                    <td>{genius.matchedPerformer}</td>
                    <td>{genius.albumTitle}</td>
                    <td>{genius.albumType}</td>
                    <td>{genius.featuring}</td>
                    <td>{genius.releaseDate}</td>
                    <td>Genius</td>
                    <td>
                      <button
                        className="button is-small is-primary is-fullwidth"
                        onClick={() => {
                          onAccept(genius, "genius_api");
                        }}
                      >
                        Accept
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <img src={shortbar} alt="loading..." />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        <footer className="modal-card-foot">
          <button className={`button`} onClick={close}>
            Close
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Index;
// --------------------------------------------
// --------------------------------------------

const capitalize = (str) =>
  str ? str.charAt(0).toUpperCase() + str.substr(1).toLowerCase() : null;
