import { backend } from "System/system";

// -------------------------------------------------------
// -------------------------------------------------------
export const saveMapping = (mapping, mappingDataString) => async dispatch => {
  await backend("mapping", "save", { mapping, mappingDataString });
  dispatch(getMappings());
}

// -------------------------------------------------------
// -------------------------------------------------------
export const updateMapping = (mappingId, mapping, mappingDataString) => async dispatch => {
  await backend("mapping", "update", { mappingId, mapping, mappingDataString });
  dispatch(getMappings());
}

// -------------------------------------------------------
// -------------------------------------------------------
export const eraseMapping = (mappingId) => async dispatch => {
  await backend("mapping", "erase", { mappingId });
  dispatch(getMappings());
}
// -------------------------------------------------------
// -------------------------------------------------------
export const loadMapping = (mappingid, cb) => async dispatch => {
  const result = await backend("mapping", "load", { mappingid });
  if(result.data && result.data.result) {
    const { mapping, mappingData } = result.data.result;
    cb({ mapping, mappingData });
    return;
  }
  cb(null);
}

// -------------------------------------------------------
// -------------------------------------------------------
export const getMappings = () => async dispatch => {
  const result = await backend("mapping", "list", { });
  if(result.data) {
    dispatch({type: "SET_MAPPINGS_LIST", list: result.data.result});
  }
}