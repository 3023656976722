import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import md5 from "md5";
// ------------------------------------------------------
import { setSignature } from "Redux/signature/actions";

const excluded = [
  "Country",
  "Region",
  "Sub-Region",
  "Hipgnosis-Region",
  "Currency",
  "Cascaded Source",
  "Cascaded Chain",
];

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const nav = useSelector((state) => state.nav);

  const { project } = projects;
  const { collectionsRepo, flatFilesReverse } = project;
  const { collectionid } = nav;
  const collection = collectionsRepo[collectionid];
  const { sql, payor } = collection;

  if (!collection) return null;
  if (!sql) return null;

  const files = collection.fileids.map((fileid) => flatFilesReverse[fileid]);

  return (
    <div>
      <div style = {{margin: "5px 0"}}>
      <button
        className="button is-small box-button"
        onClick={(e) => {
          dispatch(setSignature(collection, files));
        }}
      >
        <span>Write Signature</span>
      </button>
      </div>
      
      <div className="box">
        <div className="columns">
          <div className="column is-one-third">
            <div className="field">
              <div className="control">
                <input
                  onChange={(evt) => {
                    dispatch({
                      type: "ASSIGN_PAYOR",
                      payor: evt.target.value,
                      collectionid,
                    });
                  }}
                  value={payor}
                  className="input"
                  type="text"
                  placeholder="Enter Payor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
