import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -------------------------------------------------------------
import Frame from "Components/FrameProject/";
import Breadcrumbs from "./Navigation/Breadcrumbs";
import MainTabs from "./Navigation/MainTabs";
// -------------------------------------------------------------
import MultipleHeaders from "Components/MultipleHeaders/";
import FileViewer from "Components/FileViewer/";
import NewCollection from "Components/Collection/NewCollection";
// -------------------------------------------------------------
import XLSX2CSV from "./Components/XLSX2CSV";
import ProgressPopup from "./Components/ProgressPopup";
//import StartProgress from "./Components/StartProgress";
import BulkCut from "./Components/BulkCut";
// -------------------------------------------------------------
import { getProject, saveProject } from "Redux/projects/actions";
import { getMappings } from "Redux/mapping/actions";

// -------------------------------------------------------------
import CheckIn from "./Units/CheckIn";
// -------------------------------------------------------------
import Repository from "./Units/Repository/";
// -------------------------------------------------------------
import CollectionsDefine from "./Units/CollectionsDefine";
// -------------------------------------------------------------
import CollectionFiles from "./Units/CollectionProcess/ProcessUnits/CollectionFiles/";
import EditUpdateTable from "./Units/CollectionProcess/ProcessUnits/EditUpdateTable/";
import Dates from "./Units/CollectionProcess/ProcessUnits/Dates/";
// -------------------------------------------------------------
import ParserCustom from "./Units/CollectionProcess/ProcessUnits/ParserCustom";
import ParserMapping from "./Units/CollectionProcess/ProcessUnits/ParserMapping";
import ParserPreset from "./Units/CollectionProcess/ProcessUnits/ParserPreset";
// -------------------------------------------------------------
import Canonicals from "./Units/CollectionProcess/ProcessUnits/Canonicals/";
import Signature from "./Units/CollectionProcess/ProcessUnits/Signature/";
import CollectionDataBase from "./Units/CollectionProcess/ProcessUnits/CollectionDataBase/";
import CollectionFrame from "./Units/CollectionProcess/ProcessUnits/CollectionFrame/";

// -------------------------------------------------------------
import Currencies from "./Units/Currencies";
// -------------------------------------------------------------
import IDs from "./Units/IDs";
// -------------------------------------------------------------
import Checkout from "./Units/Checkout";
// -------------------------------------------------------------
import MasterTable from "./Units/MasterTable";
import MergeFrames from "./Units/MergeFrames";
// -------------------------------------------------------------
import SongNorming from "./Units/Norming/EditSongs";
import IncomeNorming from "./Units/Norming/EditIncome/";
import SourceNorming from "./Units/Norming/EditSources/";
import SourceConfiguration from "./Units/Norming/SourceConfig/";
import SourceChain from "./Units/Norming/SourceChain/";
import Country from "./Units/Norming/Country/";
// -------------------------------------------------------------
import Summary from "./Units/Results/Summary/";
import Downloads from "./Units/Results/Downloads/";

import bars from "Media/bars.gif";
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Screens = {
  checkin: CheckIn,
  repository: Repository,
  checkout: Checkout,
  master: MasterTable,
  ids: IDs,
  currencies: Currencies,
  define: CollectionsDefine,
  files: CollectionFiles,
  dates: Dates,
  preset: ParserPreset,
  mapping: ParserMapping,
  custom: ParserCustom,
  update: EditUpdateTable,
  canonicals: Canonicals,
  sql: CollectionDataBase,
  frame: CollectionFrame,
  merge: MergeFrames,
  signature: Signature,
  normSongs: SongNorming,
  normIncome: IncomeNorming,
  normSource: SourceNorming,
  normSourceConfig: SourceConfiguration,
  normSourceChain: SourceChain,
  normCountry: Country,
  summary: Summary,
  downloads: Downloads,
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    console.log("Fetching project", params.projectid);
    dispatch(getProject(params.projectid));
    dispatch(getMappings());
  }, []);

  const projects = useSelector((state) => state.projects);
  const nav = useSelector((state) => state.nav);
  const { leaf } = nav;

  useEffect(() => {
    console.log("Project has changed");
    dispatch(saveProject(projects.project));
  }, [projects.project]);

  if (!projects.project) {
    return (
      <Frame>
        <div className="loading-project-wrapper">
          <img src={bars} alt="Loading Project" />
        </div>
      </Frame>
    );
  }
  const ActiveScreen =
    Screens.hasOwnProperty(leaf) && projects.project !== null
      ? Screens[leaf]
      : () => <div></div>;

  return (
    <Frame>
      <XLSX2CSV />
      <ProgressPopup />
      <NewCollection />
      <MultipleHeaders />
      <FileViewer />
      <BulkCut />
      <div className="box">
        <MainTabs>
          <ActiveScreen />
        </MainTabs>
      </div>
      <Breadcrumbs />
    </Frame>
  );
};

export default Index;
