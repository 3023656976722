import { downloadDataFile, backend } from "System/system";
import store from "System/mainStore";
import queryString from "query-string";
import axios from "axios";
import {
  setNormingAll,
  nullNormingAll,
  setNorming,
  getNorming,
} from "System/normingDataStore";
import { syncKeys } from "Data/normingKeys";

// -------------------------------------------------------
let skipUpdates = 0;

// -------------------------------------------------------
// -------------------------------------------------------
export const prepNormingExport = (which, normingData) => async (dispatch) => {
  const storeData = store.getState();
  const {
    projects: { project },
  } = storeData;
  const fileid = project._id;
  await backend("norming", "prepDownload", {
    dataString: JSON.stringify(normingData),
    fileid,
    which,
  });
  downloadDataFile(fileid, `${which}s`);
};

// -------------------------------------------------------
// -------------------------------------------------------
const dbOn = () => (dispatch) => {
  dispatch({ type: "SET_DB_ACCESS", dbaccess: true });
};
const dbOff = () => (dispatch) => {
  dispatch({ type: "SET_DB_ACCESS", dbaccess: false });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const displayPopup = (showPopup) => (dispatch) => {
  dispatch({ type: "DISPLAY_IMPORT_POPUP", showPopup });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const displayUploadSourcesPopup = (showPopup) => (dispatch) => {
  dispatch({ type: "DISPLAY_IMPORT_SOURCES_POPUP", showPopup });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const displayUploadIncomesPopup = (showPopup) => (dispatch) => {
  dispatch({ type: "DISPLAY_IMPORT_INCOMES_POPUP", showPopup });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateSourceRow =
  (masterTable, database, row) => async (dispatch) => {
    dispatch(dbOn());
    const result = await backend("norming", "updateSourceRow", {
      masterTable,
      database,
      rowString: JSON.stringify(row),
    });
    dispatch(dbOff());
  };


// -------------------------------------------------------
// -------------------------------------------------------
export const populateSources =
  (masterTable, database, cb) => async (dispatch) => {
    dispatch(dbOn());
    const result = await backend("norming", "getSourcesList", {
      masterTable,
      database,
    });
    let { data, colHeaders, columns } = result.data;
    //data = data.map(entry => ({...entry, Source: entry.Source.substr(0,35) }));
    console.log(data, colHeaders, columns);
    dispatch(dbOff());
    cb({ data, colHeaders, columns });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const populateIncomes =
  (masterTable, database, cb) => async (dispatch) => {
    dispatch(dbOn());
    const result = await backend("norming", "getIncomesList", {
      masterTable,
      database,
    });
    const { data, colHeaders, columns } = result.data;
    console.log(data, colHeaders, columns);
    dispatch(dbOff());
    cb({ data, colHeaders, columns });
  };


// -------------------------------------------------------
// -------------------------------------------------------
export const setPoolRevenue =
  (song, isset, masterTable, database) => async (dispatch) => {
    console.log("Set Pool Revenue");
    dispatch(dbOn());
    await backend("database", "updatePool", {
      song,
      isset,
      masterTable,
      database,
    });
    dispatch(dbOff());
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const findPoolCandidates = (songs) => async (dispatch) => {
  const allSongs = songs.data.map((x) => x.Song);
  const poolCandidates = allSongs.filter((x) =>
    x.match(
      /(netflix|hbo|vh1|ADD. DISTRIBUTION|ADD DISTRIBUTION|ADDITIONAL DISTRIBUTION|ADD. DIST.|ADDITIONAL DIST.|Settlement|REEMISSION|Proxy|ADD. DISTR.|facebook|e\!|youtube|dmx)/i
    )
  );
  dispatch({ type: "SET_POOL_CANDIDATES", poolCandidates });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setSkip = (skip) => {
  skipUpdates = skip;
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updatePoolMulti = (updates) => async (dispatch) => {
  const data = store.getState();
  const { masterTable, database } = data.projects.project;
  await backend("database", "updatePoolMulti", {
    updatesString: JSON.stringify(updates),
    masterTable,
    database,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const queryGenius = (q) => async (dispatch) => {
  const access_token =
    "NIiU34YIFZaoRosyzIUS8fa8MqDWL6ZBRANBn7gqCbdCxyrTkx7OBODL6g_6WhET";
  dispatch({
    type: "SET_GENIUS",
    genius: { query: q, songDetails: {}, albumDetails: {} },
  });
  const requestData = {
    method: "get",
    url:
      "https://api.genius.com/search?" +
      queryString.stringify({ access_token, q }),
    headers: {},
  };
  const result = await axios(requestData);
  const { hits } = result.data.response;
  console.log("Hits are", hits);
  dispatch({ type: "SET_GENIUS_HITS", hits });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const queryGeniusDate = (songId) => async (dispatch) => {
  const access_token =
    "NIiU34YIFZaoRosyzIUS8fa8MqDWL6ZBRANBn7gqCbdCxyrTkx7OBODL6g_6WhET";
  const requestData = {
    method: "get",
    url:
      `https://api.genius.com/songs/${songId}?` +
      queryString.stringify({ access_token }),
    headers: {},
  };
  const result = await axios(requestData);
  const { song } = result.data.response;
  dispatch({ type: "SET_GENIUS_SONG_DETAILS", song });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const queryGeniusAlbum = (albumId) => async (dispatch) => {
  const access_token =
    "NIiU34YIFZaoRosyzIUS8fa8MqDWL6ZBRANBn7gqCbdCxyrTkx7OBODL6g_6WhET";
  const requestData = {
    method: "get",
    url:
      `https://api.genius.com/albums/${albumId}?` +
      queryString.stringify({ access_token }),
    headers: {},
  };
  const result = await axios(requestData);
  const { album } = result.data.response;
  console.log("Album", album);
  dispatch({ type: "SET_GENIUS_ALBUM_DETAILS", album });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const smartSuggest =
  (songs, performer, populate) => async (dispatch) => {
    const data = store.getState();
    const { masterTable, database } = data.projects.project;

    const nItems = songs.data.length;
    dispatch({
      type: "UPDATE_GENIUS_PROGRESS",
      pointer: 0,
      total: nItems,
      status: "",
    });

    const items = songs.data.map((x) => ({
      song: x["Song"],
      stem: song2stem(x["Song"]),
    }));
    const matches = [];

    for (var ii = 0; ii < nItems; ii++) {
      dispatch({
        type: "UPDATE_GENIUS_PROGRESS",
        pointer: ii,
        total: nItems,
        status: `Song ${ii}/${nItems}: ${items[ii].song}`,
      });

      const geniusMatch = await geniusGenius(performer, items[ii]);
      if (geniusMatch) {
        await backend("database", "smartSuggest", {
          ...geniusMatch,
          performer,
          masterTable,
          database,
        });
      }
    }
    populate();
    dispatch({
      type: "UPDATE_GENIUS_PROGRESS",
      pointer: null,
    });
  };

const song2stem = (song) => {
  return song.toUpperCase().replace(/\W/g, "");
};

const geniusGenius = async (performer, item) => {
  const access_token =
    "NIiU34YIFZaoRosyzIUS8fa8MqDWL6ZBRANBn7gqCbdCxyrTkx7OBODL6g_6WhET";
  const q = item.song + " " + performer;
  const requestData = {
    method: "get",
    url:
      "https://api.genius.com/search?" +
      queryString.stringify({ access_token, q }),
    headers: {},
  };
  const result = await axios(requestData);
  const { hits } = result.data.response;

  let song = "",
    release = "",
    album = "",
    songId = null;
  let hasMatch = false;

  if (hits.length > 0) {
    const thisHit = hits[0];
    console.log(song2stem(thisHit.result.title), item.stem);
    if (song2stem(thisHit.result.title) === item.stem) {
      hasMatch = true;
      song = thisHit.result.title;
      songId = thisHit.result.id;
      const requestData = {
        method: "get",
        url:
          `https://api.genius.com/songs/${songId}?` +
          queryString.stringify({ access_token }),
        headers: {},
      };
      const songResult = await axios(requestData);
      let { release_date } = songResult.data.response.song;
      if (songResult.data.response.song.album) {
        album = songResult.data.response.song.album.name;
      }
      release = release_date;
      if (!release_date && album) {
        const albumId = songResult.data.response.song.album.id;
        const requestData = {
          method: "get",
          url:
            `https://api.genius.com/albums/${albumId}?` +
            queryString.stringify({ access_token }),
          headers: {},
        };
        const albumResult = await axios(requestData);
        if (albumResult.data.response.album) {
          release = albumResult.data.response.album.release_date;
        }
      }
    }
  }
  if (hasMatch) {
    if (release) release = release.split("-").reverse().join("-");
    return { original: item.song, song, release, album };
  }
  return null;
};


// -------------------------------------------------------
// -------------------------------------------------------
export const suggestIncome =
  (incomeType, thirdParty, cb) => async (dispatch) => {
    const result = await backend("norming", "suggestIncome", {
      incomeType,
      thirdParty,
    });
    console.log(incomeType, thirdParty, result.data.normalized, result.data.normalized2);
    cb(result.data);
  };


// -------------------------------------------------------
// -------------------------------------------------------
export const loadNorming = (projectid, cb) => async (dispatch) => {
  const result = await backend("norming", "loadNorming", {
    projectid,
  });

  if (!result.data) {
    nullNormingAll();
    const syncing = {
      songUnsynced: false,
      incomeUnsynced: false,
      sourceUnsynced: false,
    };
    dispatch({ type: "SYNC_UNSYNC_NORMING", syncing });
    cb();
    return;
  }

  const songNorming = result.data.songNormingString
    ? JSON.parse(result.data.songNormingString)
    : null;
  const incomeNorming = result.data.incomeNormingString
    ? JSON.parse(result.data.incomeNormingString)
    : null;
  const sourceNorming = result.data.sourceNormingString
    ? JSON.parse(result.data.sourceNormingString)
    : null;
  const countryNorming = result.data.countryNormingString
    ? JSON.parse(result.data.countryNormingString)
    : null;
  const { songUnsynced, incomeUnsynced, sourceUnsynced } = result.data;

  setNormingAll({
    songNorming,
    incomeNorming,
    sourceNorming,
    countryNorming
  });

  const syncing = { songUnsynced, incomeUnsynced, sourceUnsynced };
  dispatch({ type: "SYNC_UNSYNC_NORMING", syncing });
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const storeNorming = (norming, which) => async (dispatch) => {
  console.log("Store Norming", norming, which)
  const data = store.getState();
  const projectid = data.projects.project._id;
  setNorming(which, norming);
  await backend("norming", "saveNorming", {
    normingString: JSON.stringify(norming),
    projectid,
    which,
  });
};


// -------------------------------------------------------
// -------------------------------------------------------
export const setSynced = (which, unsynced) => async (dispatch) => {
  const data = store.getState();
  const { project } = data.projects;
  const projectid = project._id;
  const syncing = {};
  syncing[syncKeys[which]] = unsynced;
  dispatch({ type: "SYNC_UNSYNC_NORMING", syncing });
  backend("norming", "updateSynced", {
    projectid,
    which,
    unsynced,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateRow = (database, row, which) => async (dispatch) => {
  dispatch(dbOn());
  const result = await backend("norming", "updateRow", {
    database,
    which,
    rowString: JSON.stringify(row),
  });
  dispatch(dbOff());
};

// -------------------------------------------------------
// -------------------------------------------------------
export const syncWithMaster = (which) => async (dispatch) => {
  const data = store.getState();
  const {
    project: { database },
  } = data.projects;

  const norming = JSON.parse(JSON.stringify(getNorming(which)));
  dispatch(setSynced(which, false));
  dispatch({ type: "INIT_MAIN_PROGRESS", total: norming.data.length });

  for (var ii = 0; ii < norming.data.length; ii++) {
    const thisRow = norming.data[ii];
    dispatch({
      type: "UPDATE_MAIN_PROGRESS",
      status: thisRow["Song"],
      current: ii,
    });

    switch (which) {
      case "song":
        thisRow["Original_Song_Title_SB"] = thisRow["Song"];
        delete thisRow["Song"];
        delete thisRow["Cnt"];
        delete thisRow["Rev"];
        delete thisRow["Pool"];
        await dispatch(updateRow(database, thisRow, "song"));
        break;
      case "income":
        thisRow["Income_Type_SB"] = thisRow["Income Type"];
        thisRow["Third_Party_9LC"] = thisRow["Third Party"];
        delete thisRow["Cnt"];
        await dispatch(updateRow(database, thisRow, "income"));
        break;
      case "source":
        thisRow["Source_SB"] = thisRow["Source"];
        delete thisRow["Source"];
        delete thisRow["Cnt"];
        await dispatch(updateRow(database, thisRow, "source"));
        break;
      case "country":
        thisRow["Country_SB"] = thisRow["Country"];
        delete thisRow["Country"];
        delete thisRow["Cnt"];
        await dispatch(updateRow(database, thisRow, "country"));
        break;

      default:
        break;
    }
  }
  dispatch({ type: "CLOSE_MAIN_PROGRESS" });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const populateFromMaster = (which, cb) => async (dispatch) => {
  const storeData = store.getState();
  const {
    project: { database, songHeaders },
  } = storeData.projects;

  let api;
  if(which==="song") api = "getSongList";
  if(which==="income") api = "getIncomeList";
  if(which==="source") api = "getSourceList";
  if(which==="country") api = "getCountryList"

  const params = { database };
  if (which === "song") {
    params.songHeadersString = JSON.stringify(songHeaders);
  }

  dispatch(dbOn());
  const result = await backend("norming", api, params);
  const { data, colHeaders, columns } = result.data;
  dispatch(dbOff());
  dispatch(setSynced(which, false));
  cb({ data, colHeaders, columns });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setRaw = (songIndex, rawSong, song, performer) => (dispatch) => {
  dispatch({
    type: "SONGBRIDGE_SET_SONG",
    songIndex,
    rawSong,
    song,
    performer,
  });
};
