import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import "./css.css";


// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  return (
    <div className="box">
      Coming soon
    </div>
  );
};
export default Index;