import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
// -------------------------------------------------------------
import { getStaticURI } from "System/system";

// -------------------------------------------------------------
// -------------------------------------------------------------
import { hideFileViewer } from "Redux/files/actions";
//import { extractCsvFromPdf } from "Redux/conversions/actions";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const files = useSelector((state) => state.files);

  if (!files.viewer) return null;
  const { data } = files.viewer;
  if (!data) return null;

  console.log("Data is", data);
  const filestring = data
    ? data.file.name.length <= 70
      ? data.file.name
      : data.file.name.substr(0, 70) + "..."
    : "";

  const uri = getStaticURI(`${data.file.path}/${data.file.name}`);
  console.log("URI", uri);
  console.log("Path", `${data.file.path}/${data.file.name}`);

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card wide-screen wide-wide">
        <header className="modal-card-head">
          <div className="tag is-danger">{filestring}</div>
          <p className="modal-card-title" />
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              dispatch(hideFileViewer());
            }}
          />
        </header>
        <section className="modal-card-body">
          <iframe title="PDF" src={uri} className="i-frame" />
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-small"
            onClick={() => {
              dispatch(hideFileViewer());
            }}
          >
            Done
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Index;
