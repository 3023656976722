import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import { loadMapping, saveMapping, updateMapping, eraseMapping } from "Redux/mapping/actions";
// ------------------------------------------------------
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import "Styles/handsontable.full.css";
import { map } from "react-spreadsheet/dist/point-map";

// ------------------------------------------------------
// ------------------------------------------------------
const ParserMapping = () => {
  const [newMapping, setNewMapping] = useState(false);
  const [mappingId, setMappingId] = useState(null);
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.mapping);

  return (
    <div>
      {newMapping ? (
        <NewMapping
          onClose={() => setNewMapping(false)}
          mappingId={mappingId}
        />
      ) : (
        <button
          className="button"
          onClick={() => {
            setMappingId(null);
            setNewMapping(true);
          }}
        >
          New Mapping
        </button>
      )}
      <div className="table-container margin-15">
        <table className="table is-striped">
          <tbody>
            {list.map((item, index) => (
              <tr
                key={item.id}
                className="is-clickable"
                onClick={() => {
                  setMappingId(item._id);
                  setNewMapping(true);
                }}
              >
                <td>{index + 1}</td>
                <td>{item.mapping}</td>
                <td onClick = { (evt) => {
                  evt.stopPropagation();
                  if(window.confirm("Are you sure you want to delete this mapping?")) {
                    dispatch(eraseMapping(item._id));
                  };
                }}>
                  <i className="fa-duotone fa-trash-alt" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ParserMapping;

// -----------------------------------------------------
// -----------------------------------------------------
const NewMapping = ({ onClose, collectionid, mappingId }) => {
  const [mapping, setMapping] = useState("");
  const [nRows, setNRows] = useState("1");
  const [data, setData] = useState([{ key: "", value: "" }]);
  const hot = useRef(null);

  useEffect(() => {
    if (mappingId) {
      dispatch(
        loadMapping(mappingId, (res) => {
          if (res !== null) {
            const { mapping, mappingData } = res;
            setMapping(mapping);
            setData(mappingData);
            setNRows(mappingData.length);
          }
        })
      );
    }
  }, [mappingId]);
  const dispatch = useDispatch();

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head convert">
          <div className="header">
            <p className="modal-card-title">{mappingId}</p>
            <button className="delete" onClick={onClose} />
          </div>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Mapping</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Enter Field"
                value={mapping}
                onChange={(evt) => {
                  setMapping(evt.target.value);
                }}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Number of Rows</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Number of Rows"
                value={nRows}
                onChange={(evt) => {
                  const nRows = parseInt(evt.target.value);
                  setNRows(evt.target.value);
                  if (nRows) {
                    const newData = [];
                    for (let ii = 0; ii < nRows; ii++) {
                      newData.push({ key: "", value: "" });
                    }
                    setData(newData);
                  }
                }}
              />
            </div>
            <br />
            <label className="label">Key / Value</label>
            <HotTable
              ref={hot}
              data={data}
              stretchH="all"
              height={350}
              licenseKey="non-commercial-and-evaluation"
              columnSorting={true}
              manualColumnResize={true}
              fixedRowsTop={0}
              //fixedColumnsLeft={1}
              rowHeaders={true}
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          {mappingId ? (
            <button
              onClick={() => {
                const mappingDataString = JSON.stringify(
                  hot.current.hotInstance.getData()
                );
                dispatch(updateMapping(mappingId, mapping, mappingDataString));
                onClose();
              }}
              className="button is-primary"
              disabled={!mapping}
            >
              Update Mapping
            </button>
          ) : (
            <button
              onClick={() => {
                const mappingDataString = JSON.stringify(
                  hot.current.hotInstance.getData()
                );
                dispatch(saveMapping(mapping, mappingDataString));
                onClose();
              }}
              className="button is-primary"
              disabled={!mapping}
            >
              Save Mapping
            </button>
          )}
          <button className="button" onClick={onClose}>
            Discard
          </button>
        </footer>
      </div>
    </div>
  );
};
