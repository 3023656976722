import { useSelector, useDispatch } from 'react-redux';
// ------------------------------------------------------
import { setBulkReference } from "Redux/collections/actions";

// ------------------------------------------------------
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {

  const dispatch = useDispatch();
  const collections = useSelector( state => state.collections );
  const projects = useSelector( state => state.projects );
  const { aggregateHeaders, bulkCollection }  = collections;

  if(!aggregateHeaders) return null;

  const fileids = [...bulkCollection.fileids]; //.map( fileid => project.flatFilesReverse[fileid] );

  return(
    <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card sum-attributes">
      <header className="modal-card-head">
        <p className="modal-card-title">
          Set Reference for Collection
        </p>
        <button 
          className="delete" 
          aria-label="close"
          onClick = { () => {
            dispatch({ 
              type: "SET_BULK_HEADERS", 
              aggregateHeaders: null,
              bulkCollectionId: null
            });
          } }
        />
      </header>

      <section className="modal-card-body">
        <div className = "table-container small-table">
        {
          aggregateHeaders.map(
            header => {
              return(
                <div key = {header} className = "button-wrapper">
                  <button 
                    className = "button is-fullwidth is-light is-small"
                    onClick = { () => {
                      dispatch(setBulkReference(fileids, header))
                      } }
                  >
                    <span>{ header }</span>
                  </button>
                </div>
              )
            }
          )
        }
        </div>
      </section>

      <footer className="modal-card-foot">
      </footer>
    </div>
  </div>
  )
}
export default Index;

