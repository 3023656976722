import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import MasterProgress from "./MasterProgress";
// -------------------------------------------------------------
import {
  makeMasterTable,
  truncateMaster,
  mergeCollectionTable,
  unMergeCollectionTable,
  initMasterTable,
  setProjectCurrencies,
  setOutputCurrency,
  upgradeMaster,
} from "Redux/database/actions";
// -------------------------------------------------------------
import "./css.css";

const confirm = () => {
  return window.confirm("Are you sure? This action cannot be undone.");
};


// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [allInProgress, setAllInProgress] = useState(false);
  const [upgrading, setUpgrading] = useState(false);

  const projects = useSelector((state) => state.projects);

  const {
    project,
    project: { collectionsRepo, outputCurrency, currency },
  } = projects;

  if (!project.hasMaster) {
    return (
      <p className="control">
        <button
          className="button is-primary"
          onClick={() => {
            dispatch(initMasterTable(project, () => {}));
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-database" />
          </span>
          <span>Init Master Table</span>
        </button>
      </p>
    );
  }

  const collectionids = Object.keys(collectionsRepo);
  const includedTables = project.tables.map((tableid) => ({
    id: tableid,
    headline: collectionsRepo[tableid].headline,
  }));
  console.log("included tables", includedTables.map(x => x.id), includedTables.length);
  const excludedTables = [];
  const includedSet = new Set(project.tables);
  for (var ii = 0; ii < collectionids.length; ii++) {
    if (!includedSet.has(collectionids[ii])) {
      const thisColl = collectionsRepo[collectionids[ii]];
      if (thisColl.hasTable) {
        excludedTables.push({
          id: thisColl.id,
          headline: thisColl.headline,
        });
      }
    }
  }

  const addAll = async () => {
    dispatch({ type: "RESET_SUMMARY" });
    setAllInProgress(true);
    for (var ii = 0; ii < excludedTables.length; ii++) {
      await dispatch(
        mergeCollectionTable(project, excludedTables[ii].id, false, () => {})
      );
    }
    setAllInProgress(false);
  };

  
  return (
    <div className="master-wrapper">
      <MasterProgress />
      {includedTables.length ? (
        <div className="box">
          <div className="headline">Collection Tables in Master:</div>
          <p className="buttons">
            {includedTables.map((table) => (
              <MinusTableButton
                key={table.id}
                project={project}
                table={table}
                allInProgress={allInProgress}
              />
            ))}
          </p>
        </div>
      ) : null}

      {excludedTables.length ? (
        <div className="box">
          <div className="headline">Collection Tables not in Master:</div>
          <p className="buttons">
            {excludedTables.map((table) => (
              <PlusTableButton
                key={table.id}
                project={project}
                table={table}
                allInProgress={allInProgress}
              />
            ))}
          </p>
        </div>
      ) : null}

      <div className="box">
        <div className="buttons">
          {excludedTables.length >= 2 ? <AddAllButton addAll={addAll} /> : null}

          {includedTables.length !== 0 ? (
            <TruncateButton project={project} />
          ) : null}

          <button
            className={`button ${upgrading ? "is-loading" : ""}`}
            onClick={() => {
              dispatch({ type: "RESET_SUMMARY" });
              setUpgrading(true);
              dispatch(
                upgradeMaster(project, () => {
                  setUpgrading(false);
                })
              );
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-level-up-alt" />
            </span>
            <span>Update Master Table Structure</span>
          </button>
        </div>
      </div>
      <div className = "database-clear">
      { project.database }
      </div>
    </div>
  );
};
export default Index;


// -----------------------------------------------------------
// -----------------------------------------------------------
const AddAllButton = ({ addAll }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState("");
  return (
    <button
      className={`button is-primary ${isLoading}`}
      onClick={() => {
        dispatch({ type: "RESET_SUMMARY" });
        setIsLoading("is-loading");
        addAll();
      }}
    >
      <span className="icon is-small">
        <i className="fa-duotone fa-plus-circle" />
      </span>
      <span>Add All</span>
    </button>
  );
};
// -----------------------------------------------------------
// -----------------------------------------------------------
const TruncateButton = ({ project }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");

  return (
    <button
      className={`button is-danger ${isLoading}`}
      onClick={() => {
        if (confirm()) {
          dispatch({ type: "RESET_SUMMARY" });
          setIsLoading("is-loading");
          dispatch(truncateMaster(project));
        }
      }}
    >
      <span className="icon is-small">
        <i className="fa-duotone fa-trash-alt" />
      </span>
      <span>Truncate Master</span>
    </button>
  );
};

// -----------------------------------------------------------
// -----------------------------------------------------------
const MinusTableButton = ({ project, table, allInProgress }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");

  return (
    <button
      disabled={allInProgress}
      className={`button is-warning is-light ${isLoading}`}
      onClick={() => {
        setIsLoading("is-loading");
        if (confirm()) {
          dispatch({ type: "RESET_SUMMARY" });
          dispatch(unMergeCollectionTable(project, table.id, () => {}));
        }
      }}
    >
      <span>{table.headline}</span>
      <span className="icon is-small">
        <i className="fa-duotone fa-times"></i>
      </span>
    </button>
  );
};
// -----------------------------------------------------------
// -----------------------------------------------------------
const PlusTableButton = ({ project, table, allInProgress }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");
  return (
    <button
      disabled={allInProgress}
      className={`button is-info is-light ${isLoading}`}
      onClick={() => {
        setIsLoading("is-loading");
        dispatch({ type: "RESET_SUMMARY" });
        dispatch(mergeCollectionTable(project, table.id, true, () => {}));
      }}
    >
      <span className="icon is-small">
        <i className="fa-duotone fa-plus-circle"></i>
      </span>
      <span>{table.headline}</span>
    </button>
  );
};