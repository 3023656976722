import React from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import Dir from "Components/Dir";
// -------------------------------------------------------------
import { showHideConverters } from "Redux/conversions/actions";
// -------------------------------------------------------------
import Upload from "./Upload";

// -------------------------------------------------------------
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const conversions = useSelector((state) => state.conversions);
  const { project } = projects;
  const { dirs } = project;
  const { showConverters } = conversions;

  return (
    <div className="main-files">
      {showConverters ? <Upload /> : null}
      <div className="upload-wrapper">
        <button
          className="button is-primary"
          onClick={() => {
            dispatch(showHideConverters(true));
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-cloud-upload-alt"></i>
          </span>
          <span>Upload Zip</span>
        </button>
      </div>
      <div className="box">
        {dirs.map((dir) => (
          <Dir
            key={dir.id}
            dir={dir}
            current={projects.current}
            setFiles={() => {}} //setFiles }
            setCurrent={() => {}} //setCurrent }
          />
        ))}
      </div>
    </div>
  );
};
export default Index;
