let songNorming = null;
let incomeNorming = null;
let sourceNorming = null;
let countryNorming = null;

// ----------------------------------------------
// ----------------------------------------------
export const setSongNorming = data => {
  songNorming = JSON.parse(JSON.stringify(data));
}

export const getSongNorming = () => {
  return songNorming;
}

// ----------------------------------------------
// ----------------------------------------------
export const nullNormingAll = () => {
  songNorming = null;
  incomeNorming = null;
  sourceNorming = null;
  countryNorming = null;
};


export const setNormingAll = (combinedData) => {
  songNorming = JSON.parse(JSON.stringify(combinedData.songNorming));
  incomeNorming = JSON.parse(JSON.stringify(combinedData.incomeNorming));
  sourceNorming = JSON.parse(JSON.stringify(combinedData.sourceNorming));
  countryNorming = JSON.parse(JSON.stringify(combinedData.countryNorming));
};

export const setNorming = (which, normingData) => {
  console.log("SET Norming", which);
  if(which==="song") {
    songNorming = JSON.parse(JSON.stringify(normingData));
  }
  if(which==="income") {
    incomeNorming = JSON.parse(JSON.stringify(normingData));
  }
  if (which === "source") {
    sourceNorming = JSON.parse(JSON.stringify(normingData));
  }
  if (which === "country") {
    countryNorming = JSON.parse(JSON.stringify(normingData));
  }
};

export const getNorming = (which) => {
  console.log("GET Norming", which);
  switch (which) {
    case "song":
      return songNorming;
    case "income":
      return incomeNorming;
    case "source":
      return sourceNorming;
    case "country":
      return countryNorming;
  }
};