import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { HotTable } from "@handsontable/react";
// -------------------------------------------------------------
import { setSynced, storeNorming } from "Redux/norming/actions";
// -------------------------------------------------------------
import { countries } from "Data/all_countries";
// -------------------------------------------------------------

import "./handsontable.full.css";
const which = "country";

const countriesList = countries.map((x) => x.country.toUpperCase());
const countryRef = {};
countries.forEach((x) => {
  countryRef[x.country.toUpperCase()] = x;
});

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ items }) => {
  console.log("Rendering Main Table");

  const dispatch = useDispatch();
  const hot = useRef(null);

  const afterChange = (changes, source) => {
    if (source !== "loadData") {
      const ref = hot.current.hotInstance;
      for (var ii = 0; ii < changes.length; ii++) {
        const [row, field, fromValue, toValue] = changes[ii];
        if (field === "Normalized_Country_9LC") {
          const key = ref
            .getDataAtRowProp(row, "Normalized_Country_9LC")
            .toUpperCase();
          if (countriesList.includes(key)) {
            const details = countryRef[key];
            ref.setDataAtRowProp([
              [row, "Territory_Status_DD", details["domestic"]],
              [row, "Region_DD", details["region"]],
              [row, "Subregion_DD", details["sub-region"]],
              [row, "Intermediate_Region_DD", details["intermediate-region"]],
              [row, "Regional_Block_DD", details["hipgnosis-region-code"]],
            ]);
          }
          dispatch(setSynced(which, true));
          dispatch(storeNorming(items, which));
        }
      }
    }
  };

  const afterSelection = (row, col) => {};

  return (
    <HotTable
      ref={hot}
      data={items.data}
      stretchH="all"
      height={500}
      licenseKey="non-commercial-and-evaluation"
      colHeaders={items.colHeaders}
      afterChange={afterChange}
      afterSelection={afterSelection}
      columns={items.columns}
      columnSorting={true}
      fixedRowsTop={0}
      fixedColumnsLeft={1}
      rowHeaders={true}
      manualColumnResize={true}
      colWidths={[35, 15]}
    />
  );
};

export default MainTable;
