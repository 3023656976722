import React from "react";
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import { applyBulkCut, setBulkCut } from "Redux/collections/actions"
import "./css.css"

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {

  const [cut, setCut] = useState(0);

  const dispatch = useDispatch();
  const collections = useSelector( state => state.collections );

  const { bulkCut } = collections;
  if(!bulkCut) return null;

  const submit = () => {
    dispatch(applyBulkCut(bulkCut.collection, cut));
    setCut(0);
  }

  return(
    <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className = "modal-card-head">
        <p className = "modal-card-title"></p>
        <button 
          className="delete" 
          aria-label="close"
          onClick = { () => { dispatch(setBulkCut(null)) }}
        />
      </header>
      <section className="modal-card-body">
        <div className = "box">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Cut Value</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control">
                <input
                  className = "input shorter"
                  type = "text"
                  placeholder = "Enter Collection Title"
                  value = { cut }
                  onChange = { e => { setCut(e.target.value) } }
                  onKeyPress = { e => { if(e.key==='Enter') submit(); }}
                  autoFocus = { true }
                />
              </p>
            </div>
          </div>
        </div>

        </div>
      </section>
      <footer className="modal-card-foot">
        <button 
          className = "button is-primary"
          onClick = { submit }
        >
          Set For All Files
        </button>
        <button 
          className = "button is-warning"
          onClick = { () => { dispatch(setBulkCut(null)) }}
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
  )
}

export default Index;