import { engine, download, apPy, backend } from "System/system";
import store from "System/mainStore";

const isLocal = window.location.href.slice(7, 16) === "localhost";

// -------------------------------------------------------
// -------------------------------------------------------
export const attachDir = (path, tag) => async (dispatch) => {
  const projects = store.getState().projects;
  const projectid = projects.project._id;

  const result = await backend("files", "getDir", { path, tag });
  if (result.data) {
    const { dir, flatFiles } = result.data;
    dispatch({ type: "ATTACH_DIR_TO_PROJECT", dir, flatFiles });
  }
  backend("outputs", "setStatus", { projectid, projectStatus: "in-progress" });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const findDirs = (projectid) => async (dispatch) => {
  const result = await backend("files", "getDir", { projectid });
  const { dir, flatFiles, updateHeaders } = result.data;
  return flatFiles;
};

// -------------------------------------------------------
// -------------------------------------------------------
export const hideFileViewer = () => async (dispatch) => {
  dispatch({ type: "HIDE_FILE_VIEWER" });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const dispPDF = (file) => async (dispatch) => {
  dispatch({ type: "DISPLAY_FILE_VIEWER", filetype: "pdf" });
  dispatch({ type: "SET_FILE_VIEWER_DATA", data: { file } });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const dispCSV = (file) => async (dispatch) => {
  dispatch({ type: "DISPLAY_FILE_VIEWER", filetype: "csv" });
  const result = await apPy("files", "csvInfo", {
    file: file.name,
    path: file.path.replace(/\./g, ""),
    top: 6,
    bottom: 6,
    cut: file.cut,
  });

  const info =
    typeof result.data === "string"
      ? JSON.parse(result.data.replace(/NaN/gi, "null"))
      : result.data;

  const { head, tail, headers } = info;
  dispatch({
    type: "SET_FILE_VIEWER_DATA",
    data: { head, tail, headers, file, nLines: null, fullFile: false },
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getCSVInfo = async (file) => {
  const result = await apPy("files", "csvInfo", {
    file: file.name,
    path: file.path.replace(/\./g, ""),
    top: 6,
    bottom: 6,
    cut: file.cut,
  });
  const info =
    typeof result.data === "string"
      ? JSON.parse(result.data.replace(/NaN/gi, "null"))
      : result.data;

  const { head, tail, headers } = info;
  return { head, tail, headers };
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateCut = (file, cut) => async (dispatch) => {
  const result = await apPy("files", "csvInfo", {
    file: file.name,
    path: file.path.replace(/\./g, ""),
    top: 6,
    bottom: 6,
    cut,
  });

  const info =
    typeof result.data === "string"
      ? JSON.parse(result.data.replace(/NaN/gi, "null"))
      : result.data;

  const { head, tail, headers } = info;
  dispatch({
    type: "SET_FILE_VIEWER_DATA",
    data: {
      head,
      tail,
      headers,
      file: { ...file, cut },
      nLines: null,
      fullFile: false,
    },
  });
  dispatch({ type: "UPDATE_FILE_CUT", fileid: file.id, cut });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateRef = (file, ref) => async (dispatch) => {
  dispatch({ type: "UPDATE_FILE_REF", fileid: file.id, ref });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateCSVInfo = (file, top, bottom) => async (dispatch) => {
  const result = await apPy("files", "csvInfo", {
    file: file.name,
    path: file.path.replace(/\./g, ""),
    top,
    bottom,
    cut: file.cut,
  });
  const info = JSON.parse(result.data.replace(/NaN/gi, '""'));
  const { head, tail, headers } = info;
  dispatch({
    type: "SET_FILE_VIEWER_DATA",
    data: { head, tail, headers, file, nLines: null, fullFile: false },
  });
};
// -------------------------------------------------------
// -------------------------------------------------------
export const countCSVLines = (file) => async (dispatch) => {
  const result = await backend("files", "countCSVLines", {
    filepath: `${file.path}/${file.name}`,
  });
  if (result.data) {
    const { nLines } = result.data;
    dispatch({ type: "SET_LINE_COUNT", nLines });
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const countAllCSVLines = (files) => async (dispatch) => {
  for (var ii = 0; ii < files.length; ii++) {
    const file = files[ii];
    const result = await apPy("files", "csvCountLines", {
      file: file.name,
      path: file.path.replace(/\./g, ""),
      ref: file.ref,
      cut: file.cut,
    });
    const { nLines } = result.data;
    dispatch({
      type: "SET_FILE_ATTRIBUTE",
      fileid: file.id,
      attribute: "nlines",
      title: "Line Count",
      value: nLines,
    });
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const encodeUtf8 = (project) => async (dispatch) => {
  console.log("Encode utf8");

  for (var ii = 0; ii < project.flatFiles.length; ii++) {
    const flatFile = project.flatFiles[ii];

    dispatch({
      type: "SET_PROGRESS",
      headline: "Encode UTF8",
      mainline: "Converting file encoding...",
      status: flatFile.name,
      pointer: ii,
      total: project.flatFiles.length,
    });

    let csvfile = flatFile.name;
    let path = flatFile.path;
    await backend("files", "encodeUtf8", { csvfile, path });
    console.log(ii);
  }

  dispatch({ type: "UTF8_ENCODED" });
  dispatch({ type: "SET_PROGRESS", pointer: null });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getFullCSV = (file) => async (dispatch) => {
  const result = await backend("files", "getFullCSV", {
    filepath: `${file.path}/${file.name}`,
  });
  if (result.data) {
    const { data } = result.data;
    console.log(data);
    dispatch({ type: "SET_FULL_CSV", data });
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const runSumFile = async (file, header, cb) => {
  const result = await backend("files", "runSumFile", {
    filepath: `${file.path}/${file.name}`,
    header,
  });
  cb(result.data.fullSum);
};

// -------------------------------------------------------
// -------------------------------------------------------
export const eraseFile = (fileid, collectionid) => (dispatch) => {
  dispatch({ type: "ERASE_FILE", fileid, collectionid });
};

// -------------------------------------------------------
export const setPDFs = (pdfs) => (dispatch) => {
  dispatch({
    type: "SET_PDFs",
    pdfs,
  });
};

// -------------------------------------------------------
export const setCSVs = (csvs) => (dispatch) => {
  dispatch({
    type: "SET_CSVs",
    csvs,
  });
};

// -------------------------------------------------------
export const setFiles = (pdfs, csvs) => (dispatch) => {
  dispatch({
    type: "SET_FILES",
    pdfs,
    csvs,
  });
};

// -------------------------------------------------------
export const setAuditFiles = (projectid, pdfs, csvs) => (dispatch) => {
  dispatch({
    type: "SET_AUDIT_FILES",
    pdfs,
    csvs,
  });
};

// -------------------------------------------------------
export const setCurrent = (dir) => (dispatch) => {
  dispatch({
    type: "SET_CURRENT",
    current: dir ? dir.id : null,
    currentDir: dir ? dir.title : null,
  });
};

// -------------------------------------------------------
export const setSingleFile = (csv) => (dispatch) => {
  if (csv) {
    dispatch(runLines(csv.path));
  }
  if (!csv) {
    dispatch({ type: "SET_LINES", lines: null });
  }
  dispatch({
    type: "SET_SINGLE_FILE",
    csv,
  });
};

// -------------------------------------------------------
export const setPDF = (pdf) => async (dispatch) => {
  dispatch({
    type: "SET_PDF",
    pdf,
  });
  if (pdf) {
    const result = await backend("files", "parsePDF", { pdfPath: pdf.path });
    console.log(result);
  }
};

// -------------------------------------------------------
export const setSums = (files) => (dispatch) => {
  if (files) {
    const filepaths = files.map((x) => x.path);
    backend("files", "getHeadersMultiple", { filepaths }).then((result) => {
      if (result.data) {
        console.log(result.data);
        let headers = new Set();
        result.data.headers.forEach((line) => {
          line.forEach((line) => {
            headers.add(line.replace(/\"/g, ""));
          });
        });
        console.log("Headers are", headers);
        let sumheaders = Array.from(headers);
        console.log("Adding sumheaders");
        dispatch({ type: "SET_SUMHEADERS", sumheaders });
      }
    });
  }

  dispatch({ type: "SET_SUMS", set: files !== null });
};

// -------------------------------------------------------
export const runHeader = (filepath) => (dispatch) => {
  console.log("Run Header");
  backend("files", "getHeader", { filepath, nlines: 1 }).then((result) => {
    if (result.data) {
      const textString = result.data;
      if (textString) {
        const header = JSON.parse(textString);
        dispatch({ type: "SET_HEADER", header });
      }
    }
  });
};

// -------------------------------------------------------
export const runLines = (filepath) => (dispatch) => {
  console.log("Run Lines");
  backend("files", "getHeader", { filepath, nlines: 4 }).then((result) => {
    if (result.data) {
      const textString = result.data;
      if (textString) {
        const lines = JSON.parse(textString);
        dispatch({ type: "SET_LINES", lines });
      }
    }
  });
};

// -------------------------------------------------------
export const runSigma = (filepath, heading) => (dispatch) => {
  console.log("RunSigma", filepath, heading);
  dispatch({ type: "SET_SIGMA", nlines: null, sum: null, heading });

  backend("files", "getBalance", { filepath, heading }).then((result) => {
    if (result.data) {
      const { nlines, sum } = result.data;
      dispatch({ type: "SET_SIGMA", nlines, sum, heading });
    }
  });
};

// -------------------------------------------------------
/*export const getHeaders_prev = (files) => dispatch => {
  
  const filepaths = files.map( x => `${x.path}/${x.name}` );
  backend( 
    'files','getHeadersMultiple', 
    { filepaths }
  ).then(
    result => {
      console.log(result)
      if(result.data) {
        const { headers } = result.data;
        console.log("Headers are", headers);
        dispatch({ 
          type: "SET_HEADERS", 
          headers: headers
        });
      }
    }
  );
}*/

// -------------------------------------------------------
export const getHeaders = (allfiles) => async (dispatch) => {
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = JSON.stringify(allfiles.map((x) => x.name));
  const paths = JSON.stringify(allfiles.map((x) => x.path.replace(/\./g, "")));
  const cuts = JSON.stringify(allfiles.map((x) => x.cut));

  const result = await apPy("files", "getHeadersMultiple", {
    files,
    paths,
    cuts,
  });

  dispatch({
    type: "SET_HEADERS",
    headers: result.data.headers,
  });
};

// -------------------------------------------------------
export const getHeadersSet = (collection, allfiles) => async (dispatch) => {
  allfiles = allfiles.filter((file) => file.ext === "CSV");
  const files = JSON.stringify(allfiles.map((x) => x.name));
  const paths = JSON.stringify(allfiles.map((x) => x.path.replace(/\./g, "")));
  const cuts = JSON.stringify(allfiles.map((x) => x.cut));

  const result = await apPy("files", "getHeadersMultiple", {
    files,
    paths,
    cuts,
  });

  const items = new Set();
  result.data.headers.forEach((headers) => {
    headers.forEach((item) => {
      items.add(item);
    });
  });

  const headerSet = Array.from(items);
  dispatch({
    type: "SET_BULK_HEADERS",
    aggregateHeaders: headerSet,
    bulkCollection: collection,
  });
};

// -------------------------------------------------------
export const buildDB = (files) => (dispatch) => {
  const filepaths = files.map((x) => x.path);
  backend("files", "getHeadersMultiple", { filepaths }).then((result) => {
    if (result.data) {
      const { headers } = result.data;
      console.log("headers are", headers);
      const allHeaders = new Set();
      for (var row of headers) {
        for (var header of row) {
          if (header.trim()) {
            allHeaders.add(header.trim());
          }
        }
      }
      const dbheaders = Array.from(allHeaders).map((header) => ({
        header,
        datatype: "float",
      }));
      dispatch({
        type: "SET_DB_HEADERS",
        dbheaders,
      });
    }
  });
};

// -------------------------------------------------------
/*export const sendData___ = (fullfiles) => async dispatch => {
  const filepaths = fullfiles.map( x => x.path );
  const instructions = [];
  //filepaths.length
  for(var ii=0; ii<3; ii++) {
    const result = await backend( 
      'files','getFileData', 
      { filepath: filepaths[ii] } 
    );
    const nLines = result.data.lineNumber;
    const batchSize = 50000;
    const nBatches = Math.ceil(nLines/batchSize);

    for(var hh=0; hh<nBatches; hh++) {
      const ini = hh*batchSize;
      const fin = Math.min(ini + batchSize, nLines);
      instructions.push({
        filepath: filepaths[ii],
        ini, fin,
        key_ini: 0, key_fin: 15
      });
      instructions.push({
        filepath: filepaths[ii],
        ini, fin,
        key_ini: 15, key_fin: 30
      });
      console.log(filepaths[ii]);
    }
  }
  console.log(instructions);
  for(var ff = 0; ff<instructions.length; ff++) {
    console.log(ff, instructions.length);
    const instruction = instructions[ff];
    console.log(instruction);
    await backend( 
      'files','sendData2', 
      { ...instruction } 
    );
  }
}*/

// -------------------------------------------------------
export const shutHeaders = () => (dispatch) => {
  dispatch({ type: "SET_HEADERS", headers: null });
};

// -------------------------------------------------------
export const sendData = (fullfiles) => async (dispatch) => {
  const filepaths = fullfiles.map((x) => x.path);
  const files = fullfiles.map((x) => x.title);

  await backend("files", "cleanFiles", {
    fullfilesstring: JSON.stringify(fullfiles),
  });
};

// -------------------------------------------------------
export const makeTables = (fullfiles) => async (dispatch) => {
  const files = fullfiles.map((x) => x.title);
  await backend("files", "makeTables", { files });
};

// -------------------------------------------------------
export const importFromCSV = (fullfiles) => async (dispatch) => {
  const files = fullfiles.map((x) => x.title);
  await backend("files", "importFromCSV", { files });
};

// -------------------------------------------------------
export const attachExtra = (fullfiles) => async (dispatch) => {
  const files = fullfiles.map((x) => x.title);
  await backend("files", "attachExtra", { files });
};

// -------------------------------------------------------
export const mergeTables = (fullfiles) => async (dispatch) => {
  const files = fullfiles.map((x) => x.title);
  await backend("files", "mergeTables", { files });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const buildTable = (csvs, headings) => async (dispatch) => {
  let nFiles = csvs.length;
  let nLineItems = headings.length + 1;

  let table = [],
    grand = [];
  for (var ii = 0; ii < nFiles; ii++) {
    let line = [];
    for (var vv = 0; vv < nLineItems; vv++) {
      line[vv] = null;
    }
    table[ii] = line;
  }

  table[nFiles] = [];
  for (vv = 0; vv < nLineItems; vv++) {
    table[nFiles][vv] = 0;
  }

  dispatch({
    type: "SET_TABLE",
    table,
  });

  for (var lineIndex = 0; lineIndex < nFiles; lineIndex++) {
    const result = await backend("files", "getBalances", {
      filepath: csvs[lineIndex].path,
      headings,
    });

    if (result.data) {
      table[lineIndex][0] = result.data.nlines;
      for (var vv = 0; vv < result.data.sums.length; vv++) {
        table[lineIndex][vv + 1] = result.data.sums[vv];
      }
      if (lineIndex === nFiles - 1) {
        for (ii = 0; ii < nFiles; ii++) {
          for (vv = 1; vv < nLineItems; vv++) {
            table[nFiles][vv] += table[ii][vv];
          }
        }
      }
      for (vv = 1; vv < nLineItems; vv++) {
        table[nFiles][vv] = Math.round(100 * table[ii][vv]) / 100;
      }
      dispatch({
        type: "SET_TABLE",
        table,
      });
    }
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const prepDownload = (cb) => async (dispatch) => {
  const data = store.getState();

  const { mainDir } = data.projects.project;
  const mainPath = mainDir.path;
  dispatch({
    type: "SET_PREP_DOWNLOAD",
    prepDownload: true,
  });
  const result = await backend("files", "prepDownload", { mainPath });
  dispatch({
    type: "SET_PREP_DOWNLOAD",
    prepDownload: false,
  });
  download(result.data.zip);
};

// -------------------------------------------------------
// -------------------------------------------------------
export const calculateDerived =
  (files, dateparsers, cb) => async (dispatch) => {
    let col_a, col_b, format_a, format_b;
    let allowed = true;
    if (dateparsers.length) {
      col_a = dateparsers[0].column;
      format_a = dateparsers[0].format;
      if (!format_a) {
        allowed = false;
      }
      if (dateparsers.length === 2) {
        col_b = dateparsers[1].column;
        format_b = dateparsers[1].format;
        if (!format_b) {
          allowed = false;
        }
      }
      if (allowed) {
        for (var ii = 0; ii < files.length; ii++) {
          const file = files[ii];
          await apPy("files", "derive", {
            file: file.name,
            path: file.path.replace(/\./g, ""),
            cut: file.cut,
            col_a,
            col_b,
            format_a,
            format_b,
          });
        }
      }
    }
    cb();
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const eraseAuditFile = (file) => (dispatch) => {
  dispatch({ type: "ERASE_AUDIT_FILE", file });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const cleanFile = (file) => async dispatch => {
  await apPy("files", "clean_special", {
    file: file.name,
    path: file.path.replace(/\./g, ""),
  });
};