import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { buildDB, dropTable, queryDB } from "Redux/database/actions";
// -------------------------------------------------------------
import RunBuild from "./RunBuild";
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const database = useSelector((state) => state.database);
  const nav = useSelector((state) => state.nav);

  const { project } = projects;
  const { data } = database;

  const { collectionsRepo } = project;
  const { collectionid } = nav;
  const collection = collectionsRepo[collectionid];
  const { sql, hasTable } = collection;

  useEffect(() => {
    if (hasTable) {
      dispatch(queryDB(collection.id, project.database));
    }
  }, []);

  if (!project.dirs || !collection) return null;

  const thisDir = project.dirs[0];
  const headline = thisDir.title;
  const mainPath = thisDir.path.slice(0, -headline.length);
  const files = collection.fileids.map(
    (fileid) => project.flatFilesReverse[fileid]
  );

  const buildCollectionTable = () => {
    const { secondaryRoyalty, secondaryTitle, mapper } = sql;
    
    dispatch(
      buildDB(
        collection.id,
        project.database,
        files,
        mainPath,
        mapper,
        secondaryRoyalty,
        secondaryTitle
      )
    );
  };

  console.log("Data is", data);
  return (
    <>
      {database.dbProgress ? <RunBuild /> : null}
      <div className="field has-addons">
        {hasTable ? (
          <p className="control">
            <button
              className="button is-danger box-button is-outlined"
              onClick={() => {
                dispatch(dropTable(collection.id, project.database));
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-trash-alt" />
              </span>
              <span>Drop Collection Table</span>
            </button>
          </p>
        ) : (
          <p className="control">
            <button
              className="button is-primary box-button"
              onClick={buildCollectionTable}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-database" />
              </span>
              <span>Build Collection Table</span>
            </button>
          </p>
        )}
      </div>
      <MainTable data={data} hasTable={hasTable} />
      {hasTable ? (
        <div className="sql-table">
          SQL Table: <b>{collection.id}</b>
        </div>
      ) : null}
    </>
  );
};

export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ data, hasTable }) => {
  if (!data || !hasTable || !data.length) return null;
  return (
    <div className="table-container">
      <table className="table is-fullwidth is-striped is-hoverable small-table is-bordered collection">
        <thead>
          <tr>
            {Object.keys(data[0]).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => (
            <tr key={`row-${rowindex}`}>
              {Object.keys(row).map((key) => {
                let entry = row[key];
                if (!entry) {
                  return <td key={`${rowindex}-${key}`}></td>;
                }
                if (entry.length > 10) {
                  entry = entry.substr(0, 10) + "...";
                }
                if (entry.length) entry = entry.replace(/ /g, "\u00A0");
                return <td key={`${rowindex}-${key}`}>{entry}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
