import { combineReducers } from "redux";
import files from "Redux/files/reducer";
import user from "Redux/user/reducer";
import flyway from "Redux/flyway/reducer";
import projects from "Redux/projects/reducer";
import collections from "Redux/collections/reducer";
import database from "Redux/database/reducer";
import norming from "Redux/norming/reducer";
import conversions from "Redux/conversions/reducer";
import updates from "Redux/updates/reducer";
import nav from "Redux/nav/reducer";
import summary from "Redux/summary/reducer";
import progress from "Redux/progress/reducer";
import songbridge from "Redux/songbridge/reducer";
import outputs from "Redux/outputs/reducer";
import mapping from "Redux/mapping/reducer";
// --------------------------------------------------------------

const rootReducer = combineReducers({
  files,
  user,
  projects,
  collections,
  conversions,
  database,
  flyway,
  norming,
  updates,
  nav,
  summary,
  progress,
  songbridge,
  outputs,
  mapping
});

// --------------------------------------------------------------
export default rootReducer;
