import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../NormingButtons";
// -------------------------------------------------------------
import MainTable from "./MainTable";
import ProgressBar from "Components/ProgressBar";
// -------------------------------------------------------------
import { getNorming } from "System/normingDataStore";
// -------------------------------------------------------------
import { storeNorming, populateFromMaster } from "Redux/norming/actions";
// -------------------------------------------------------------
import "./css.css";

const which = "source";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {
  const dispatch = useDispatch();

  const [populating, setPopulating] = useState(false);
  const [items, setItems] = useState(null);

  useEffect(() => {
    const theseItems = getNorming(which);
    setItems(theseItems);
  }, []);

  const populate = async () => {
    setPopulating(true);
    await dispatch(
      populateFromMaster(which, (result) => {
        setPopulating(false);
        setItems(JSON.parse(JSON.stringify(result)));
        dispatch(storeNorming(result, which));
      })
    );
  };

  return (
    <div className="norming-wrapper">
      <ProgressBar />
      <NormingButtons
        which={which}
        populate={populate}
        populating={populating}
        items={items}
      />

      {items ? (
        <div className="hands-on-wrapper">
          <MainTable items={items} />
        </div>
      ) : null}
    </div>
  );
};
export default Index;
