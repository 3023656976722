import React from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import DirPhysical from "Components/DirPhysical";

// -------------------------------------------------------------
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {

  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const conversions = useSelector((state) => state.conversions);
  const { project } = projects;
  const { dirs } = project;

  return (
    <div className="main-files">
      <div className="columns">
        <div className="column">
          <div className="box">
            {dirs.map((dir) => (
              <DirPhysical key={dir.id} dir={dir} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
