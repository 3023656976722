import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
// ------------------------------------------------------
import { displayPopup, setSynced, updateRow } from "Redux/norming/actions";
import { uploadAndReadCSV } from "System/system";
// ------------------------------------------------------
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({ populate, which, items }) => {
  const dispatch = useDispatch();

  const norming = useSelector((state) => state.norming);
  const projects = useSelector((state) => state.projects);
  const { project } = projects;
  const { database } = project;

  const [current, setCurrent] = useState("start");

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles) {
      setCurrent("loading");
      const formData = new FormData();
      formData.append("filetoupload", acceptedFiles[0]);
      const result = await uploadAndReadCSV(formData);
      setCurrent("updating");
      console.log("Items are", items);

      if (which === "source") {
        const itemsRef = {};
        items.data.forEach((item) => {
          itemsRef[item["Source"].trim()] = item["Normalized_Source_9LC"];
        });

        if (result.data) {
          const { table } = result.data;
          let cnt = 0;
          if (table) {
            for (var ii = 0; ii < table.length; ii++) {
              const normalized = itemsRef[table[ii]["Source_SB"].trim()];
              if (!normalized) {
                setCurrent(`Processing row ${ii} of ${table.length}`);
                console.log("-------------------");
                console.log(cnt, normalized, table[ii]);
                console.log("-------------------\n");
                await dispatch(updateRow(database, table[ii], which));
                cnt++;
              }
            }
            dispatch(displayPopup(false));
            dispatch(setSynced(which, false));
            populate();
          }
        }
        return;
      }

      if (result.data) {
        const { table } = result.data;
        if (table) {
          for (var ii = 0; ii < table.length; ii++) {
            setCurrent(`Processing row ${ii} of ${table.length}`);
            await dispatch(updateRow(database, table[ii], which));
          }
          dispatch(displayPopup(false));
          dispatch(setSynced(which, false));
          populate();
        }
      }
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  if (!norming.importPopup) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card sum-attributes">
        <header className="modal-card-head">
          <p className="modal-card-title">
            Upload CSV File ({which.at(0).toUpperCase() + which.slice(1)}s)
          </p>
          <button
            onClick={() => {
              dispatch(displayPopup(false));
            }}
            className="delete"
            aria-label="close"
          />
        </header>

        <section className="modal-card-body">
          {current === "start" ? (
            <div {...getRootProps()} className="drop-zone-sources">
              <input {...getInputProps()} />
              Drop CSV here
            </div>
          ) : current === "loading" ? (
            <div>Loading... </div>
          ) : current === "updating" ? (
            <div>Updating...</div>
          ) : (
            <div>{current}</div>
          )}
        </section>

        <footer className="modal-card-foot"></footer>
      </div>
    </div>
  );
};
export default Index;
