import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../NormingButtons";
import IncomeSuggestions from "./IncomeSuggestions";
// -------------------------------------------------------------
import MainTable from "./MainTable";
import ProgressBar from "Components/ProgressBar";
// -------------------------------------------------------------
import { getNorming } from "System/normingDataStore";
// -------------------------------------------------------------
import {
  storeNorming,
  populateFromMaster,
  suggestIncome,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import "./css.css";

const which = "income";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {
  const dispatch = useDispatch();
  const hot = useRef(null);

  const [populating, setPopulating] = useState(false);
  const [suggesting, setSuggesting] = useState(false);
  const [suggestions, setSuggestions] = useState(null);

  const [items, setItems] = useState(null);

  useEffect(() => {
    const theseItems = getNorming(which);
    setItems(theseItems);
  }, []);

  const populate = async () => {
    setPopulating(true);
    await dispatch(
      populateFromMaster(which, (result) => {
        setPopulating(false);
        setItems(JSON.parse(JSON.stringify(result)));
        dispatch(storeNorming(result, which));
      })
    );
  };

  // -------------------------------------------------------------
  const makeSuggestions = async () => {
    setSuggesting(true);
    setSuggestions(null);
    const theseSuggestions = [];
    for (var ii = 0; ii < items.data.length; ii++) {
      const income = items.data[ii]["Income Type"];
      const thirdparty = items.data[ii]["Third Party"];
      await dispatch(
        suggestIncome(income.trim(), thirdparty.trim(), (result) => {
          if (result.normalized) {
            const suggested = result.normalized;
            const suggested2 = result.normalized2;
            const current = items.data[ii]["Normalized_Income_Type_9LC"];
            const current2 = items.data[ii]["Normalized_Income_Type_II_9LC"];
            theseSuggestions.push({
              dataindex: ii,
              income,
              thirdparty,
              current,
              suggested,
              isEqual: (current === suggested),
              current2,
              suggested2,
              isEqual2: (current2 === suggested2)
            });
          }
        })
      );
    }
    setSuggestions(JSON.parse(JSON.stringify(theseSuggestions)));
    //setSuggesting(false);
  };

  return (
    <div className="norming-wrapper">
      <IncomeSuggestions
        suggesting={suggesting}
        close={() => {
          setSuggesting(false);
        }}
        suggestions={suggestions}
        hot = { hot }
        update = {
          () => {
            dispatch(storeNorming(items, "income"));
          }
        }
      />
      <ProgressBar />
      <NormingButtons
        which={which}
        populate={populate}
        populating={populating}
        items={items}
      >
        <button
          className={`button ${suggesting ? "is-loading" : ""} is-small`}
          onClick={makeSuggestions}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-magic" />
          </span>
          <span>Make Suggestions</span>
        </button>
      </NormingButtons>

      {items ? (
        <div className="hands-on-wrapper">
          <MainTable items={items} hot = { hot } />
        </div>
      ) : null}
    </div>
  );
};
export default Index;
