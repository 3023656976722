import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { showPivotPopup, updatePivot } from "Redux/projects/actions";
// -------------------------------------------------------------
const clients = [
  { entity: "AMR" },
  { entity: "Alternative" },
  { entity: "ARC" },
  { entity: "Art Issue" },
  { entity: "BitSonic" },
  { entity: "Bitter and Twisted Management" },
  { entity: "CC Young" },
  { entity: "Concord" },
  { entity: "Cowbell" },
  { entity: "Crescendo" },
  { entity: "David Ravden" },
  { entity: "GRF" },
  { entity: "Hipgnosis" },
  { entity: "Influence Media" },
  { entity: "Kilometre" },
  { entity: "Letter One" },
  { entity: "Lyric" },
  { entity: "Mojo" },
  { entity: "Music Law" },
  { entity: "Music Royalties Inc" },
  { entity: "Nine Lives Capital" },
  { entity: "Primary Wave" },
  { entity: "Royalty Earner" },
  { entity: "Songvest" },
  { entity: "Tailwind" },
  { entity: "Tempo" },
  { entity: "Utopia" },
];

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);

  const [data, setData] = useState({});
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (projects.project) {
      const project = projects.project;
      let { pivot, client, keyType, associates, keys } = project;
      if (!keys) keys = [{ pivot: pivot, keyType: keyType }];
      if (!associates) associates = [];
      setData({
        pivot,
        client,
        keyType,
        associates,
        keys,
      });
    }
  }, [projects.project]);

  const submit = () => {
    dispatch(updatePivot(data));
  };

  const onChange = (evt) => {
    setData({
      ...data,
      [evt.target.id]: evt.target.value,
    });
    setHasChanged(true);
  };

  const onChangeKey = (evt, keyindex) => {
    const keys = [...data.keys];
    keys[keyindex][evt.target.id] = evt.target.value;
    setData({ ...data, keys });
    setHasChanged(true);
  };

  const addKey = () => {
    setData({ ...data, keys: [...data.keys, {
      pivot: "", keyType: "Artist"
    }] });
    setHasChanged(true);
  }

  const removeKey = (keyindex) => {
    setData({
      ...data,
      keys: [
        ...data.keys,
      ].filter((x,i) => i!==keyindex)
    });
  }

  if (!projects.project) return null;
  if (!data.keys) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{projects.project.title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              dispatch(showPivotPopup(false));
            }}
          />
        </header>

        <section className="modal-card-body">
          <div className="box">
            <div className="field">
              <label className="label">Client</label>
              <div className="control has-icons-left has-icons-right">
                <select
                  className="input is-success"
                  type="text"
                  placeholder="Enter Catalog Key Entity"
                  value={data.client}
                  id="client"
                  onChange={onChange}
                >
                  {clients.map((client, clientindex) => (
                    <option key={`client-${clientindex}`}>
                      {client.entity}
                    </option>
                  ))}
                </select>
                <span className="icon is-small is-left">
                  <i className="fa-duotone fa-building"></i>
                </span>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="table-container">
              <table className="table table-bordered key-table">
                <thead>
                  <tr>
                    <th>Key Person</th>
                    <th>Key Person Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.keys.map((key, keyindex) => {
                    return (
                      <tr key={`keyrows-${keyindex}`}>
                        <td>
                          <div className="field">
                            <div className="control has-icons-left has-icons-right">
                              <input
                                onChange={(evt) => {
                                  onChangeKey(evt, keyindex);
                                }}
                                autoFocus={true}
                                className="input is-success"
                                type="text"
                                placeholder="Enter Catalog Key Entity"
                                value={key.pivot}
                                id="pivot"
                              />
                              <span className="icon is-small is-left">
                                <i className="fa-duotone fa-user"></i>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="field">
                            <div className="control has-icons-left has-icons-right">
                              <select
                                onChange={(evt) => {
                                  onChangeKey(evt, keyindex);
                                }}
                                autoFocus={true}
                                className="input is-success"
                                type="text"
                                placeholder="Key Person Type"
                                value={key.keyType}
                                id="keyType"
                              >
                                <option>Artist</option>
                                <option>Writer</option>
                                <option>Performer</option>
                                <option>Producer</option>
                                <option>Composer</option>
                                <option>Manager</option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-duotone fa-tags"></i>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            className="delete"
                            onClick={() => {
                              removeKey(keyindex);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <button className="button is-small" onClick={addKey}>
              <span className="icon is-small">
                <i className="fa fa-plus-circle" />
              </span>
            </button>
          </div>

          <div className="box">
            <div className="field">
              <label className="label">Associates</label>
              <div className="control has-icons-left has-icons-right">
                <textarea
                  onChange={onChange}
                  autoFocus={true}
                  className="textarea input is-success"
                  type="text"
                  placeholder="Associate A, Associate B, ..."
                  value={data.associates}
                  id="associates"
                />
                <span className="icon is-small is-left">
                  <i className="fa-duotone fa-users"></i>
                </span>
              </div>
            </div>
          </div>
        </section>

        <footer className="modal-card-foot">
          <button
            className="button is-primary"
            disabled={!hasChanged}
            onClick={submit}
          >
            Update
          </button>

          <button
            className="button"
            onClick={() => {
              dispatch(showPivotPopup(false));
            }}
          >
            Discard
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Index;
