const initialState = {
  importPopup: false,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "UPDATE_GENIUS_PROGRESS":
      if (action.pointer === null) return { ...state, geniusProgress: null };

      return {
        ...state,
        geniusProgress: {
          pointer: action.pointer,
          total: action.total,
          status: action.status,
        },
      };

    // -------------------------------------------------------
    case "DISPLAY_IMPORT_POPUP":
      return {
        ...state,
        importPopup: action.showPopup,
      };
    // -------------------------------------------------------
    case "DISPLAY_IMPORT_SOURCES_POPUP":
      return {
        ...state,
        importSourcesPopup: action.showPopup,
      };
    // -------------------------------------------------------
    case "DISPLAY_IMPORT_INCOMES_POPUP":
      return {
        ...state,
        importIncomesPopup: action.showPopup,
      };

    // -------------------------------------------------------
    case "SET_GENIUS_ALBUM_DETAILS":
      return {
        ...state,
        genius: {
          ...state.genius,
          albumDetails: {
            ...state.genius.albumDetails,
            [action.album.id]: action.album,
          },
        },
      };

    // -------------------------------------------------------
    case "SET_GENIUS_SONG_DETAILS":
      return {
        ...state,
        genius: {
          ...state.genius,
          songDetails: {
            ...state.genius.songDetails,
            [action.song.id]: action.song,
          },
        },
      };

    // -------------------------------------------------------
    case "SET_GENIUS_HITS":
      return { ...state, genius: { ...state.genius, hits: action.hits } };

    // -------------------------------------------------------
    case "SET_GENIUS":
      return { ...state, genius: action.genius };


    // -------------------------------------------------------
    case "SET_POOL_CANDIDATES":
      return { ...state, poolCandidates: action.poolCandidates };

    // -------------------------------------------------------
    case "UPLOAD_SOURCES_POPUP":
      return { ...state, uploadSourcesPopup: action.collectionid };

    // -------------------------------------------------------
    case "ADD_LINK_HEADERS":
      return { ...state, linkHeaders: action.linkHeaders };

    // -------------------------------------------------------
    case "ADD_LINK_HEADERS":
      return { ...state, linkHeaders: action.linkHeaders };

    // -------------------------------------------------------
    case "STORE_SONG_NORMING":
      return { ...state, songNorming: action.songNorming };

    // -------------------------------------------------------
    case "SYNC_UNSYNC_SONG_NORMING":
      return {
        ...state,
        unsyncedSongNorm: action.unsynced,
      };
    // -------------------------------------------------------
    case "SYNC_UNSYNC_NORMING":
      return {
        ...state,
        ...action.syncing
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
