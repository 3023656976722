import { apPy, apFly } from "System/system";
import { saveProjectAfterTimeout } from "Redux/projects/actions";
import store from "System/mainStore";
// -------------------------------------------------------------
import { collectionCoreHeaders, masterHeaders } from "Data/updateHeaders";

// -------------------------------------------------------
// -------------------------------------------------------
export const assignToFlyway = (collectionid) => async (dispatch) => {
  dispatch({ type: "ASSIGN_TO_FLYWAY", collectionid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const unAssignToFlyway = (collectionid) => async (dispatch) => {
  dispatch({ type: "UNASSIGN_TO_FLYWAY", collectionid });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const buildFrame = (includedTables) => async (dispatch) => {
  const { project } = store.getState().projects;
  const { database, datadir } = project;

  const additionalHeaders = masterHeaders
    .filter((x) => !collectionCoreHeaders.includes(x))
    .map((x) => x.header);

  dispatch({
    type: "INIT_MAIN_PROGRESS",
    title: "Building Collection Frames",
    total: includedTables.length,
  });

  const tableids = includedTables.map((x) => x.id);
  for (var ii in tableids) {
    const tableid = tableids[ii];
    dispatch({
      type: "UPDATE_MAIN_PROGRESS",
      current: ii,
      status: `Building frame ${tableid}`,
    });
    await apFly("makeCollectionFrame", {
      database,
      datadir,
      tableid,
    });
  }
  dispatch({
    type: "UPDATE_MAIN_PROGRESS",
    current: ii,
    status: `Fusing frames`,
  });
  await apFly("fuseCollectionFrames", {
    datadir,
    tableids: JSON.stringify(tableids),
    addheaders: JSON.stringify(additionalHeaders),
  });
  dispatch({ type: "FRAME_BUILT" });
  dispatch({ type: "CLOSE_MAIN_PROGRESS" });
  dispatch(saveProjectAfterTimeout(project));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const clearAllFrames = () => async (dispatch) => {
  await dispatch({ type: "CLEAR_ALL_FRAMES" });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const mergeAllFrames = (collections) => async (dispatch) => {
  await dispatch({ type: "CLEAR_ALL_FRAMES" });

  const { project } = store.getState().projects;
  const { datadir } = project;

  const additionalHeaders = masterHeaders
    .filter((x) => !collectionCoreHeaders.includes(x))
    .map((x) => x.header);


  dispatch({
    type: "INIT_MAIN_PROGRESS",
    title: "Merging Collection Frames",
    total: collections.length+1,
  });

  for (var ii in collections) {
    const collectionid = collections[ii].id;
    const collectionHeadline = collections[ii].headline;
    const numberOfFiles = collections[ii].fileids.length;
    dispatch({
      type: "UPDATE_MAIN_PROGRESS",
      current: ii,
      status: `${ii} of ${collections.length}: ${collectionHeadline} (${numberOfFiles})`,
    });
    await dispatch(
      mergeCollectionFrame(collectionid, ii, () => {
        console.log("Merged", collectionid);
      })
    );
  }

  dispatch({
    type: "UPDATE_MAIN_PROGRESS",
    current: collections.length,
    status: `Completing merge, updating columns`,
  });

  await apPy("files", "complete_merge", {
    datadir,
    addheaders: JSON.stringify(additionalHeaders),
  });

  dispatch({ type: "SET_PROJECT_HAS_FRAME", hasFrame: true });
  dispatch({ type: "CLOSE_MAIN_PROGRESS" });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const mergeCollectionFrame = (collectionid, index, cb) => async (dispatch) => {

  const { project } = store.getState().projects;
  const { datadir } = project;

  await apPy("files", "merge_collections_into_master", {
    datadir,
    collectionid,
    index,
  });

  cb();
};


// -------------------------------------------------------
// -------------------------------------------------------
export const unmergeCollectionFrame =
  (collectionid, cb) => async (dispatch) => {
    const { project } = store.getState().projects;
    const { datadir } = project;
    await apFly("unmergeCollectionFrame", {
      collectionid,
      datadir,
    });
    await dispatch({ type: "UNMERGE_FRAME", collectionid });
    cb();
  };
