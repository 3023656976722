import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// --------------------------------------------
import { queryDiscogs, querySongDetails } from "Redux/songbridge/actions";
// --------------------------------------------
import shortbar from "Media/short-bar.gif";

import "./css.css";

// --------------------------------------------
// --------------------------------------------
const Index = ({ accept }) => {
  const projects = useSelector((state) => state.projects);
  const songbridge = useSelector((state) => state.songbridge);
  const dispatch = useDispatch();

  let { rawSong, songIndex, showSongNorm } = songbridge;
  let {
    project: { pivot },
  } = projects;
  rawSong = capitalize(rawSong);
  pivot = capitalize(pivot);

  useEffect(() => {
    if (rawSong && pivot && showSongNorm) {
      setEmpty(false);
      dispatch(
        queryDiscogs(rawSong, pivot, (result) => {
          if (!result) {
            setEmpty(true);
            return;
          }
          setSong(result.title);
          setPerformer(result.performer);
          setLinkout(`https://www.discogs.com/${result.uri}`);
        })
      );
    }
  }, [showSongNorm]);

  const [song, setSong] = useState(null);
  const [performer, setPerformer] = useState(null);
  const [linkout, setLinkout] = useState(null);
  const [empty, setEmpty] = useState(false);

  if (!showSongNorm) return null;

  const onAccept = () => {
    accept(song, performer, songIndex, rawSong);
    close();
  };

  const close = () => {
    setSong(null);
    setPerformer(null);
    setLinkout(null);
    setEmpty(false);
    dispatch({ type: "SHOW_HIDE_SONG_NORM", show: false });
  };

  if (empty)
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Song Norming</p>
            <button className="delete" aria-label="close" onClick={close} />
          </header>
          <section className="modal-card-body">
            <div className="title">Unable to find a match.</div>
            <div className="box" style={{ display: "none" }}>
              <a>Google</a> | <a>Discogs</a>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className={`button`} onClick={close}>
              Close
            </button>
          </footer>
        </div>
      </div>
    );

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Song Norming</p>
          <button className="delete" aria-label="close" onClick={close} />
        </header>

        <section className="modal-card-body">
          {song || performer ? (
            <div>
              <table className="table is-fullwidth is-striped is-bordered">
                <thead>
                  <tr>
                    <th>Song</th>
                    <th>Performer</th>
                    <th>Link Out</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <td>{song}</td>
                  <td>{performer}</td>
                  <td>
                    <a href={linkout} target="_blank">
                      Discogs
                    </a>
                  </td>
                  <td>
                    <button
                      className="button is-small is-primary is-fullwidth"
                      onClick={onAccept}
                    >
                      Accept
                    </button>
                  </td>
                </tbody>
              </table>
            </div>
          ) : (
            <img src={shortbar} alt="loading..." />
          )}
        </section>

        <footer className="modal-card-foot">
          <button className={`button`} onClick={close}>
            Close
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Index;

// ----------------------------------------------
// ----------------------------------------------
const capitalize = (string) => {
  if(!string) return null;
  const capitalized = string
    .toLowerCase()
    .split(" ")
    .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1))
    .join(" ");
  console.log("Capitalized: " + capitalized);
  return capitalized;
}