import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import waitbar from "Media/waitbar.gif";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({ displayStats, close, items }) => {
  if (!displayStats) return null;

  const totalCount = items.data
    .map((x) => parseInt(x.Cnt))
    .reduce((a, b) => a + b, 0);
  const totalRevenue = items.data
    .map((x) => parseFloat(x.Rev))
    .reduce((a, b) => a + b, 0);
  const totalSongs = items.data.length;

  const populatedRows = items.data.filter(
    (x) => x.Song_Name_9LC !== "" && x.Song_Name_9LC !== null
  );
  const coverageCount = populatedRows
    .map((x) => parseInt(x.Cnt))
    .reduce((a, b) => a + b, 0);
  const coverageRevenue = populatedRows
    .map((x) => parseFloat(x.Rev))
    .reduce((a, b) => a + b, 0);
  const coverageSongs = populatedRows.length;

  const twoDecimals = x => Math.round(100*x)/100;

  const percent = (frac, total) => {
    return twoDecimals(100*frac/total);
  }
  return (
    <div className="modal is-active" style={{ zIndex: 9999 }}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Completion Status</p>
          <button onClick={close} className="delete" aria-label="close" />
        </header>

        <section className="modal-card-body">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th></th>
                <th>Completed</th>
                <th>Total</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Songs</td>
                <td>{coverageSongs}</td>
                <td>{totalSongs}</td>
                <td>{percent(coverageSongs, totalSongs)}%</td>
              </tr>
              <tr>
                <td>Count</td>
                <td>{coverageCount}</td>
                <td>{totalCount}</td>
                <td>{percent(coverageCount, totalCount)}%</td>
              </tr>
              <tr>
                <td>Revenue</td>
                <td>{twoDecimals(coverageRevenue)}</td>
                <td>{twoDecimals(totalRevenue)}</td>
                <td>{percent(coverageRevenue, totalRevenue)}%</td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-card-foot">
          <button className="button">Close</button>
        </footer>
      </div>
    </div>
  );
};
export default Index;
