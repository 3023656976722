import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
// -------------------------------------------------------------
import running from "Media/running.gif";
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {

  const database = useSelector( state => state.database );
  if(!database.dbProgress) return null;

  const { headline, status } = database.dbProgress;
  
  return(
    <div className="modal is-active">
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head convert">
        <div className = "header">
        <p className="modal-card-title">
          { headline }
        </p>
        </div>
      </header>
      <section className="modal-card-body">
        <div className = "box">
          <div className = "status-headline">
            <img src = {running} />
            &nbsp;{ headline } 
          </div>
          <i>{ status }</i>
        </div>
      </section>
      <footer className="modal-card-foot" />
    </div>
  </div>
  )
}

export default Index;