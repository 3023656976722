// -------------------------------------------------------
// -------------------------------------------------------
export const explicitSize = (nBytes, fac) => {
  const giga = Math.round(nBytes / 1000000000);
  const gigaRounded = Math.round((fac * nBytes) / 1000000000) / fac;
  if (giga) return { size: gigaRounded, unit: "GB" };

  const mega = Math.round(nBytes / 1000000);
  const megaRounded = Math.round((fac * nBytes) / 1000000) / fac;
  if (mega) return { size: megaRounded, unit: "MB" };

  const kilo = Math.round(nBytes / 1000);
  const kiloRounded = Math.round((fac * nBytes) / 1000) / fac;
  if (kilo) return { size: kiloRounded, unit: "KB" };

  const bytesRounded = Math.round(fac * nBytes) / fac;
  return { size: bytesRounded, unit: "B" };
};
