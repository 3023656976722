import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../NormingButtons";
// -------------------------------------------------------------
import MainTable from "./MainTable";
import ProgressBar from "Components/ProgressBar";
// -------------------------------------------------------------
import { getNorming } from "System/normingDataStore";
// -------------------------------------------------------------
import { countries } from "Data/all_countries";
// -------------------------------------------------------------
import { storeNorming, populateFromMaster } from "Redux/norming/actions";
// -------------------------------------------------------------
import "./css.css";

const which = "country";
const countriesList = countries.map((x) => x.country.toUpperCase());
const countryRef = {};
countries.forEach((x) => {
  countryRef[x.country.toUpperCase()] = x;
});

const capitalize = (string) => {
  const capFrag = (frag) =>
    frag.charAt(0).toUpperCase() + frag.slice(1).toLowerCase();
  return string.split(" ").map(capFrag).join(" ");
};

console.log(capitalize("three Little Piggies"));

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {
  const dispatch = useDispatch();

  const [populating, setPopulating] = useState(false);
  const [items, setItems] = useState(null);

  useEffect(() => {
    const theseItems = getNorming(which);
    setItems(theseItems);
  }, []);

  const populate = async () => {
    setPopulating(true);
    console.log(countriesList);
    await dispatch(
      populateFromMaster(which, (result) => {
        console.log("Result is", result);
        result.colHeaders = [
          ...result.colHeaders,
          "Territory_Status_DD",
          "Region_DD",
          "Subregion_DD",
          "Intermediate_Region_DD",
          "Regional_Block_DD",
        ];
        result.columns = [
          ...result.columns,
          { data: "Territory_Status_DD", readOnly: true },
          { data: "Region_DD", readOnly: true },
          { data: "Subregion_DD", readOnly: true },
          { data: "Intermediate_Region_DD", readOnly: true },
          { data: "Regional_Block_DD", readOnly: true },
        ];
        result.data = result.data.map((item) => {
          item = {
            ...item,
            Territory_Status_DD: null,
            Region_DD: null,
            Subregion_DD: null,
            Intermediate_Region_DD: null,
            Regional_Block_DD: null,
          };

          let column = null;

          if (countriesList.includes(item.Country.toUpperCase())) {
            column = "Country";
          }
          if (item.Normalized_Country_9LC) {
            if (
              countriesList.includes(item.Normalized_Country_9LC.toUpperCase())
            ) {
              column = "Normalized_Country_9LC";
            }
          }

          if (column) {
            const row = countryRef[item[column].toUpperCase()];
            return {
              ...item,
              Normalized_Country_9LC: capitalize(item[column]),
              Territory_Status_DD: row["domestic"],
              Region_DD: row["region"],
              Subregion_DD: row["sub-region"],
              Intermediate_Region_DD: row["intermediate-region"],
              Regional_Block_DD: row["hipgnosis-region-code"],
            };
          }
          return item;
        });
        setPopulating(false);
        setItems(JSON.parse(JSON.stringify(result)));
        dispatch(storeNorming(result, which));
      })
    );
  };

  return (
    <div className="norming-wrapper">
      <ProgressBar />
      <NormingButtons
        which={which}
        populate={populate}
        populating={populating}
        items={items}
      />

      {items ? (
        <div className="hands-on-wrapper">
          <MainTable items={items} />
        </div>
      ) : null}
    </div>
  );
};
export default Index;
