import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import ImportNorming from "../ImportNorming";
import { syncKeys } from "Data/normingKeys";
// -------------------------------------------------------------
import {
  displayPopup,
  syncWithMaster,
  prepNormingExport,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = ({ which, populating, populate, items, children }) => {
  const dispatch = useDispatch();
  const norming = useSelector((state) => state.norming);
  const [showCsv, setShowCsv] = useState(false);

  const isUnsynced = norming[syncKeys[which]];

  return (
    <div className="norming-buttons-wrapper">
      {norming.importPopup ? (
        <ImportNorming which={which} populate={populate} items = { items } />
      ) : null}

      <div className="buttons">
        <button
          className={`button box-button is-small ${
            populating ? "is-loading" : ""
          }`}
          onClick={populate}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-cloud-download-alt" />
          </span>
          <span>Build from Master</span>
        </button>

        <button
          className={`button  is-small ${isUnsynced ? "is-warning" : ""}`}
          onClick={() => {
            dispatch(syncWithMaster(which));
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-sync-alt" />
          </span>
          <span>Sync with Master</span>
        </button>

        {showCsv ? (
          <>
            <button
              className={`button box-button is-small`}
              onClick={() => {
                dispatch(displayPopup(true));
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-upload" />
              </span>
              <span>Import CSV</span>
            </button>
            <button
              className={`button box-button is-small`}
              onClick={() => {
                dispatch(() => {
                  dispatch(prepNormingExport(which, items.data));
                });
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-download" />
              </span>
              <span>Export CSV</span>
            </button>
          </>
        ) : (
          <button
            className={`button box-button is-small`}
            onClick={() => {
              setShowCsv(true);
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-file-alt" />
            </span>
            <span>Offline Ops</span>
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Index;
