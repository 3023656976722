import { doDownloadOutputFile, outPy } from "System/system";

// -------------------------------------------------------
// -------------------------------------------------------
export const downloadOutputFile = (project, filetype, cb) => async (dispatch) => {
  const { database } = project;
  const result = await outPy(filetype, { database })
  if(result.data) {
    const { file } = result.data;
    doDownloadOutputFile(file);
    cb();  
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getTimePeriods = (project) => async (dispatch) => {
  const { database } = project;
  const result = await outPy("findperiod", { database });
  if(result.data) {
    const timePeriods = result.data["Data Periods"];
    dispatch({ type: "SET_OUTPUT_TIME_PERIODS", timePeriods });
  }
};


// -------------------------------------------------------
// -------------------------------------------------------
export const downloadTopFile = (project, period, cb) => async (dispatch) => {
  const { database } = project;
  const result = await outPy("songvest", { database, period })
  if(result.data) {
    const { file } = result.data;
    doDownloadOutputFile(file);
    cb();  
  }
};