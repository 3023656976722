import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import { addSumAttr } from "Redux/collections/actions";

// ------------------------------------------------------
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({ files }) => {
  const dispatch = useDispatch();
  const collections = useSelector((state) => state.collections);
  const { aggregateHeaders } = collections;
  if (aggregateHeaders === null) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card sum-attributes">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Sum Attribute</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              dispatch({
                type: "SET_COLLECTION_AGGREGATE_HEADERS",
                aggregateHeaders: null,
              });
            }}
          />
        </header>

        <section className="modal-card-body">
          <div className="table-container small-table">
            {aggregateHeaders.map((header) => {
              return (
                <div key={header} className="button-wrapper">
                  <button
                    className="button is-fullwidth is-light is-small"
                    onClick={() => {
                      dispatch(addSumAttr(files, header));
                    }}
                  >
                    <span>{header}</span>
                  </button>
                </div>
              );
            })}
          </div>
        </section>

        <footer className="modal-card-foot"></footer>
      </div>
    </div>
  );
};
export default Index;
