import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { HotTable } from '@handsontable/react';
// -------------------------------------------------------------
import copyToClipboard from "System/copyToClipboard";
// -------------------------------------------------------------
import { updateUpdateTable } from "Redux/collections/actions";
import { notify } from "Redux/user/actions";
import { getCSVInfo } from "Redux/files/actions";
// -------------------------------------------------------------
import "./css.css";
import "./handsontable.full.css";
// -------------------------------------------------------------
const monthMapper = {
  '01': { q: 'Q1', h: 'H1' },
  '02': { q: 'Q1', h: 'H1' },
  '03': { q: 'Q1', h: 'H1' },
  '04': { q: 'Q2', h: 'H1' },
  '05': { q: 'Q2', h: 'H1' },
  '06': { q: 'Q2', h: 'H1' },
  '07': { q: 'Q3', h: 'H2' },
  '08': { q: 'Q3', h: 'H2' },
  '09': { q: 'Q3', h: 'H2' },
  '10': { q: 'Q4', h: 'H2' },
  '11': { q: 'Q4', h: 'H2' },
  '12': { q: 'Q4', h: 'H2' },
}
// -------------------------------------------------------------
const quarterMapper = {
  'Q1': 'H1',
  'Q2': 'H1',
  'Q3': 'H2',
  'Q4': 'H2',
}

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {

  const [info, setInfo] = useState(null);
  const dispatch = useDispatch();

  const projects = useSelector( state => state.projects );
  const { project: { collectionsRepo, updateHeaders, flatFilesReverse } } = projects;
  const nav = useSelector( state => state.nav ); 
  const { collectionid } = nav;
  if(!collectionid) { return null; }
  const collection = collectionsRepo[collectionid]; 


  const columns = updateHeaders.map(
    (header, headerindex) => {
      if(headerindex) return { };
      else return { readOnly: true }
    }
  );

  
  const { fileids } = collection;
  const data = fileids.map(
    fileid => [ ...flatFilesReverse[fileid].updateTable ]
  );

  const beforeChange = (changes, source) => {
    for(var ii = 0; ii<changes.length; ii++) {
      let [row, col, dummy, value] = changes[ii];
      if(col===3 && value!==null) value = value.toUpperCase();
      dispatch(updateUpdateTable(row, col, value, fileids));
      if(col===3 && value) {
        const match = value.match(/(\d\d\d\d) ([QH\w]\w)/);
        if(match) {
          const year = match[1];
          dispatch(updateUpdateTable(row, 5, year, fileids));
          const det = match[2];
          if(parseInt(det)) {
            const h = monthMapper.hasOwnProperty(det) ? monthMapper[det].h : '';
            const q = monthMapper.hasOwnProperty(det) ? monthMapper[det].q : '';
            dispatch(updateUpdateTable(row, 6, h, fileids));
            dispatch(updateUpdateTable(row, 7, q, fileids));
            dispatch(updateUpdateTable(row, 8, det, fileids));
            dispatch(updateUpdateTable(row, 9, `${year} ${h}`, fileids));
          }
          if(det.substr(0,1)==='Q') {
            const h = quarterMapper.hasOwnProperty(det) ? quarterMapper[det] : '';
            dispatch(updateUpdateTable(row, 6, h, fileids));
            dispatch(updateUpdateTable(row, 7, det, fileids));
            dispatch(updateUpdateTable(row, 8, '', fileids));
            dispatch(updateUpdateTable(row, 9, `${year} ${h}`, fileids));
          }
          if(det.substr(0,1)==='H') {
            dispatch(updateUpdateTable(row, 6, det, fileids));
            dispatch(updateUpdateTable(row, 7, '', fileids));
            dispatch(updateUpdateTable(row, 8, '', fileids));
            dispatch(updateUpdateTable(row, 9, `${year} ${det}`, fileids));
          }
        }
      }
      if(changes.length===1 && (col===1 || col===2) ) {
        for(var cc = 0; cc<fileids.length; cc++) {
          dispatch(updateUpdateTable(cc, col, value, fileids));
        }
      }
    }
  }

  const afterSelection = async (row, col) => {
    if(col===0) {
      const file = flatFilesReverse[fileids[row]];
      setInfo(null);
      const fileInfo = await getCSVInfo(file);
      setInfo(fileInfo);
    }
  }

  const filedata = null;

  return(
    <>
      <div className = "hands-on-wrapper">
        <HotTable 
          data={data}
          width="auto"
          height = "auto"
          stretchH="all"
          licenseKey='non-commercial-and-evaluation'
          colHeaders={updateHeaders}
          beforeChange = { beforeChange }
          afterSelection = { afterSelection }
          columns = { columns }
        />
      </div>
      <div 
        className = {`popup ${info===null ? 'closed' : 'open'}`}
        onClick = { () => { if(info) setInfo(null); } }
      >
        <div 
          className = "box"
          onClick = { evt => { evt.stopPropagation(); } }
        >
        {
            info ? (
                <div className = "box table-container update-file">
                  <table className = "table is-small is-fullwidth is-striped is-hoverable is-bordered">
                    <thead>
                      <tr>
                        {
                          info.headers.map(
                            (header, headerindex) => {
                              return(
                                <th key = { `${header}-${headerindex}` }>
                                  { header.replace(/ /g, "\u00A0") }
                                </th>                              
                              )
                            }
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        info.head.map(
                          (headRow, headIndex) => {
                            return(
                              <tr key = {`head-row-${headIndex}`}>
                                {
                                  info.headers.map(
                                    header => {
                                      return(
                                        <td 
                                          key = {`${header}-${headIndex}`}
                                          onClick = { evt => { 
                                            const copied = evt.target.innerText;
                                            copyToClipboard(copied);
                                            dispatch(notify(`Copied to clipboard`, copied, 1.5));
                                          }}
                                        >
                                          { clean(headRow[header]) }
                                        </td>
                                      )
                                    }
                                  )
                                }
                              </tr>
                            )
                          }
                        )
                      }
                    </tbody>
                  </table>
                </div>
            ) : null
          }

        </div>
      </div>
    </>
  )
}

export default Index;

const clean = str => {
  if(typeof(str)!=='string') return str;
  if(str===null) return null;
  return str.replace(/ /g, "\u00A0");
}